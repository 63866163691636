import { Catalog, Producer, Product } from '../../../../../domain'
import { useEffect, useState } from 'react'
import { queryClient } from '../../../../../utilities/queryClient'
import { Card, Col, DynamicImage, Row, Spacer, Typography } from '../../../../../components'
import Loader from '../../../../../components/Loader'
import useAuthenticationContext from '../../../../../utilities/hook/useAuthenticationContext'
import {
  GalleryImageWrapper,
  StyledCardWrapper,
  StyledCardContent,
  DynamicImageWrapper,
  TextWrapper,
  DynamicImageGalleryWrapper,
} from './ProducerInfos.styles'
import { ProducerInfosProps } from './ProducerInfos.model'
import DirectusUtil from '../../../../../utilities/utils/directus'
import DeviceUtil from '../../../../../utilities/utils/device'
import { ProducerVatTypeEnum } from '../../../../../../enums/producer'

const ProducerInfos = ({ id }: ProducerInfosProps) => {
  const [product, setProduct] = useState<Product | undefined>()
  const { getAccessInfos } = useAuthenticationContext()
  const catalog: Catalog | undefined = queryClient.getQueryData(['c_catalog'])

  const isMobileScreen = DeviceUtil.isMobileApp()

  useEffect(() => {
    if (!catalog || !catalog.batchs) return
    const foundBatch = catalog.batchs.find((batch) => batch?.product?.id === id)
    const foundProduct: Product | undefined = foundBatch?.product

    if (foundBatch) {
      setProduct(foundProduct)
    }
  }, [])

  if (!product) {
    return <Loader isLoading pageLoading />
  }

  const galleryImagesIds =
    DirectusUtil.getProducerGalleryImageUrlFromId(product.producer as any as Producer) || []

  return (
    <StyledCardWrapper>
      <Card>
        <StyledCardContent>
          <Row>
            <Col lg={2} md={2} sm={3} xs={3} xsAlignItems="center">
              <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
              <DynamicImageWrapper>
                <DynamicImage
                  togglesModal
                  imageUri={DirectusUtil.getProducerImageUrlFromId(
                    product.producer as any as Producer,
                  )}
                />
              </DynamicImageWrapper>
            </Col>

            <Col
              lg={galleryImagesIds.length > 0 ? 6 : 10}
              md={galleryImagesIds.length > 0 ? 6 : 10}
              sm={9}
              xs={9}
            >
              <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
              <TextWrapper>
                <Typography.CardTitle>{product.producer?.label}</Typography.CardTitle>
                <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodyExtraSmall colorName="text-dark-3">
                  {product.producer?.location}
                </Typography.BodyExtraSmall>
                <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.Body>{product.producer?.description}</Typography.Body>

                {product.producer?.account?.legalInfo.vatType ===
                  ProducerVatTypeEnum.WITHOUT_VAT && (
                  <>
                    <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
                    <Typography.BodySmall colorName="color-danger">
                      Ce producteur n'est pas assujetti à la TVA, elle n'est pas déductible sur ses
                      produits.
                    </Typography.BodySmall>
                  </>
                )}
              </TextWrapper>
            </Col>
            {galleryImagesIds.length > 0 && (
              <Col lg={4} md={4} sm={12} xs={12}>
                <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
                <GalleryImageWrapper isMobile={isMobileScreen}>
                  {product.producer?.galleryImagesIds?.map((imageUri, index) => (
                    <DynamicImageGalleryWrapper>
                      <DynamicImage
                        key={imageUri}
                        imageUri={galleryImagesIds[index]}
                        togglesModal
                      />
                    </DynamicImageGalleryWrapper>
                  ))}
                </GalleryImageWrapper>
              </Col>
            )}
          </Row>
        </StyledCardContent>
      </Card>
    </StyledCardWrapper>
  )
}

export default ProducerInfos
