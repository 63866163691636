import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { Button, Col, PageTitle, Row, Spacer, Typography } from '../../../../components'
import TextInput from '../../../../components/inputs/TextInput'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../utilities/styling/wrappers'
import {
  FormContentWrapper,
  RegisterFormWrapper,
  StyledButtonWrapper,
  StyledProgressBar,
  StyledProgressBarWrapper,
} from './RegisterProducerForm.styles'
import { AuthenticationStackParamList } from '../../../../navigation/AuthenticationNavigationStack/AuthenticationNavigationStack.model'
import { AddProducerRegister } from '../../../../domain'
import { useState } from 'react'
import { StackNavigationProp } from '@react-navigation/stack'
import GeneralUtils from '../../../../utilities/utils/general'
import UserService from '../../../../services/user'
import { set } from 'date-fns'
import StorageUtil from '../../../../utilities/storage/storage'

enum RegisterProducerFormSteps {
  EMAIL = 0,
  USER_INFO = 1,
  PASSWORD = 2,
}

const RegisterProducerFormScreen = () => {
  const route = useRoute<RouteProp<AuthenticationStackParamList, 'RegisterProducerForm'>>()
  const { token } = route?.params ?? { token: '' }
  const navigation = useNavigation<StackNavigationProp<AuthenticationStackParamList>>()
  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState<RegisterProducerFormSteps>(
    RegisterProducerFormSteps.EMAIL,
  )
  const [existingUser, setExistingUser] = useState<boolean>(false)

  const [newUser, setNewUser] = useState<AddProducerRegister>({
    label: '',
    lastName: '',
    firstName: '',
    phone1: '',
    mail: '',
    password: '',
    passwordConfirmation: '',
  })

  const [errorMessage, setErrorMessage] = useState<string>('')

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newUser)) return null

    const userTemp = Object.assign({}, newUser)

    userTemp[param as keyof typeof newUser] = value
    setNewUser(userTemp)
  }

  const onRegister = async () => {
    if (!newUser.mail || !newUser.password) {
      setErrorMessage('Veuillez remplir tous les champs')
      return
    }

    if (newUser.password !== newUser.passwordConfirmation) {
      setErrorMessage('Les mots de passe ne correspondent pas')
      return
    }

    if (newUser.password && newUser.password.length < 8) {
      setErrorMessage('Le mot de passe doit contenir au moins 8 caractères')
      return
    }
    setIsLoading(true)

    const tempNewuser = {
      label: newUser.label,
      lastName: newUser.lastName,
      firstName: newUser.firstName,
      phone1: newUser.phone1,
      mail: newUser.mail.toLocaleLowerCase(),
      password: newUser.password,
    }
    setErrorMessage('')
    const createdUser = await UserService.registerProducer(tempNewuser)

    if (!createdUser || !createdUser.token) {
      setErrorMessage(
        `Impossible de valider l'inscription, votre email est peut-être déjà rattaché à un compte. Veuillez nous contacter à contact@harvy.tech`,
      )
      setIsLoading(false)

      return
    } else {
      await StorageUtil.setItem('login_mail', newUser.mail)
      await setNewUser({
        lastName: '',
        firstName: '',
        label: '',
        phone1: '',
        mail: '',
        password: '',
        passwordConfirmation: '',
      })
      navigation.navigate('RegisterTutorial', {
        type: 'PRODUCER',
        mail: newUser.mail.toLocaleLowerCase(),
        password: newUser.password,
      })

      setIsLoading(false)
    }
  }

  const getFormProgress = (): number => {
    const nbAttributes = Object.keys(newUser).length
    const nbAttributesEmpty = Object.values(newUser).filter(
      (x) => x === undefined || x === '',
    ).length
    const percentage = (nbAttributes - nbAttributesEmpty) / nbAttributes

    if (percentage >= 0 && percentage <= 1) {
      return percentage * 100
    }
    return 0
  }

  const buttonDisabled = () => {
    if (currentStep === RegisterProducerFormSteps.EMAIL) {
      return !newUser.mail
    }
    if (currentStep === RegisterProducerFormSteps.USER_INFO) {
      return !(newUser.firstName && newUser.lastName && newUser.label)
    }
    if (currentStep === RegisterProducerFormSteps.PASSWORD) {
      return !newUser.password || !newUser.passwordConfirmation
    }
    return false
  }

  const handleButtonClickNext = async () => {
    if (!GeneralUtils.isEmail(newUser.mail || '')) {
      setErrorMessage('Adresse mail invalide')
      return
    }
    setIsLoading(true)
    if (currentStep === RegisterProducerFormSteps.EMAIL && newUser.mail) {
      const emailFound = await UserService.checkEmail(newUser.mail)
      if (emailFound) {
        setExistingUser(true)
        setErrorMessage('Il existe déjà un compte avec cet email. Veuillez vous connecter.')
      } else {
        setCurrentStep(currentStep + 1)
      }
    } else {
      setCurrentStep(currentStep + 1)
    }
    setIsLoading(false)
  }

  const handleRedirectLogin = async () => {
    await StorageUtil.setItem('login_mail', newUser.mail)
    navigation.navigate('Login', {})
  }

  const RenderEmailStep = () =>
    currentStep === RegisterProducerFormSteps.EMAIL ? (
      <>
        <PageTitle title="Inscription" />
        <Row>
          <Col xs={12} sm={12} md={12}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newUser.mail}
              label="Email"
              field="text"
              onChangeText={(text) => setValue(text, 'mail')}
            />
          </Col>
        </Row>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      </>
    ) : (
      <></>
    )

  const RenderUserInfoStep = () =>
    currentStep === RegisterProducerFormSteps.USER_INFO ? (
      <>
        <PageTitle title="Vos informations" />
        <Row>
          <Col xs={12} sm={12} md={6}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newUser.firstName}
              label="Prénom"
              field="text"
              onChangeText={(text) => setValue(text, 'firstName')}
            />
          </Col>
          <Col xs={12} sm={12} md={6}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newUser.lastName}
              label="Nom"
              field="text"
              onChangeText={(text) => setValue(text, 'lastName')}
            />
          </Col>
        </Row>
        {newUser.lastName && newUser.firstName && (
          <>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <Row>
              <Col xs={12} sm={12} md={12}>
                <TextInput
                  value={newUser.label}
                  label="Nom de votre exploitation"
                  field="text"
                  onChangeText={(text) => setValue(text, 'label')}
                />
              </Col>
            </Row>
          </>
        )}
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        {newUser.label && (
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <TextInput
                value={newUser.phone1}
                label="Téléphone"
                field="text"
                onChangeText={(text) => setValue(text, 'phone1')}
              />
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall colorName="color-grey">
                Le nº de téléphone n'est pas obligatoire. Il nous permettra de vous aider dans la
                mise en place de l'outil.
              </Typography.BodySmall>
            </Col>
          </Row>
        )}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      </>
    ) : (
      <></>
    )

  const RenderPasswordStep = () =>
    currentStep === RegisterProducerFormSteps.PASSWORD ? (
      <>
        <PageTitle title="Votre mot de passe" />
        <Row>
          <Col xs={12} sm={12} md={12}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newUser.password}
              label="Mot de passe"
              field="password"
              secureTextEntry
              onChangeText={(text) => setValue(text, 'password')}
            />
          </Col>
          <Col xs={12} sm={12} md={12}>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TextInput
              value={newUser.passwordConfirmation}
              label="Confirmez votre mot de passe"
              field="password"
              secureTextEntry
              onChangeText={(text) => setValue(text, 'passwordConfirmation')}
            />
          </Col>
        </Row>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
      </>
    ) : (
      <></>
    )

  return (
    <ScreenSafeAreaWrapper withBottomNav noPaddingLeft>
      <ContentWrapper alignCenter noHeader noDrawer>
        <FormContentWrapper>
          <RegisterFormWrapper>
            {RenderEmailStep()}
            {RenderUserInfoStep()}
            {RenderPasswordStep()}
            <Spacer size={1} />
            {errorMessage ? (
              <Typography.Body colorName="color-danger" align="center">
                {errorMessage}
              </Typography.Body>
            ) : null}
            <Spacer size={1} />
            {existingUser && (
              <StyledButtonWrapper>
                <Button label="Se connecter" onPress={() => handleRedirectLogin()} fullWidth />
              </StyledButtonWrapper>
            )}
            <Spacer size={1} />
          </RegisterFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.5} />
          <StyledProgressBarWrapper>
            <StyledProgressBar percentage={getFormProgress()}></StyledProgressBar>
          </StyledProgressBarWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.5} />
          {!existingUser && (
            <StyledButtonWrapper>
              {currentStep === RegisterProducerFormSteps.PASSWORD ? (
                <Button
                  label={`S'inscrire`}
                  onPress={() => onRegister()}
                  disabled={isLoading || !newUser.passwordConfirmation}
                />
              ) : (
                <Button
                  label="Suivant"
                  onPress={() => handleButtonClickNext()}
                  disabled={buttonDisabled()}
                  loading={isLoading}
                />
              )}
            </StyledButtonWrapper>
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
        </FormContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default RegisterProducerFormScreen
