import styled from 'styled-components/native'

const StyledTitleWrapper = styled.View<{ noMarginLeft?: boolean }>`
  margin-left: ${({ theme }) => theme.spacingUnit * 1.5}px;
  margin-top: ${({ theme }) => theme.spacingUnit * 2}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 0.5}px;
  width: 97%;
  flex-direction: row;
  justify-content: space-between;
  padding-right: ${({ theme }) => theme.spacingUnit * 0.5}px;

  ${({ noMarginLeft }) => noMarginLeft && 'margin-left: 0;'}
`

const StyledLeftWrapper = styled.View`
  flex: auto;
`

export { StyledTitleWrapper, StyledLeftWrapper }
