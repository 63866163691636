import { Batch, Catalog } from '../../../domain'
import { Category } from '../../../domain/Category'
import ApiService from '../../api.service'

const getAllBatchs = async (
  tourId?: string,
  cartId?: string,
  limit: number = 30,
  pageNumber: number = 1,
  selectedCategories?: string[],
  selectedProducers?: string[],
  search?: string,
  productLabelsIds?: string[],
): Promise<Catalog> => {
  if (!tourId || !cartId) return {}

  const response = await ApiService.get(`/batch/tour/${tourId}/cart/${cartId}`, {
    limit,
    page: pageNumber,
    categories: selectedCategories,
    selectedProducers: selectedProducers,
    search,
    productLabelsIds,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const BatchClientService = {
  getAllBatchs,
}

export default BatchClientService
