import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Divider, Icon, Icons, Text } from '../../../../components'
import { UserMenuItemProps } from './UserMenuItem.model'
import {
  StyledArrowBox,
  StyledCenterBox,
  StyledFirstBox,
  StyledFlatlistItem,
  StyledIconBox,
} from './UserMenuItem.styles'

function UserMenuItem({
  type,
  label,
  iconType,
  iconName,
  color,
  onPress,
  page,
  onNavigate,
  viewType,
  currentViewType,
}: UserMenuItemProps) {
  const theme = useTheme()

  if (!type) {
    return null
  }

  if (viewType && currentViewType && viewType !== currentViewType) {
    return null
  }

  if (type === 'divider') {
    return <Divider />
  }

  const paddingTextLeft = theme.spacingUnit * (iconType ? 3 : 0)

  const onPressHandler = async () => {
    if (onPress != null) {
      onPress()
    }

    if (page && onNavigate) {
      onNavigate(page)
    }
  }

  return (
    <TouchableOpacity activeOpacity={theme.activeOpacity} onPress={onPressHandler}>
      <StyledFlatlistItem>
        <StyledFirstBox>
          {iconType ? (
            <StyledIconBox>
              <Icon
                type={iconType === 'Ionicons' ? Icons.Ionicons : Icons.MaterialCommunityIcons}
                name={iconName || 'home'}
                color={theme.colors['color-primary']}
              />
            </StyledIconBox>
          ) : null}
          <StyledCenterBox style={{ paddingLeft: paddingTextLeft }}>
            <Text colorName={color} style={{ fontFamily: 'Inter-Medium' }}>
              {label}
            </Text>
          </StyledCenterBox>
        </StyledFirstBox>
        <StyledArrowBox>
          {type !== 'item-no-arrow' ? (
            <Icon
              type={Icons.Ionicons}
              name="chevron-forward-outline"
              color={theme.colors[color || 'color-primary']}
            />
          ) : null}
        </StyledArrowBox>
      </StyledFlatlistItem>
    </TouchableOpacity>
  )
}

export default UserMenuItem
