import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Alert, View } from 'react-native'
import { Col, Row, SectionTitle, Spacer, Typography } from '../../../components'
import Loader from '../../../components/Loader'
import { Cart, CartBatch, Catalog } from '../../../domain'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import BatchClientService from '../../../services/client/batch'
import CartClientService from '../../../services/client/cart'
import OrderClientService from '../../../services/client/order'
import { queryClient } from '../../../utilities/queryClient'
import StorageUtil from '../../../utilities/storage/storage'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import ClientCartBatchs from './components/ClientCartBatchs'
import ClientCartSummary from './components/ClientCartSummary'
import FormatUtils from '../../../utilities/utils/format'
import { SubTotalWrapper } from './ClientCart.styles'
import CartUtil from '../../../utilities/utils/cart'

const ClientCartScreen = () => {
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const [cartId, setCartId] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const {
    data: cart,
    refetch: refetchCart,
    isInitialLoading: cartLoading,
  } = useQuery<Cart | null, Error>(['c_cart', cartId], () => CartClientService.getOneCart(cartId), {
    keepPreviousData: true,
    enabled: cartId !== undefined,
  })

  const {
    data: catalog,
    refetch: refetchBatchs,
    isInitialLoading: batchsLoading,
  } = useQuery<Catalog, Error>(
    ['c_catalog'],
    () => BatchClientService.getAllBatchs(cart?.tour?.id, cart?.id),
    {
      keepPreviousData: true,
      enabled: cart?.id !== undefined,
    },
  )

  useEffect(() => {
    const getStoredCart = async () => {
      const storedCartId = await StorageUtil.getItem('c_cart_id')

      if (storedCartId) {
        await setCartId(JSON.parse(storedCartId))
        refetchCart()
      } else {
        navigation.navigate('ClientCatalog', {})
      }
    }
    getStoredCart()
  }, [])

  const onValidateOrder = async () => {
    if (!cart || !cart.id) return
    setIsLoading(true)
    const isUpdatedOrder = cart.targetCart
    let order = null
    if (isUpdatedOrder) {
      order = await OrderClientService.update({ cartId: cart.id })
    } else {
      order = await OrderClientService.create({ cartId: cart.id })
    }
    if (order && order.id) {
      StorageUtil.deleteItem('c_cart_id')
      queryClient.setQueryData(['c_cart', cart.id], null)
      queryClient.setQueryData(['c_catalog'], undefined)
      navigation.navigate('ClientOrders', {
        screen: 'ClientOrder',
        params: isUpdatedOrder
          ? { id: order.id, isUpdatedOrder: true }
          : { id: order.id, isNewOrder: true },
      })
      setIsLoading(false)
      return
    } else {
      Alert.alert('Erreur lors de la création de la commande')
    }
    setIsLoading(false)
  }

  if (cartLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!cart) return null

  const getOrderConditions = () => {
    let minOrderConditions = 'Aucun minimum de commande'
    let deliveryFeeConditions = 'pas de frais de livraison'
    let freeDeliveryonditions = 'aucun Franco'

    const tour = cart?.tour

    if (tour?.minOrderValueHT && tour?.minOrderValueHT > 0) {
      minOrderConditions = `Minimum de commande de ${FormatUtils.formatPrice(
        tour?.minOrderValueHT,
      )}`
    }

    if (tour?.deliveryFeesHT && tour?.deliveryFeesHT > 0) {
      deliveryFeeConditions = `frais de livraison de ${FormatUtils.formatPrice(
        tour?.deliveryFeesHT,
      )}`
    }

    if (tour?.freeDeliveryOrderValueHT && tour?.freeDeliveryOrderValueHT > 0) {
      freeDeliveryonditions = `franco de ${FormatUtils.formatPrice(tour?.freeDeliveryOrderValueHT)}`
    }

    return `${minOrderConditions}, ${deliveryFeeConditions}, ${freeDeliveryonditions}`
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper fullWidth>
        <SectionTitle
          title={`Finalisez votre${cart?.targetCart ? ' modification de' : ''} commande`}
        />
        <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
        <Typography.BodySmall colorName="color-danger">
          Cette commande n'est pas encore finalisée. Pour la terminer, cliquez sur "valider la
          commande".
        </Typography.BodySmall>
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        {cart?.targetCart && (
          <>
            <Typography.Body>
              Ceci est une modification de la commande #{FormatUtils.formatId(cart.order?.id)}
            </Typography.Body>
            <Typography.BodySmall colorName="text-dark-3">
              Validée initialement le{' '}
              {FormatUtils.capitalize(
                FormatUtils.formatDate(cart.order?.createdDate, 'FullDate+StartTime'),
              )}
            </Typography.BodySmall>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          </>
        )}
        <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
        <Typography.BodySmall colorName="text-dark-3">{getOrderConditions()}</Typography.BodySmall>
        <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />

        <Row>
          <Col xs={12} sm={12} md={12} lg={7}>
            {(cart.producers || []).map((producer) => {
              const producerCartBatchs = cart.cartBatchs?.filter(
                (cartBatch) => cartBatch?.batch?.product?.producer?.id === producer.id,
              )

              return (
                <>
                  <ClientCartBatchs
                    key={producer.id}
                    producer={producer}
                    catalog={catalog}
                    cartBatchs={producerCartBatchs}
                    cart={cart}
                  />
                  <SubTotalWrapper>
                    <Typography.BodyExtraSmall bold colorName="text-dark-3">
                      Sous-total:{' '}
                      {FormatUtils.formatPrice(
                        CartUtil.calculateSubtotal(producerCartBatchs),
                        'ht',
                      )}
                    </Typography.BodyExtraSmall>
                  </SubTotalWrapper>
                </>
              )
            })}
          </Col>
          <Col xs={12} sm={12} md={12} lg={5}>
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            <ClientCartSummary cart={cart} loading={isLoading} onValidateOrder={onValidateOrder} />
          </Col>
        </Row>
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientCartScreen
