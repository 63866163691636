import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { CardPageLink } from '../../../components'
import PageTitle from '../../../components/PageTitle'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { GroupEnums } from '../../../../enums/group'

const CarrierBillingScreen = () => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const { getAccessInfos } = useAuthenticationContext()

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Gestion financière" />
        <CardPageLink
          label={`Bons de livraison`}
          icon="arrow-right"
          onClick={() => navigation.navigate('CarrierBillingDeliveryNotes', {})}
        />
        <CardPageLink
          label={`Frais de livraison`}
          icon="arrow-right"
          onClick={() => navigation.navigate('CarrierDeliveryFees', {})}
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierBillingScreen
