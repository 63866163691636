import { Cart, CartBatch, UdpateCartBatch } from '../../../domain'
import ApiService from '../../api.service'

const getAllCarts = async (clientId: string): Promise<Cart[]> => {
  const response = await ApiService.get(`/cart/client/${clientId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOneCart = async (cartId?: string): Promise<Cart | null> => {
  if (!cartId) return null
  const response = await ApiService.get(`/cart/${cartId}`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const create = async (clientId: string, dto: any): Promise<Cart> => {
  const response = await ApiService.post(`/cart/client/${clientId}`, dto).catch((error) => {
    throw error
  })
  return response
}

const createUpdateOrder = async (clientId: string, idCart: string): Promise<Cart> => {
  const response = await ApiService.post(`/cart/${idCart}/client/${clientId}`, {}).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const updateCartBatch = async (cartId: string, dto: UdpateCartBatch): Promise<Cart> => {
  const response = await ApiService.post(`/cart/${cartId}/cartBatch`, dto).catch((error) => {
    throw error
  })
  return response
}

const CartClientService = {
  getAllCarts,
  getOneCart,
  create,
  createUpdateOrder,
  updateCartBatch,
}

export default CartClientService
