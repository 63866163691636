import React from 'react'
import { useTheme } from 'styled-components/native'
import { CarrierTourProducerTopBoxProps } from './CarrierTourProducerTopBox.model'
import { CardParam } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import { StyledTopBox } from './CarrierTourProducerTopBox.styles'

function CarrierTourProducerTopBox({ producerTour, tour }: CarrierTourProducerTopBoxProps) {
  const theme = useTheme()

  return (
    <>
      <StyledTopBox>
        <CardParam
          label={'Producteur'}
          value={producerTour?.producer?.label || ''}
          flex={8}
          isStart
        />
        <CardParam label={'Colis'} value={tour?.nbCrates || '0'} flex={4} isEnd />
      </StyledTopBox>
      <StyledTopBox>
        <CardParam label={'Commandes'} value={tour?.nbOrders || '0'} flex={6} isStart />
        <CardParam
          label={'Ventes'}
          value={FormatUtils.formatPrice(tour?.totalIncome)}
          flex={6}
          isEnd
        />
      </StyledTopBox>
    </>
  )
}

export default CarrierTourProducerTopBox
