import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, PageTitle, Spacer, Typography } from '../../../components'

import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'

import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { View } from 'react-native'
import { useQuery } from '@tanstack/react-query'
import { Account, StripeAccount } from '../../../domain/Account'
import AccountService from '../../../services/billing/account'
import CardSettings from '../../../components/CardSettings'
import { Producer } from '../../../domain'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import ProducerProducerService from '../../../services/producer/producer'
import StripeService from '../../../services/billing/stripe'

const ProducerBillingPaymentConfigurationScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCheck, setIsLoadingCheck] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  const [isStripeAccountChecked, setIsStripeAccountChecked] = useState(false)
  const [stripeAccountData, setStripeAccountData] = useState<StripeAccount | undefined>(undefined)
  const [isStripeAccountToCreate, setIsStripeAccountToCreate] = useState(false)
  const [isStripeAccountValid, setIsStripeAccountValid] = useState(false)

  const accountId = getAccessInfos().accountId

  if (!accountId) {
    return (
      <ScreenSafeAreaWrapper withBottomNav>
        <ContentWrapper noDrawer>
          <PageTitle title="Mon compte facturation" />
          <View style={{ width: '100%' }}>
            <Typography.BodySmall align="center">
              Nous n'avons pas pu trouver votre compte facturation
            </Typography.BodySmall>
          </View>
        </ContentWrapper>
      </ScreenSafeAreaWrapper>
    )
  }

  const { data: accountData } = useQuery<Account, Error>(
    ['account', getAccessInfos().accountId],
    () => AccountService.getOne(accountId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: producerData,
    refetch: refetchProducer,
    isFetching: producerLoading,
  } = useQuery<{ producer: Producer; stepsData: TutorialStepData[] }, Error>(
    ['p_producer', getAccessInfos().producerId],
    () => ProducerProducerService.getOne(getAccessInfos().producerId),
    {
      keepPreviousData: true,
      enabled: getAccessInfos().producerId !== undefined,
    },
  )

  useEffect(() => {
    if (accountData && !accountData.stripeAccountId) {
      setIsStripeAccountChecked(true)
      setIsStripeAccountToCreate(true)
    }
  }, [accountData])

  const onCheckStripeAccount = async () => {
    setIsLoadingCheck(true)
    try {
      const stripeAccountData = await AccountService.getStripeAccount(accountId)
      if (stripeAccountData.stripeAccount) {
        setStripeAccountData(stripeAccountData.stripeAccount)
        setIsStripeAccountToCreate(false)
        if (
          stripeAccountData?.stripeAccount?.requirements?.currently_due &&
          stripeAccountData?.stripeAccount?.requirements?.currently_due.length === 0
        ) {
          setIsStripeAccountValid(true)
        }
      } else {
        setIsStripeAccountToCreate(true)
      }
      setIsStripeAccountChecked(true)
    } catch (error) {
      setIsStripeAccountToCreate(true)
      console.error('error', error)
    }
    setIsLoadingCheck(false)
  }

  const onCreateStripeAccount = async () => {
    setIsLoading(true)

    if (!producerData || !accountData) return

    const newAccountToken = await StripeService.createAccountToken(accountData)

    if (!newAccountToken) {
      setErrorMessage(
        `Une erreur est survenue lors de la création de votre compte, veuillez contacter l'équipe Harvy (Code: SP-001)`,
      )
      setIsStripeAccountToCreate(false)
      return
    }

    try {
      await AccountService.createStripeAccount(newAccountToken, accountId, producerData.producer)
      onCheckStripeAccount()
      onPressOnboardingPage()
    } catch (error) {
      setErrorMessage(
        `Une erreur est survenue lors de la création de votre compte, veuillez contacter l'équipe Harvy`,
      )
      setIsStripeAccountToCreate(false)
      console.error('error', error)
    }

    setIsLoading(false)
  }

  const onPressOnboardingPage = async () => {
    const onboardingUrl = await AccountService.getStripeAccountOnboardingUrl(accountId)

    if (onboardingUrl) {
      window.open(onboardingUrl, '_blank')
    }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle noMarginLeft title={`Configuration de vos paiements`} />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <CardSettings
          title="Vérifier la configuration de mon compte"
          description={`Vérifiez la configuration de votre compte afin de recevoir vos premiers paiements`}
          children={
            <Button
              fullWidth={true}
              label={'Vérifier'}
              onPress={() => onCheckStripeAccount()}
              loading={isLoadingCheck}
              disabled={isStripeAccountChecked || isLoading}
            />
          }
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        {isStripeAccountChecked && isStripeAccountToCreate && !isStripeAccountValid ? (
          <CardSettings
            title="Créer votre compte de paiement"
            description={`Vérifiez la configuration de votre compte afin de recevoir vos premiers paiements`}
            children={
              <Button
                fullWidth={true}
                label={'Créer'}
                onPress={() => onCreateStripeAccount()}
                loading={isLoading}
                disabled={stripeAccountData !== undefined}
              />
            }
          />
        ) : undefined}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        {isStripeAccountChecked && stripeAccountData && !isStripeAccountValid ? (
          <CardSettings
            title="Finalisez la création de votre compte"
            description={`Validez vos informations légales afin de finalsier la création de cotre compte`}
            children={
              <Button
                fullWidth={true}
                label={'Finaliser'}
                onPress={() => onPressOnboardingPage()}
                loading={isLoading}
              />
            }
          />
        ) : undefined}

        {isStripeAccountValid && (
          <>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Typography.Body colorName="text-dark-1">
              Votre compte de paiement est bien configuré, vous pouvez recevoir des virements
            </Typography.Body>
          </>
        )}

        {errorMessage ? (
          <>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
          </>
        ) : null}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerBillingPaymentConfigurationScreen
