import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Linking, ScrollView } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Col, PageTitle, Row, SectionTitle, Spacer, Typography } from '../../../components'
import Loader from '../../../components/Loader'
import { BatchsFake, Cart, Order, Tour, Producer, DeliveryNote } from '../../../domain'
import { ClientStackParamList } from '../../../navigation/ClientNavigationStack/ClientNavigationStack.model'
import OrderClientService from '../../../services/client/order'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import ClientOrderBatchs from './components/ClientOrderBatchs'
import ClientOrderSummary from './components/ClientOrderSummary'
import OrderHistory from '../../../modules/Order/OrderHistory'
import DeliveryNoteClientService from '../../../services/client/deliveryNote'
import DirectusUtil from '../../../utilities/utils/directus'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import TourDeliveryNoteCard from '../../../modules/DeliveryNote/TourDeliveryNoteCard'
import { SubTotalWrapper } from './ClientOrder.styles'
import CartUtil from '../../../utilities/utils/cart'

const ClientOrderScreen = () => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ClientStackParamList>>()
  const route = useRoute<RouteProp<ClientStackParamList, 'ClientOrder'>>()
  const { getAccessInfos } = useAuthenticationContext()

  const { id, isNewOrder, isUpdatedOrder } = route?.params ?? {
    id: '',
    isNewOrder: false,
    isUpdatedOrder: false,
  }

  const {
    data: order,
    refetch: refetchOrder,
    isInitialLoading: orderLoading,
  } = useQuery<Order, Error>(['c_order'], () => OrderClientService.getOneOrder(id), {
    keepPreviousData: false,
    enabled: id !== '',
  })

  const { data: deliveryNotes } = DeliveryNoteClientService.useGetAllDeliveryNotes(
    order?.cart?.tour?.id,
    getAccessInfos().clientId,
    order?.id,
  ).query()

  if (orderLoading) {
    return <Loader isLoading pageLoading paddingLeft />
  }

  if (!order || !order.cart) return null

  const producers = () => {
    if (!order.cart?.cartBatchs) return []
    const tempProducers: Producer[] = []

    order.cart?.cartBatchs.map((cartBatch) => {
      if (!cartBatch?.batch?.product) return
      const { producer } = cartBatch.batch.product
      const isInProducerList = tempProducers.find(
        (listedProducer) => listedProducer.id === producer?.id,
      )
      if (!isInProducerList && producer) {
        tempProducers.push(producer)
      }
    })
    return tempProducers
  }

  const onClickDeliveryNote = async (deliveryNote: DeliveryNote) => {
    if (!deliveryNote.id) return

    const deliveryNoteWithAccess = await DeliveryNoteClientService.getOne(deliveryNote.id)
    if (!deliveryNoteWithAccess.documentId || !deliveryNoteWithAccess.accessToken) return

    Linking.openURL(
      DirectusUtil.getDocumentDownloadUrlFromId(
        deliveryNoteWithAccess.documentId,
        deliveryNoteWithAccess.accessToken,
      ),
    )
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper fullWidth>
        {isNewOrder ? (
          <>
            <PageTitle title="Commande validée" />
            <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.Body>
              Votre commande est prise en compte et a été envoyé aux producteurs.
            </Typography.Body>
          </>
        ) : null}
        {isUpdatedOrder ? (
          <>
            <PageTitle title="Modification validée" />
            <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.Body>
              Votre commande a bien été modifiée et a été envoyé aux producteurs.
            </Typography.Body>
          </>
        ) : null}
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <SectionTitle
          title={`Votre commande #${FormatUtils.formatId(order.id)} du ${FormatUtils.formatDate(
            order.createdDate,
            'FullDate+StartTime',
          )}`}
        />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <Row>
          <Col xs={12} sm={12} md={12} lg={7}>
            {producers().map((producer) => {
              const producerCartBatchs = order.cart?.cartBatchs?.filter(
                (cartBatch) => cartBatch?.batch?.product?.producer?.id === producer.id,
              )
              if (!producerCartBatchs || producerCartBatchs.length < 1) return
              return (
                <>
                  <ClientOrderBatchs
                    key={producer.id}
                    producer={producer}
                    cartBatchs={producerCartBatchs}
                  />
                  <SubTotalWrapper>
                    <Typography.BodyExtraSmall bold colorName="text-dark-3">
                      Sous-total:{' '}
                      {FormatUtils.formatPrice(
                        CartUtil.calculateSubtotal(producerCartBatchs),
                        'ht',
                      )}
                    </Typography.BodyExtraSmall>
                  </SubTotalWrapper>
                </>
              )
            })}
          </Col>
          <Col xs={12} sm={12} md={12} lg={5}>
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            <ClientOrderSummary order={order} isUpdatedOrder={isUpdatedOrder} />
          </Col>
        </Row>
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        {(deliveryNotes || []).length > 0 && (
          <Typography.SectionTitle colorName="text-dark-3">
            Bons de livraison
          </Typography.SectionTitle>
        )}

        {((deliveryNotes || []).filter((dl) => dl.order?.id === id) || []).map((deliveryNote) => (
          <TourDeliveryNoteCard
            key={deliveryNote.id}
            deliveryNote={deliveryNote}
            onClick={onClickDeliveryNote}
            isCarrier={false}
          />
        ))}
        <Spacer size={6} axis={Spacer.AxisEnum.VERTICAL} />
        <OrderHistory order={order} />
        <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientOrderScreen
