import { Platform } from 'react-native'
import styled, { css } from 'styled-components/native'

const DashboardContentWrapper = styled.View`
  flex: 1;
  width: 92%;
`

const StyledButtonWrapper = styled.View`
  align-items: center;
  width: 100%;
`

const BoxWrapper = styled.View`
  width: 100%;
  max-width: 1000px;
  min-height: 200px;
  background-color: ${({ theme }) => theme.colors['background-3']};
  margin-top: ${({ theme }) => theme.spacingUnit * 1}px;
  margin-bottom: ${({ theme }) => theme.spacingUnit * 2}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
`

const StyledLabelTopView = styled.View`
  width: 100%;
  flex-direction: row;
  // padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
`

const StyledLabel = styled.View`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  background-color: lightgrey;
  opacity: 0.7;
`
const TextInputWrapper = styled.View`
  width: 100%;
  max-width: 200px;
`

export {
  DashboardContentWrapper,
  StyledButtonWrapper,
  BoxWrapper,
  StyledLabelTopView,
  StyledLabel,
  TextInputWrapper,
}
