import { useNavigation } from '@react-navigation/native'
import { ProducerPropositionV2DuplicateProps } from './ProducerPropositionV2Duplicate.model'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import SliderMenu from '../../../../../components/SliderMenu'
import { Button, Dropdown, SectionTitle, Spacer, Typography } from '../../../../../components'
import DateSwitcher from '../../../../../components/DateSwitcher'
import FormatUtils from '../../../../../utilities/utils/format'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Batch, Pagination } from '../../../../../domain'
import BatchProducerService from '../../../../../services/producer/batch'
import useAuthenticationContext from '../../../../../utilities/hook/useAuthenticationContext'
import { View } from 'react-native'
import { BatchEnums } from '../../../../../../enums'
import BatchToDuplicateCard from './BatchToDuplicateCard'
import { queryClient } from '../../../../../utilities/queryClient'

const propositionModes = [
  {
    label: 'Journée',
    value: 'DAY',
  },
  {
    label: 'Semaine',
    value: 'WEEK',
  },
  {
    label: 'Stock',
    value: 'MONTH',
  },
]

export type Period = {
  id: string
  label: string
  type: 'DAY' | 'WEEK'
  startDate: Date
  endDate: Date
  daysInterval: number
}

const ProducerPropositionV2Duplicate = ({
  propositionsFilters,
  onDuplicate,
  onCancelDuplication,
}: ProducerPropositionV2DuplicateProps) => {
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [selectedPeriod, setSelectedPeriod] = useState<Period | undefined>(undefined)
  const [periods, setPeriods] = useState<Period[]>([])
  const [selectedBatchs, setSelectedBatchs] = useState<Batch[]>([])

  const [isLoadingDuplicate, setIsLoadingDuplicate] = useState(false)

  if (propositionsFilters.mode === 'MONTH')
    return (
      <>
        <Typography.Body>
          La duplication n'est pas disponible sur le mois, basculez en mode journée ou semaine.
        </Typography.Body>
      </>
    )

  const {
    data: batchs,
    refetch: refetchBatchs,
    isRefetching: batchsRefetching,
    isLoading: batchsLoading,
  } = useQuery<{ data: Batch[]; pagination: Pagination }, Error>(
    ['p_duplicate_batchs'],
    () =>
      BatchProducerService.getAllBatchs(
        getAccessInfos().producerId,
        selectedPeriod?.startDate,
        selectedPeriod?.endDate,
        undefined,
        propositionsFilters.mode === 'DAY'
          ? [BatchEnums.TYPE.DAY]
          : [BatchEnums.TYPE.DAY, BatchEnums.TYPE.WEEK],
        100,
        1,
      ),
    {
      keepPreviousData: true,
      enabled: selectedPeriod !== undefined,
    },
  )

  useEffect(() => {
    if (selectedPeriod && selectedBatchs && selectedBatchs.length < 1) {
      const tempSelectedBatchs: Batch[] = []
      batchs?.data.map((b) => {
        tempSelectedBatchs.push(b)
      })

      setSelectedBatchs(tempSelectedBatchs)
    }
  }, [batchs])

  useEffect(() => {
    setSelectedPeriod(undefined)
    fillPeriods()
  }, [propositionsFilters])

  const fillPeriods = () => {
    const tempPeriods = []
    if (propositionsFilters.mode === 'DAY') {
      const nbDaysLimit = 20
      let nbDays = 1
      let targetDate = propositionsFilters.start

      while (nbDays < nbDaysLimit) {
        const tempDate = new Date(targetDate)

        tempDate.setDate(tempDate.getDate() - 1)

        const tempStartDate = new Date(tempDate)
        tempDate.setHours(0)
        tempDate.setMinutes(0)
        tempDate.setSeconds(0)
        const tempEndDate = new Date(tempDate)
        tempEndDate.setHours(23)
        tempEndDate.setMinutes(59)
        tempEndDate.setSeconds(59)

        const period: Period = {
          id: nbDays.toString(),
          label: `${FormatUtils.formatDate(tempStartDate.toString(), 'FullDate')}`,
          type: 'DAY',
          startDate: tempStartDate,
          endDate: tempEndDate,
          daysInterval: nbDays,
        }
        tempPeriods.push(period)

        targetDate = tempDate
        nbDays = nbDays + 1
      }
    }

    if (propositionsFilters.mode === 'WEEK') {
      const nbWeeksLimit = 6
      let nbWeeks = 1
      let targetDate = propositionsFilters.start

      while (nbWeeks < nbWeeksLimit) {
        const tempDate = new Date(targetDate)

        tempDate.setDate(tempDate.getDate() - 7)

        const tempStartDate = new Date(tempDate)
        tempDate.setHours(0)
        tempDate.setMinutes(0)
        tempDate.setSeconds(0)
        const tempEndDate = new Date(tempDate)
        tempEndDate.setDate(tempEndDate.getDate() + 6)
        tempEndDate.setHours(23)
        tempEndDate.setMinutes(59)
        tempEndDate.setSeconds(59)

        let tempLabel = `${FormatUtils.formatDate(
          tempStartDate.toString(),
          'Week',
        )} (du ${FormatUtils.formatDate(
          tempStartDate.toString(),
          'FullDate',
        )} au ${FormatUtils.formatDate(tempEndDate.toString(), 'FullDate')})`

        if (nbWeeks === 1) {
          tempLabel = `La semaine dernière ${FormatUtils.formatDate(
            tempStartDate.toString(),
            'Week',
          )}`
        }
        const period: Period = {
          id: nbWeeks.toString(),
          label: tempLabel,
          type: 'WEEK',
          startDate: tempStartDate,
          endDate: tempEndDate,
          daysInterval: nbWeeks * 7,
        }
        tempPeriods.push(period)

        targetDate = tempDate
        nbWeeks = nbWeeks + 1
      }
    }
    setPeriods(tempPeriods)
  }

  const isSelectedBatch = (batch: Batch) => {
    return selectedBatchs.find((b) => b.id === batch.id) !== undefined
  }

  const onClickBatch = (batch: Batch) => {
    const tempSelectedBatchs = [...selectedBatchs]

    if (isSelectedBatch(batch)) {
      const index = tempSelectedBatchs.findIndex((b) => b.id === batch.id)
      if (index > -1) {
        tempSelectedBatchs.splice(index, 1)
      }
    } else {
      tempSelectedBatchs.push(batch)
    }

    setSelectedBatchs(tempSelectedBatchs)
  }

  const onChangeSelectedPeriod = async (value: any) => {
    await setSelectedPeriod(value)
    await setSelectedBatchs([])
    refetchBatchs()
  }

  const onDuplicateClick = async () => {
    setErrorMessage('')
    if (selectedBatchs.length < 1 || isLoadingDuplicate || !selectedPeriod) {
      setErrorMessage('Vous devez sélectionner au moins un lot à dupliquer')
      setIsLoadingDuplicate(false)
      return
    }
    setIsLoadingDuplicate(true)

    const body = {
      after: FormatUtils.dateToUtcString(selectedPeriod.startDate, '00:00:00'),
      before: FormatUtils.dateToUtcString(selectedPeriod.endDate, '23:59:59'),
      batchsIds: selectedBatchs.map((b) => b.id),
      daysInterval: selectedPeriod.daysInterval,
    }

    const createdBatchs = await BatchProducerService.duplicateBatchs(
      getAccessInfos().producerId,
      body,
    )
    if (createdBatchs) {
      await queryClient.invalidateQueries(['p_batchs'])
      await queryClient.invalidateQueries(['p_batchs_dashboard'])

      onDuplicate()
    } else {
      setErrorMessage('Un problème est survenu lors de la duplication des lots')
    }
    setIsLoadingDuplicate(false)
  }

  return (
    <>
      <SectionTitle
        title={`Quelle ${
          propositionsFilters.mode === 'DAY' ? `journée` : `semaine`
        } souhaitez-vous dupliquer ?`}
      />
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.6} />
      <Dropdown
        label={propositionsFilters.mode === 'DAY' ? `Choisir une journée` : `Choisir une semaine`}
        onChange={onChangeSelectedPeriod}
        multiple={false}
        defaultValue={selectedPeriod?.id}
        optionsDefault={
          !periods
            ? []
            : periods?.map((period) => ({
                ...period,
                key: period.id,
                label: period.label,
                value: period.id,
              }))
        }
        zIndex={1000}
        itemKey="period"
      />
      {(batchs?.data || []).map((batch) => {
        let selected = isSelectedBatch(batch)

        return <BatchToDuplicateCard batch={batch} selected={selected} onClick={onClickBatch} />
      })}
      <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />

      <View style={{ alignItems: 'center' }}>
        {selectedPeriod && !batchsLoading && batchs && batchs.data?.length < 1 && (
          <Typography.BodySmall colorName={'text-dark-3'}>
            Aucun lot à dupliquer
          </Typography.BodySmall>
        )}

        {selectedPeriod && batchs?.data && (
          <Typography.BodySmall colorName={'text-dark-3'}>
            {FormatUtils.pluralizeAmountLabel('lot', selectedBatchs.length)} à dupliquer sur{' '}
            {FormatUtils.pluralizeAmountLabel('lot', batchs?.data.length)}
          </Typography.BodySmall>
        )}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        {errorMessage ? (
          <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
        ) : null}
        {selectedPeriod && (
          <Button
            small
            label={`Dupliquer ${selectedBatchs.length} lots`}
            onPress={() => onDuplicateClick()}
            loading={isLoadingDuplicate}
          />
        )}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
        <Button
          small
          label="Annuler duplication"
          onPress={() => onCancelDuplication()}
          colorName="text-dark-3"
        />
      </View>
    </>
  )
}

export default ProducerPropositionV2Duplicate
