import React from 'react'
import { useTheme } from 'styled-components/native'
import { StaticCardProps } from './InfoCard.model'
import {
  StyledInfoCard,
  StyledCardContent,
  StyledCircleContent,
  StyledInfoCardWrapper,
} from './InfoCard.styles'
import Icon, { Icons } from '../Icon'

function InfoCard({ children, leftIcon }: StaticCardProps) {
  const theme = useTheme()

  return (
    <StyledInfoCardWrapper>
      <StyledInfoCard>
        <StyledCircleContent>
          <Icon
            type={Icons.Ionicons}
            name={leftIcon}
            size={theme.spacingUnit * 2}
            color={theme.colors['color-grey']}
          />
        </StyledCircleContent>
        <StyledCardContent>{children}</StyledCardContent>
      </StyledInfoCard>
    </StyledInfoCardWrapper>
  )
}

export default InfoCard
