import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Col,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  TextInput,
  Typography,
} from '../../../components'
import { DeliveryNote, ProducersFake, StepsFake, Tour, ToursFake } from '../../../domain'
import i18n from '../../../i18n'
import TourProducerCard from '../../../modules/Tour/TourProducerCard'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'

import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import DeliveryNoteCarrierService from '../../../services/carrier/deliveryNote'
import Loader from '../../../components/Loader'
import TourDeliveryNoteCard from '../../../modules/DeliveryNote/TourDeliveryNoteCard'
import DirectusUtil from '../../../utilities/utils/directus'
import * as Linking from 'expo-linking'
import { queryClient } from '../../../utilities/queryClient'
import { StackNavigationProp } from '@react-navigation/stack'
import { DeliveryNoteTypeEnum, MailEnums } from '../../../../enums'
import InfoCard from '../../../components/InfoCard'
import CardSettings from '../../../components/CardSettings'
import {
  SummaryLeftBox,
  SummaryRightBox,
  TotalSummaryCard,
  TotalSummaryCardContent,
} from './CarrierTourDeliveryNote.styles'
import { View } from 'react-native'

const CarrierTourDeliveryNoteScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourDeliveryNote'>>()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [deliveryNoteDocumentRefreshs, setDeliveryNoteDocumentRefreshs] = useState<number>(0)

  const [isLoading, setIsLoading] = useState(false)

  const { deliveryNoteId } = route?.params ?? { deliveryNoteId: '' }

  const {
    data: deliveryNote,
    refetch: refetchDeliveryNote,
    isInitialLoading: deliveryNoteLoading,
  } = useQuery<DeliveryNote, Error>(
    ['sf_tour_delivery_note', deliveryNoteId],
    () => DeliveryNoteCarrierService.getOne(deliveryNoteId),
    {},
  )

  const [updatedDeliveryNote, setUpdatedDeliveryNote] = useState<Partial<DeliveryNote>>()

  useEffect(() => {
    if (deliveryNote) {
      setUpdatedDeliveryNote({
        id: deliveryNote.id,
        customNumber: deliveryNote.customNumber || '',
      })
    }
  }, [deliveryNote])

  const refetchDeliveryNoteForDocument = async () => {
    if (deliveryNote && !deliveryNote.documentId && deliveryNoteDocumentRefreshs < 3) {
      setTimeout(() => {
        refetchDeliveryNote()
        setDeliveryNoteDocumentRefreshs(deliveryNoteDocumentRefreshs + 1)
      }, 5000)
    }
  }

  useEffect(() => {
    if (deliveryNote && !deliveryNote.documentId) {
      refetchDeliveryNoteForDocument()
    }
  }, [deliveryNote, deliveryNoteDocumentRefreshs])

  const [errorMessage, setErrorMessage] = useState<string>('')

  const setValue = (value: any, param: string) => {
    if (!updatedDeliveryNote) return
    setErrorMessage('')
    if (!param && !(param in updatedDeliveryNote)) return null

    const deliveryNoteTemp = Object.assign({}, updatedDeliveryNote)

    deliveryNoteTemp[param as keyof typeof updatedDeliveryNote] = value
    setUpdatedDeliveryNote(deliveryNoteTemp)
  }

  const onUpdateDeliveryNote = async () => {
    if (!deliveryNote?.id || !updatedDeliveryNote) return
    setErrorMessage('')
    setIsLoading(true)

    const deliveryNoteUpdated = {
      customNumber: updatedDeliveryNote.customNumber,
    }

    const updatedDeliveryNoteTemp = await DeliveryNoteCarrierService.update(
      deliveryNote.id,
      deliveryNoteUpdated,
    )
    if (
      updatedDeliveryNoteTemp &&
      updatedDeliveryNoteTemp.customNumber === updatedDeliveryNote.customNumber
    ) {
      setTimeout(async () => {
        await refetchDeliveryNote()
        setIsLoading(false)
      }, 3000)
    } else {
      setErrorMessage('Un problème est survenu lors de la mise à jour du bon de livraison')
      setIsLoading(false)
    }
  }

  if (deliveryNoteLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!deliveryNote || !deliveryNote.id) return null

  const producer = deliveryNote.producer
  const carrier = deliveryNote.carrier

  const client = deliveryNote.order?.cart?.client

  const onClickDeliveryNotePrint = () => {
    if (!deliveryNote.documentId || !deliveryNote.accessToken) return

    Linking.openURL(
      DirectusUtil.getDocumentDownloadUrlFromId(deliveryNote.documentId, deliveryNote.accessToken),
    )
  }

  const getFnKey = () => {
    if (!deliveryNote || !deliveryNote?.type) return ''
    let QueryFn = ''
    if (deliveryNote.type === 1) {
      QueryFn = 'sf_tour_delivery_notes'
    }
    if (deliveryNote.type === 2) {
      QueryFn = 'sf_tour_delivery_orders'
    }
    if (deliveryNote.type === 3) {
      QueryFn = 'sf_tour_delivery_producers'
    }
    console.log(QueryFn)
    return QueryFn
  }

  const onRefreshDocument = async () => {
    setErrorMessage('')

    setIsLoading(true)
    const newDeliveryNote = await DeliveryNoteCarrierService.refresh(deliveryNoteId)
    if (newDeliveryNote?.id) {
      setTimeout(async () => {
        await refetchDeliveryNote()
        const tour = deliveryNote.order?.cart?.tour
        await queryClient.fetchQuery([getFnKey(), tour?.id])

        setIsLoading(false)
      }, 3000)
    } else {
      setErrorMessage('Erreur lors de la re-génération du PDF')
      setIsLoading(false)
    }
  }

  const onDelete = async () => {
    setErrorMessage('')
    const tour = deliveryNote.tour
    const type = deliveryNote.type
    if (!tour?.id || !type) return
    setIsLoading(true)
    const deletedDelivery = await DeliveryNoteCarrierService.deleteOne(deliveryNoteId)

    await queryClient.fetchQuery([getFnKey(), tour?.id])
    navigation.navigate('CarrierTourDeliveryNotes', {
      id: tour?.id,
      type: type,
    })

    setIsLoading(false)
  }

  const mailSent = (mailType: MailEnums): { sent: boolean; history: any } => {
    let isSent = false
    const history = deliveryNote.mailsStatus?.history
    let foundHistory = null
    if (history && history.length) {
      foundHistory = history.find((h) => h.type === mailType)
      isSent = foundHistory !== null
    }

    return { sent: isSent, history: foundHistory }
  }

  const mappedMailType = (): MailEnums => {
    let mappedMailType = MailEnums.DELIVERY_NOTE_PRODUCER_CARRIER
    if (deliveryNote.type === DeliveryNoteTypeEnum.PRODUCER_CLIENT) {
      mappedMailType = MailEnums.DELIVERY_NOTE_PRODUCER_CLIENT
    }
    if (deliveryNote.type === DeliveryNoteTypeEnum.CARRIER_CLIENT) {
      mappedMailType = MailEnums.DELIVERY_NOTE_CARRIER_CLIENT
    }
    return mappedMailType
  }

  if (isLoading) {
    return <Loader isLoading pageLoading />
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle
          title={`BL N°${
            deliveryNote.customNumber || deliveryNote.identifier
          } ( ${FormatUtils.getLabelFromDeliveryNoteType(deliveryNote.type)})`}
        />
        <Row>
          <Col xs={12} md={6}>
            <InfoCard leftIcon="link">
              <Typography.BodySmall colorName="text-dark-3">
                Emetteur ({producer?.label ? 'Producteur' : 'Super-producteur'})
              </Typography.BodySmall>
              <Typography.BodySmall bold>
                {producer?.label || carrier?.legalName}
              </Typography.BodySmall>
            </InfoCard>
          </Col>
          <Col xs={12} md={6}>
            <InfoCard leftIcon="link">
              <Typography.BodySmall colorName="text-dark-3">
                Destinataire ({client?.label ? 'Client' : 'Super-producteur'})
              </Typography.BodySmall>
              <Typography.BodySmall bold>
                {carrier ? carrier.name : client?.label}
              </Typography.BodySmall>
            </InfoCard>
          </Col>
        </Row>
        {errorMessage ? (
          <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
        ) : null}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <TotalSummaryCard>
          <TotalSummaryCardContent>
            <SummaryLeftBox>
              <Typography.Body>{'Total HT '}</Typography.Body>
              {(deliveryNote.totalVAT10 || 0) > 0 && (
                <Typography.Body>{'Total TVA 10%'}</Typography.Body>
              )}
              {(deliveryNote.totalVAT20 || 0) > 0 && (
                <Typography.Body>{'Total TVA 20%'}</Typography.Body>
              )}
              {(deliveryNote.totalVAT55 || 0) > 0 && (
                <Typography.Body>{'Total TVA 5.5%'}</Typography.Body>
              )}
              <Typography.Body bold>{'Total TTC '}</Typography.Body>
            </SummaryLeftBox>
            <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={2} />
            <SummaryRightBox>
              <Typography.Body bold>
                {FormatUtils.formatPrice(deliveryNote?.totalHTBatchs)}
              </Typography.Body>
              {(deliveryNote.totalVAT10 || 0) > 0 && (
                <Typography.Body>
                  {FormatUtils.formatPrice(deliveryNote?.totalVAT10)}
                </Typography.Body>
              )}
              {(deliveryNote.totalVAT20 || 0) > 0 && (
                <Typography.Body>
                  {FormatUtils.formatPrice(deliveryNote?.totalVAT20)}
                </Typography.Body>
              )}
              {(deliveryNote.totalVAT55 || 0) > 0 && (
                <Typography.Body>
                  {FormatUtils.formatPrice(deliveryNote?.totalVAT55)}
                </Typography.Body>
              )}
              <Typography.Body bold>
                {FormatUtils.formatPrice(deliveryNote?.totalTTC)}
              </Typography.Body>
            </SummaryRightBox>
          </TotalSummaryCardContent>
        </TotalSummaryCard>

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <Row>
          <Col xs={12} alignItems="center">
            <View>
              <TextInput
                value={updatedDeliveryNote?.customNumber || ''}
                label="N° de BL personnalisé"
                field="text"
                onChangeText={(text) => setValue(text, 'customNumber')}
                autoFocus
              />
            </View>
          </Col>
        </Row>
        <Row>
          <Col xs={12} alignItems="center">
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

            <Typography.BodySmall colorName="text-dark-3">
              Le bon de livraison sera regénéré
            </Typography.BodySmall>
          </Col>
        </Row>
        <Row>
          <Col xs={12} alignItems="center">
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

            <Button label={'Valider'} onPress={() => onUpdateDeliveryNote()} loading={isLoading} />
          </Col>
        </Row>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

        <SectionTitle title={`Actions`} />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <CardSettings
          title="Télécharger le BL"
          description={'Télécharger le BL'}
          children={
            <Button
              label="Télécharger"
              onPress={() => onClickDeliveryNotePrint()}
              disabled={isLoading || !deliveryNote.documentId}
              fullWidth={true}
              small
              loading={!deliveryNote.documentId && deliveryNoteDocumentRefreshs < 3}
            />
          }
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <CardSettings
          title="Re-générer le BL"
          description={'Re-générer le BL'}
          children={
            <Button
              label={'Générer'}
              onPress={() => onRefreshDocument()}
              loading={isLoading}
              colorName="color-grey"
              fullWidth={true}
            />
          }
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <CardSettings
          title="Envoyer le BL"
          description={'Envoyer le BL par mail'}
          children={
            <>
              <Typography.BodySmall semiBold colorName="color-primary" align="left">
                {mailSent(MailEnums.DELIVERY_NOTE_PRODUCER_CARRIER).sent &&
                  `Envoyé le ${FormatUtils.formatDate(
                    mailSent(mappedMailType()).history?.date,
                    'Date',
                  )}`}
              </Typography.BodySmall>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.6} />
              <Button
                label={'Envoyer'}
                onPress={() =>
                  navigation.navigate('CarrierTourDeliveryNoteSendMail', {
                    deliveryNoteId: deliveryNoteId,
                  })
                }
                fullWidth={true}
                colorName="color-grey"
                loading={isLoading}
              />
            </>
          }
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <CardSettings
          title="Supprimer le BL"
          description={`Si vous avez effectué plusieurs changements`}
          children={
            <Button
              label={'Supprimer'}
              onPress={() => onDelete()}
              loading={isLoading}
              fullWidth={true}
              colorName="color-grey"
              hasDoubleValidation
              confirmationLabel="Êtes-vous sûr de vouloir supprimer ce bon de livraison ?"
            />
          }
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourDeliveryNoteScreen
