import React from 'react'
import { useTheme } from 'styled-components/native'
import FormatUtils from '../../utilities/utils/format'
import Card from '../Card'
import Icon, { Icons } from '../Icon'
import Typography from '../Typography'
import { CardParamProps } from './CardParam.model'
import {
  StyledBox,
  StyledCardContent,
  StyledCardParam,
  StyledLeft,
  StyledLeftBox,
  StyledRight,
  StyledRightBox,
} from './CardParam.styles'

function CardParam({
  label,
  value,
  onClick,
  flex,
  isStart,
  isEnd,
  icon,
  rightBox,
}: CardParamProps) {
  const theme = useTheme()

  const onClickCard = () => {
    if (onClick) {
      onClick()
    }
  }

  const iconName = () => {
    if (icon === 'print') {
      return 'print'
    }
    if (icon === 'open-outline') {
      return 'open-outline'
    }
    if (icon === 'mail') {
      return 'mail-outline'
    }
    return 'md-pencil-sharp'
  }

  return (
    <StyledCardParam flex={flex} isStart={isStart} isEnd={isEnd}>
      <Card>
        <StyledCardContent onPress={onClickCard}>
          <StyledLeft>
            <Typography.BodySmall colorName="color-primary">{label}</Typography.BodySmall>
            <StyledBox>
              <StyledLeftBox>
                {typeof value === 'string' || typeof value === 'number' ? <Typography.Body>{value}</Typography.Body> : value}
              </StyledLeftBox>
              <StyledRightBox>
                {onClick ? (
                  <Icon
                    type={Icons.Ionicons}
                    name={iconName()}
                    size={theme.spacingUnit * (icon ? 2 : 1.5)}
                    color={theme.colors['color-primary']}
                  />
                ) : null}
              </StyledRightBox>
            </StyledBox>
          </StyledLeft>
          {rightBox ? <StyledRight>{rightBox}</StyledRight> : null}
        </StyledCardContent>
      </Card>
    </StyledCardParam>
  )
}

export default CardParam
