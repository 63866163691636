import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Card,
  Col,
  Icon,
  Icons,
  Row,
  Spacer,
  TitleWithTag,
  TouchableCard,
  Typography,
} from '../../../components'
import i18n from '../../../i18n'
import FormatUtils from '../../../utilities/utils/format'
import { BatchsCardProps } from './BatchsCard.model'
import {
  StyledButtonWrapper,
  StyledCardContent,
  StyledCardWrapper,
  StyledCenterBox,
  StyledImage,
  StyledLeftBox,
  StyledRightBox,
  StyledValueBox,
  StyledValuesWrapper,
  TextInlineBox,
} from './BatchsCard.styles'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { View } from 'react-native'
import { Batch, Product } from '../../../domain'
import { InterfaceColors } from '../../../utilities/styling/theme/theme'
import BatchUtil from '../../../utilities/utils/batch'
import DirectusUtil from '../../../utilities/utils/directus'
import { BatchEnums, UserEnums } from '../../../../enums'
import { TextTag } from '../../../components/Text/Text.model'
import useGroupContext from '../../../utilities/hook/useGroupContext'

const BatchsCard = ({
  productBatchs,
  onClick,
  isProducer,
  displayProducer,
  userRole,
}: BatchsCardProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [displaySubBatchs, setDisplaySubBatchs] = useState(false)
  const { group } = useGroupContext()

  const mainBatch =
    productBatchs.batchs && productBatchs.batchs[0] ? productBatchs.batchs[0] : undefined

  if (!mainBatch) {
    return <></>
  }
  const onClickBatch = (selectedBatch?: Batch) => {
    if (selectedBatch) {
      onClick(selectedBatch)
    } else {
      onClick(mainBatch)
    }
  }

  const getAvailableQuantity = (tempBatch: Batch) => {
    return tempBatch.availableQuantity || 0
  }

  const color = (tempBatch: Batch) => {
    if (
      getAvailableQuantity(tempBatch) / tempBatch.totalQuantity < 0.4 ||
      tempBatch.totalQuantity === 0
    ) {
      return 'color-danger'
    }
    return 'color-primary'
  }

  const batchComputed = BatchUtil.getBatchComputed({
    ...mainBatch,
  })

  const isSingleBatch = () => {
    if (productBatchs.batchs && productBatchs.batchs.length === 1) {
      return true
    }
    return false
  }

  const getBatchStatusMessage = (InBatch: Batch) => {
    if (InBatch.computed?.statusMessage && InBatch.computed?.statusMessage.length > 0) {
      return InBatch.computed?.statusMessage
    }
    return []
  }

  const isCarrier = () => {
    return userRole === UserEnums.RoleEnums.CARRIER
  }

  const renderStockIndicator = (tempBatch: Batch) => {
    return (
      <StyledValuesWrapper>
        <StyledValueBox>
          <Typography.BodySmall semiBold>
            {FormatUtils.formatQuantity(tempBatch.totalQuantity, tempBatch.product?.mesureType)}
          </Typography.BodySmall>
        </StyledValueBox>
        <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
        <StyledValueBox>
          <Icon
            type={Icons.MaterialCommunityIcons}
            name="circle-multiple"
            size={theme.spacingUnit * 2.5}
            color={theme.colors[color(tempBatch)]}
          />
        </StyledValueBox>
        <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
        <StyledValueBox>
          <Typography.BodySmall semiBold colorName={color(tempBatch)}>
            {FormatUtils.formatQuantity(
              getAvailableQuantity(tempBatch),
              tempBatch.product?.mesureType,
            )}
          </Typography.BodySmall>
        </StyledValueBox>
        <Spacer size={0.2} axis={Spacer.AxisEnum.HORIZONTAL} />
        <View>
          <Spacer size={0.75} axis={Spacer.AxisEnum.VERTICAL} />
          <Typography.BodyExtraSmall colorName={color(tempBatch)}>
            restant
          </Typography.BodyExtraSmall>
        </View>
      </StyledValuesWrapper>
    )
  }

  const subBatchs = productBatchs.batchs || []

  const renderSubBatchs = () => {
    if (isSingleBatch() || subBatchs.length < 2) {
      return <></>
    }

    return subBatchs.map((batch) => {
      const subBatchComputed = BatchUtil.getBatchComputed({
        ...batch,
      })

      return (
        <StyledCardWrapper
          onPress={() => onClickBatch(subBatchComputed)}
          activeOpacity={0.8}
          key={subBatchComputed.id}
        >
          <TouchableCard>
            <StyledCardContent>
              <Row>
                <Col xs={4}>
                  <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                  <Typography.BodySmall>
                    {subBatchComputed.computed?.periodLabel}
                  </Typography.BodySmall>
                  {subBatchComputed.isPrivate ||
                  subBatchComputed.carrierFees ||
                  getBatchStatusMessage(subBatchComputed).length > 0 ? (
                    <Typography.BodySmall colorName={'color-danger'}>
                      {subBatchComputed.carrierFees
                        ? `Commission ${subBatchComputed.carrierFees}%`
                        : ''}
                      {subBatchComputed.isPrivate
                        ? `${subBatchComputed.carrierFees ? '- ' : ''}Caché`
                        : ''}
                      {getBatchStatusMessage(subBatchComputed).length > 0 &&
                        `${
                          subBatchComputed.carrierFees || subBatchComputed.isPrivate ? '- ' : ''
                        }${FormatUtils.pluralizeAmountLabel(
                          'problème',
                          getBatchStatusMessage(subBatchComputed).length,
                        )}`}
                    </Typography.BodySmall>
                  ) : null}
                </Col>
                <Col xs={4} alignItems="center">
                  {renderStockIndicator(subBatchComputed)}
                </Col>
                <Col xs={4} alignItems="flex-end">
                  <View style={{ alignItems: 'flex-end' }}>
                    {isCarrier() ? (
                      <>
                        <Typography.BodyExtraSmall colorName="text-dark-3">
                          Prix client
                        </Typography.BodyExtraSmall>
                        <Spacer size={0.15} axis={Spacer.AxisEnum.VERTICAL} />
                        <Typography.BodySmall
                          semiBold
                          colorName={
                            !subBatchComputed.computed?.priceParams?.unitPricesFormatted
                              ? 'color-danger'
                              : 'text-dark-1'
                          }
                        >
                          {!subBatchComputed.computed?.priceParams?.unitPricesFormatted
                            ? 'NA'
                            : subBatchComputed.computed?.priceParams?.unitPricesFormatted}
                        </Typography.BodySmall>
                        <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                        {group?.hasPricingModule &&
                        subBatchComputed.computed?.pricesStats?.producerPriceBellow.min ? (
                          <Typography.BodyExtraSmall colorName={'color-warning'}>
                            Prix conseillé :{' '}
                            {FormatUtils.formatPrice(
                              subBatchComputed.computed?.pricesStats.unitPriceBellow?.min,
                            )}
                          </Typography.BodyExtraSmall>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <Typography.BodyExtraSmall colorName="text-dark-3">
                          Prix départ
                        </Typography.BodyExtraSmall>
                        <Spacer size={0.15} axis={Spacer.AxisEnum.VERTICAL} />
                        <Typography.BodySmall semiBold>
                          {subBatchComputed.computed?.priceParams?.producerPricesFormatted}
                        </Typography.BodySmall>
                        <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                        {group?.hasPricingModule &&
                        subBatchComputed.computed?.pricesStats?.producerPriceBellow.min ? (
                          <Typography.BodyExtraSmall colorName={'color-warning'}>
                            Prix conseillé :{' '}
                            {FormatUtils.formatPrice(
                              subBatchComputed.computed?.pricesStats.producerPriceBellow?.min,
                            )}
                          </Typography.BodyExtraSmall>
                        ) : null}
                      </>
                    )}
                  </View>
                </Col>
              </Row>
            </StyledCardContent>
          </TouchableCard>
        </StyledCardWrapper>
      )
    })
  }

  return (
    <>
      <StyledCardWrapper
        onPress={() =>
          isSingleBatch() ? onClickBatch(batchComputed) : setDisplaySubBatchs(!displaySubBatchs)
        }
        activeOpacity={0.8}
        key={batchComputed.id}
      >
        <TouchableCard>
          <StyledCardContent>
            <StyledLeftBox>
              <StyledImage
                source={DirectusUtil.getProductImageUrlFromId(
                  batchComputed.product as any as Product,
                )}
                resizeMode="cover"
              />
            </StyledLeftBox>
            <StyledCenterBox>
              <TitleWithTag tag={batchComputed.product?.internalReference}>
                <Typography.CardTitle
                  style={{
                    lineHeight: theme.lineHeights.CARD_TITLE - (displayProducer ? 4 : 0),
                    maxWidth: '100%',
                  }}
                  ellipsis
                >
                  {batchComputed.product?.label}
                </Typography.CardTitle>
              </TitleWithTag>

              {displayProducer && (
                <Typography.BodyExtraSmall ellipsis>
                  {batchComputed.product?.producer?.label}
                </Typography.BodyExtraSmall>
              )}
              <TextInlineBox>
                <Typography.BodySmall colorName={'text-dark-3'}>
                  par{' '}
                  {FormatUtils.formatQuantity(
                    batchComputed.product?.packing,
                    batchComputed.product?.mesureType,
                  )}
                </Typography.BodySmall>
                {batchComputed.isPrivate ||
                batchComputed.carrierFees ||
                getBatchStatusMessage(batchComputed).length > 0 ? (
                  <Typography.BodySmall colorName={'color-danger'}>
                    {' '}
                    ({batchComputed.carrierFees ? `Commission ${batchComputed.carrierFees}%` : ''}
                    {batchComputed.isPrivate ? `${batchComputed.carrierFees ? '- ' : ''}Caché` : ''}
                    {getBatchStatusMessage(batchComputed).length > 0 &&
                      `${batchComputed.carrierFees || batchComputed.isPrivate ? '- ' : ''}${
                        getBatchStatusMessage(batchComputed).length
                      } problème`}
                    )
                  </Typography.BodySmall>
                ) : null}
              </TextInlineBox>
              {isSingleBatch() ? renderStockIndicator(batchComputed) : null}
            </StyledCenterBox>
            <StyledRightBox>
              <View style={{ alignItems: 'flex-end' }}>
                {isSingleBatch() && (
                  <>
                    {isCarrier() ? (
                      <>
                        <Typography.BodyExtraSmall colorName="text-dark-3">
                          Prix client
                        </Typography.BodyExtraSmall>
                        <Spacer size={0.15} axis={Spacer.AxisEnum.VERTICAL} />
                        <Typography.BodySmall
                          semiBold
                          colorName={
                            !batchComputed.computed?.priceParams?.unitPricesFormatted
                              ? 'color-danger'
                              : 'text-dark-1'
                          }
                        >
                          {!batchComputed.computed?.priceParams?.unitPricesFormatted
                            ? 'NA'
                            : batchComputed.computed?.priceParams?.unitPricesFormatted}
                        </Typography.BodySmall>
                        <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                        {group?.hasPricingModule &&
                        batchComputed.computed?.pricesStats?.producerPriceBellow.min ? (
                          <Typography.BodyExtraSmall colorName={'color-warning'}>
                            Prix conseillé :{' '}
                            {FormatUtils.formatPrice(
                              batchComputed.computed?.pricesStats.unitPriceBellow?.min,
                            )}
                          </Typography.BodyExtraSmall>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <Typography.BodyExtraSmall colorName="text-dark-3">
                          Prix départ
                        </Typography.BodyExtraSmall>
                        <Spacer size={0.15} axis={Spacer.AxisEnum.VERTICAL} />
                        <Typography.BodySmall semiBold>
                          {batchComputed.computed?.priceParams?.producerPricesFormatted}
                        </Typography.BodySmall>
                        <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                        {group?.hasPricingModule &&
                        batchComputed.computed?.pricesStats?.producerPriceBellow.min ? (
                          <Typography.BodyExtraSmall colorName={'color-warning'}>
                            Prix conseillé :{' '}
                            {FormatUtils.formatPrice(
                              batchComputed.computed?.pricesStats.producerPriceBellow?.min,
                            )}
                          </Typography.BodyExtraSmall>
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </View>

              <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall>
                {isSingleBatch() ? batchComputed.computed?.periodLabel : ``}
              </Typography.BodySmall>
              {!isSingleBatch() && (
                <>
                  <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                  <StyledButtonWrapper onPress={() => setDisplaySubBatchs(!displaySubBatchs)}>
                    <Typography.BodySmall colorName="text-dark-1">
                      {displaySubBatchs
                        ? 'réduire'
                        : `afficher les ${FormatUtils.pluralizeAmountLabel(
                            'lot',
                            subBatchs.length,
                          )}`}
                    </Typography.BodySmall>
                    <Spacer size={0.4} axis={Spacer.AxisEnum.HORIZONTAL} />
                    <Icon
                      type={Icons.Ionicons}
                      name={displaySubBatchs ? 'md-remove-circle' : 'chevron-forward'}
                      color={theme.colors['text-dark-3']}
                      size={theme.spacingUnit * 2}
                    />
                  </StyledButtonWrapper>
                </>
              )}
            </StyledRightBox>
          </StyledCardContent>
        </TouchableCard>
      </StyledCardWrapper>
      {displaySubBatchs && (
        <View style={{ width: '95%', marginLeft: '3%' }}>{renderSubBatchs()}</View>
      )}
    </>
  )
}

export default BatchsCard
