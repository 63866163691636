import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import AddCartButton from '../../modules/Cart/AddCartButton'
import FormatUtils from '../../utilities/utils/format'
import DirectusUtil from '../../utilities/utils/directus'
import { ProducerStackParamList } from '../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { Product } from '../../domain'
import Typography from '../Typography'
import Spacer from '../Spacer'
import TouchableCard from '../TouchableCard'
import { CartBatchCardNewProps } from './CartBatchCardNew.model'
import {
  StyledCardWrapper,
  StyledCardContent,
  StyledLeftBox,
  StyledImage,
  StyledCenterBox,
  StyledValuesWrapper,
  StyledValueBox,
  StyledRightBox,
  StyledGainBox,
} from './CartBatchCardNew.styles'
import { TitleWithTag } from '..'

const CartBatchCardNew = ({
  cartBatch,
  cart,
  noButton,
  displayClientPrice,
  displayProducerPrice,
  displayProducerLabel,
  displayMinPrice,
  displayGain,
}: CartBatchCardNewProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const OnClickBatch = () => {
    // onClick(cartBatch?.batch!)
  }

  const gain =
    ((cartBatch?.unitPriceProducer || 0) - (cartBatch?.batch?.unitPriceProducer || 0)) /
    (cartBatch?.batch?.unitPriceProducer || 0)

  return (
    <StyledCardWrapper onPress={OnClickBatch} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <StyledImage
              source={DirectusUtil.getProductImageUrlFromId(
                cartBatch?.batch?.product as any as Product,
              )}
              resizeMode="cover"
            />
          </StyledLeftBox>
          <StyledCenterBox>
            <TitleWithTag tag={cartBatch?.batch?.product?.internalReference}>
              <Typography.CardTitle colorName="text-dark-1" ellipsis>
                {cartBatch?.batch?.product?.label}
              </Typography.CardTitle>
            </TitleWithTag>

            {displayProducerLabel && (
              <Typography.BodyExtraSmall colorName="text-dark-3">
                {cartBatch?.batch?.product?.producer?.label}
              </Typography.BodyExtraSmall>
            )}
            <StyledValuesWrapper>
              {displayMinPrice && (
                <>
                  <StyledValueBox>
                    <Typography.BodySmall>Prix min.</Typography.BodySmall>
                    <Typography.BodySmall semiBold>
                      {FormatUtils.formatPrice(
                        cartBatch?.batch?.unitPriceProducer,
                        undefined,
                        cartBatch?.batch?.product?.producer,
                      )}
                      /{FormatUtils.formatUnity(cartBatch?.batch?.product?.mesureType)}
                    </Typography.BodySmall>
                  </StyledValueBox>
                  <Spacer size={1.5} axis={Spacer.AxisEnum.HORIZONTAL} />
                </>
              )}
              {displayGain && gain > 0 && (
                <>
                  <StyledValueBox>
                    <Typography.BodySmall align="center">Gain</Typography.BodySmall>
                    <StyledGainBox>
                      <Typography.BodySmall semiBold>
                        +{FormatUtils.formatPercentage(gain)}
                      </Typography.BodySmall>
                    </StyledGainBox>
                  </StyledValueBox>
                  <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
                </>
              )}
              {displayProducerPrice && (
                <StyledValueBox>
                  <Typography.BodySmall>Votre prix</Typography.BodySmall>
                  <Typography.BodySmall semiBold>
                    {FormatUtils.formatPrice(
                      cartBatch?.unitPriceProducer,
                      undefined,
                      cartBatch?.batch?.product?.producer,
                    )}
                    /{FormatUtils.formatUnity(cartBatch?.batch?.product?.mesureType)}
                  </Typography.BodySmall>
                </StyledValueBox>
              )}
              {displayClientPrice && (
                <>
                  {displayMinPrice ? <Spacer size={1.5} axis={Spacer.AxisEnum.HORIZONTAL} /> : null}
                  <StyledValueBox>
                    <Typography.BodySmall>Prix client</Typography.BodySmall>
                    <Typography.BodySmall semiBold>
                      {FormatUtils.formatPrice(cartBatch?.unitPrice)}/
                      {FormatUtils.formatUnity(cartBatch?.batch?.product?.mesureType)}
                    </Typography.BodySmall>
                  </StyledValueBox>
                </>
              )}
              <Spacer size={1.5} axis={Spacer.AxisEnum.HORIZONTAL} />
            </StyledValuesWrapper>
          </StyledCenterBox>
          <StyledRightBox>
            <View style={{ alignItems: 'flex-end' }}>
              <Typography.BodySmall colorName={'text-dark-3'}>
                par{' '}
                {FormatUtils.formatQuantity(
                  cartBatch?.batch?.product?.packing,
                  cartBatch?.batch?.product?.mesureType,
                )}
              </Typography.BodySmall>
            </View>
            {noButton && (
              <StyledValueBox>
                <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
                <Typography.BodySmall semiBold>
                  {cartBatch?.packingQuantity} colis
                </Typography.BodySmall>
                <Typography.BodySmall>
                  {FormatUtils.formatQuantity(
                    cartBatch?.totalQuantity,
                    cartBatch?.batch?.product?.mesureType,
                  )}
                </Typography.BodySmall>
              </StyledValueBox>
            )}
            <View>
              {!noButton && <AddCartButton cart={cart!} batch={cartBatch?.batch!} small />}
            </View>
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default CartBatchCardNew
