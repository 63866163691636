import { Batch, Group, Pagination, Tour } from '../../../domain'
import { batchsByGroup } from '../../../screens/Producer/ProducerArrivalPreparation'
import ApiService from '../../api.service'

const getAllTours = async (
  producerId: string,
  next?: Date,
  previous?: Date,
  available?: boolean,
  limit?: number,
  pageNumber: number = 1,
  searchOnDepositDate?: boolean,
): Promise<{ data: Tour[]; pagination: Pagination }> => {
  const response = await ApiService.get(`/tour/producer/${producerId}`, {
    after: next ? next : null,
    before: previous ? previous : null,
    available: available ? 'true' : null,
    limit,
    page: pageNumber,
    searchOnDepositDate: searchOnDepositDate ? true : null,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOneTour = async (tourId: string): Promise<Tour> => {
  const response = await ApiService.get(`/tour/${tourId}`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const getOneTourBatchs = async (tourId: string, producerId: string): Promise<Tour> => {
  const response = await ApiService.get(`/tour/${tourId}/producer/${producerId}/batch`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const getOneTourAllBatchs = async (tourId: string): Promise<Tour> => {
  const response = await ApiService.get(`/tour/${tourId}/batch`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const getOneTourProducerOrders = async (tourId: string, producerId: string): Promise<Tour> => {
  const response = await ApiService.get(`/tour/${tourId}/producer/${producerId}`, {})
    .then((result) => {
      return result
    })
    .catch((error) => {
      throw error
    })
  return response
}

const join = async (producerId: string, tourId: string): Promise<Tour> => {
  const response = await ApiService.post(`/tour/${tourId}/producer/${producerId}/join`, {}).catch(
    (error) => {
      throw error
    },
  )
  return response
}

const duplicateProposition = async (
  producerId: string,
  tourId: string,
  dto: any,
): Promise<Tour> => {
  const response = await ApiService.post(
    `/tour/${tourId}/producer/${producerId}/duplicate-proposition`,
    dto,
  ).catch((error) => {
    throw error
  })
  return response
}

const getAllDayArrivalBatchs = async (
  idProducer: string,
  start?: Date,
  end?: Date,
  limit: number = 30,
  pageNumber: number = 1,
): Promise<{ batchs: batchsByGroup; tours: Tour[] }> => {
  const response = await ApiService.get(`/tour/arrival/producer/${idProducer}/batch`, {
    after: start ? new Date(start) : null,
    before: end ? new Date(end) : null,
    limit,
    page: pageNumber,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const TourProducerService = {
  getAllTours,
  getOneTour,
  getOneTourBatchs,
  getOneTourAllBatchs,
  getOneTourProducerOrders,
  join,
  duplicateProposition,
  getAllDayArrivalBatchs,
}

export default TourProducerService
