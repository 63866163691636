import { Alert, TouchableOpacity, View } from 'react-native'
import { Button, Icon, Icons, Spacer, Typography } from '../../../components'
import {
  QuantityInputWrapper,
  StyledValueBox,
  StyledValuesButtonWrapper,
} from './AddCartButton.styles'
import CartClientService from '../../../services/client/cart'
import { queryClient } from '../../../utilities/queryClient'
import { useEffect, useState } from 'react'
import { CartBatch, UdpateCartBatch } from '../../../domain'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { AddCartButtonProps } from './AddCartButton.model'
import FormatUtils from '../../../utilities/utils/format'
import { useTheme } from 'styled-components/native'
import QuantityInput from '../../../components/inputs/QuantityInput'

const AddCartButton = ({ batch, cart, small }: AddCartButtonProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [cartBatch, setCartBatch] = useState<CartBatch | undefined>(undefined)
  const [packingQuantityValue, setPackingQuantityValue] = useState('')
  const [totalQuantityValue, setTotalQuantityValue] = useState<number | undefined>(undefined)

  const theme = useTheme()

  const { getAccessInfos } = useAuthenticationContext()

  useEffect(() => {
    if (!cart) return
    if (!cart?.cartBatchs || cart?.cartBatchs?.length < 1) {
      setCartBatch(undefined)
      setPackingQuantityValue('')
      setTotalQuantityValue(undefined)
      return
    }

    const foundCartBatch = cart.cartBatchs.find((cb) => cb.batch?.id === batch.id)
    if (foundCartBatch) {
      setCartBatch(foundCartBatch)
      setPackingQuantityValue(FormatUtils.floatToString(foundCartBatch.packingQuantity))
      setTotalQuantityValue(foundCartBatch.totalQuantity)
    }
  }, [cart])

  const onAddCartBatch = async () => {
    if (isLoading || !batch.product?.packing) return
    setIsLoading(true)
    const newCartbatch: UdpateCartBatch = {
      batchId: batch.id,
      clientId: getAccessInfos().clientId || cart.client?.id,
      packingQuantity: 1,
      packing: batch.product?.packing,
      totalQuantity: batch.product?.packing,
      unitPrice: batch.unitPrice,
      unitPriceProducer: batch.unitPriceProducer,
      carrierFees: batch.priceCarrierFees,
      otherBatchs: batch.otherBatchs,
    }

    const updateResponse = await CartClientService.updateCartBatch(cart.id, newCartbatch)
    if (updateResponse.id) {
      const newCartBatchs = updateResponse.cartBatchs

      const newCartBatch = newCartBatchs?.find((cb) => cb.batch?.id === batch.id)

      if (newCartBatch) {
        setCartBatch(newCartBatch)
        setPackingQuantityValue(FormatUtils.floatToString(newCartBatch.packingQuantity))
        setTotalQuantityValue(newCartBatch.totalQuantity)
      }
    }
    queryClient.invalidateQueries(['c_cart', cart.id])

    setIsLoading(false)
  }

  const onUpdateCartBatch = async (changeQuatity: number) => {
    if (isLoading || !cartBatch || !batch.product?.packing) return
    if (changeQuatity > 0 && !batchIsAvailable()) {
      setPackingQuantityValue(FormatUtils.floatToString(cartBatch.packingQuantity))
      setTotalQuantityValue(cartBatch.totalQuantity)
      return
    }
    setIsLoading(true)

    let newQuantity = (cartBatch.packingQuantity || 0) + changeQuatity
    newQuantity = newQuantity < 0 ? 0 : newQuantity

    const newTotalQuantity = Math.round(batch.product?.packing * newQuantity * 100) / 100

    const newCartbatch: UdpateCartBatch = {
      batchId: batch.id,
      clientId: getAccessInfos().clientId || cart.client?.id,
      packingQuantity: newQuantity,
      packing: batch.product?.packing,
      totalQuantity: newTotalQuantity,
      unitPrice: batch.unitPrice,
      unitPriceProducer: batch.unitPriceProducer,
      carrierFees: batch.priceCarrierFees,
      otherBatchs: batch.otherBatchs,
    }

    const updateResponse = await CartClientService.updateCartBatch(cart.id, newCartbatch)
    if (updateResponse.id) {
      const newLocalCartBatch = cartBatch
      newLocalCartBatch.packingQuantity = newQuantity
      newLocalCartBatch.totalQuantity = newTotalQuantity
      setCartBatch(newLocalCartBatch)
      setPackingQuantityValue(FormatUtils.floatToString(newLocalCartBatch.packingQuantity))
      setTotalQuantityValue(newLocalCartBatch.totalQuantity)
    }
    queryClient.invalidateQueries(['c_cart', cart.id])
    queryClient.invalidateQueries(['p_cart'])
    queryClient.invalidateQueries(['sf_cart'])
    setIsLoading(false)
  }

  const batchIsAvailable = () => {
    if (
      batch?.availableQuantity &&
      batch.product?.packing &&
      batch?.availableQuantity >= batch.product?.packing
    ) {
      const availableQuantity = batch?.availableQuantity - (cartBatch?.totalQuantity || 0)
      return availableQuantity > 0
    }
    return false
  }

  const onChangePackingQuantityValue = (value: string) => {
    const newPackingQuantity = FormatUtils.stringToFloat(value)

    if (batch.product?.packing) {
      const newTotalQuantity =
        Math.round((newPackingQuantity || 0) * batch.product?.packing * 100) / 100
      setTotalQuantityValue(newTotalQuantity)
    }

    setPackingQuantityValue(value)
  }

  const onChangeQuantity = () => {
    const newPackingQuantity = FormatUtils.stringToFloat(packingQuantityValue)
    const diffQuantity = newPackingQuantity - (cartBatch?.packingQuantity || 0)
    onUpdateCartBatch(diffQuantity)
  }

  return (
    <>
      {cartBatch && cartBatch?.totalQuantity && cartBatch?.totalQuantity > 0 ? (
        <View style={{ alignItems: 'center', width: '100%' }}>
          <StyledValuesButtonWrapper>
            <View style={{ flex: 1 }}></View>
            <StyledValueBox>
              <TouchableOpacity onPress={() => onUpdateCartBatch(-1)} disabled={isLoading}>
                <Icon
                  type={Icons.Ionicons}
                  name="remove-circle"
                  size={small ? theme.spacingUnit * 3 : theme.spacingUnit * 3.5}
                  color={theme.colors['color-primary']}
                />
              </TouchableOpacity>
            </StyledValueBox>
            <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
            {small ? (
              <StyledValueBox>
                <Typography.Body semiBold colorName={'color-primary'}>
                  {cartBatch?.packingQuantity} colis
                </Typography.Body>
                <Typography.Body semiBold colorName={'color-primary'}>
                  ({FormatUtils.formatQuantity(cartBatch?.totalQuantity, batch.product?.mesureType)}
                  )
                </Typography.Body>
              </StyledValueBox>
            ) : (
              <StyledValueBox>
                <QuantityInputWrapper>
                  <QuantityInput
                    field="text"
                    value={packingQuantityValue}
                    suffix="colis"
                    onChangeText={onChangePackingQuantityValue}
                    onBlurCallBack={onChangeQuantity}
                    onKeyPressCallBack={onChangeQuantity}
                    small
                  />
                </QuantityInputWrapper>
              </StyledValueBox>
            )}

            <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
            <StyledValueBox>
              <TouchableOpacity
                onPress={() => onUpdateCartBatch(1)}
                disabled={isLoading || !batchIsAvailable()}
              >
                <Icon
                  type={Icons.Ionicons}
                  name="add-circle"
                  size={small ? theme.spacingUnit * 3 : theme.spacingUnit * 3.5}
                  color={
                    !batchIsAvailable() ? theme.colors['color-grey'] : theme.colors['color-primary']
                  }
                />
              </TouchableOpacity>
            </StyledValueBox>
            <View style={{ flex: 1 }}></View>
          </StyledValuesButtonWrapper>
          {!small && totalQuantityValue && totalQuantityValue > 0 && (
            <>
              <Spacer size={0.1} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall semiBold colorName={'color-primary'} align="center">
                ({FormatUtils.formatQuantity(totalQuantityValue, batch.product?.mesureType, true)})
              </Typography.BodySmall>
            </>
          )}
        </View>
      ) : (
        <StyledValuesButtonWrapper>
          <View style={{ flex: 1 }}></View>
          <Button
            small
            lowPadding
            rounded
            label={batchIsAvailable() ? 'Ajouter' : 'Rupture'}
            onPress={() => onAddCartBatch()}
            disabled={isLoading || !batchIsAvailable()}
          />
          <View style={{ flex: 1 }}></View>
        </StyledValuesButtonWrapper>
      )}
    </>
  )
}

export default AddCartButton
