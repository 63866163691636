import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { Button, PageTitle, Spacer, Typography } from '../../../components'
import {
  BatchsFake,
  Cart,
  DeliveryNote,
  Order,
  ProducersFake,
  StepsFake,
  Tour,
  ToursFake,
} from '../../../domain'
import i18n from '../../../i18n'
import BatchCartCard from '../../../modules/Batch/BatchCartCard'
import TourProducerCard from '../../../modules/Tour/TourProducerCard'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import CarrierTourProducerTopBox from './components/CarrierTourProducerTopBox'
import { StyledButtonWrapper, ToursContentWrapper } from './CarrierTourProducer.styles'
import { useQuery } from '@tanstack/react-query'
import TourCarrierService from '../../../services/carrier/tour'
import { View } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { queryClient } from '../../../utilities/queryClient'
import Loader from '../../../components/Loader'
import DeliveryNoteCarrierService from '../../../services/carrier/deliveryNote'
import TourDeliveryNoteCard from '../../../modules/DeliveryNote/TourDeliveryNoteCard'
import { UserEnums } from '../../../../enums'
import TourUtil from '../../../utilities/utils/tour'

const CarrierTourProducerScreen = () => {
  const theme = useTheme()
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierTourProducer'>>()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const { id, idProducer } = route?.params ?? { id: '', idProducer: '' }

  const {
    data: tour,
    refetch: refetchTour,
    isInitialLoading: tourLoading,
  } = useQuery<Tour, Error>(
    ['sf_tour_producer'],
    () => TourCarrierService.getOneTourProducer(id, idProducer),
    {
      keepPreviousData: false,
    },
  )

  const {
    data: deliveryNotes,
    refetch: refetchDeliveryNotes,
    isInitialLoading: deliveryNotesLoading,
  } = useQuery<DeliveryNote[], Error>(
    ['sf_tour_delivery_notes', id, idProducer],
    () => DeliveryNoteCarrierService.getAllTour(id, undefined, undefined, idProducer),
    {
      keepPreviousData: true,
    },
  )

  const getTourIdFormatted = () => {
    if (tour && tour.id && tour.id.length > 5) {
      return tour.id.slice(-5)
    }
    return 'NA'
  }

  const getOrderIdFormatted = (order?: Order) => {
    if (order && order.id && order.id.length > 5) {
      return order.id.slice(-5)
    }
    return 'NA'
  }

  if (tourLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!tour) return <View></View>

  const producerTour = tour.producerTour
    ? tour.producerTour.find((producerTour) => producerTour.producer.id === idProducer)
    : null
  const carts = tour.carts

  const onClickRemoveProducer = async () => {
    if (!producerTour) return
    await TourCarrierService.removeProducer(tour.id, producerTour.producer.id)
    await queryClient.invalidateQueries(['sf_tour', tour.id])
    navigation.navigate('CarrierTourProducers', { id: tour.id })
  }

  const getTotalOrderProducer = (cart: Cart) => {
    const cartBatchs = cart.cartBatchs
    let totalHT = 0
    if (!cartBatchs) return totalHT
    cartBatchs.map((cartBatch) => {
      totalHT += (cartBatch.totalQuantity || 0) * (cartBatch.unitPrice || 0)
    })
    return totalHT
  }
  const renderCarts = () => {
    if (!carts || carts.length < 1) return null
    return carts.map((cart) => {
      return (
        <View key={cart.id}>
          <Typography.SectionTitle>
            Commande #{getOrderIdFormatted(cart.order)} - {cart.client?.label}
          </Typography.SectionTitle>
          <Typography.BodySmall style={{ marginLeft: theme.spacingUnit * 2 }}>
            Total de la commande : {FormatUtils.formatPrice(getTotalOrderProducer(cart), 'ht')}
          </Typography.BodySmall>
          {cart.cartBatchs?.map((cartBatch) => (
            <BatchCartCard
              key={cartBatch.id}
              cartBatch={cartBatch}
              onClick={() => null}
              userRole={UserEnums.RoleEnums.CARRIER}
              withStatusButton={false}
            />
          ))}
        </View>
      )
    })
  }

  const onClickDeliveryNote = (deliveryNote: DeliveryNote) => {
    if (!deliveryNote.id) return
    navigation.navigate('CarrierTourDeliveryNote', {
      deliveryNoteId: deliveryNote.id,
    })
  }

  if (!producerTour) return <View></View>

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`Collect #${getTourIdFormatted()}`}
            buttonRight={
              <Typography.BodySmall>
                {FormatUtils.capitalize(FormatUtils.formatDate(tour.start, 'FullDate'))}
                {'\n'}
                {FormatUtils.capitalize(
                  FormatUtils.formatDate(tour.start, 'StartTime+EndTime', tour.end),
                )}
              </Typography.BodySmall>
            }
          />
          <CarrierTourProducerTopBox producerTour={producerTour} tour={tour} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          {carts && carts.length > 0 ? (
            renderCarts()
          ) : (
            <>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.Body colorName="color-danger" align="center">
                Aucune commande à livrer pour ce producteur
              </Typography.Body>
              <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
              <StyledButtonWrapper>
                <Button
                  label={'Retirer de la tournée'}
                  onPress={() => onClickRemoveProducer()}
                  colorName="color-grey"
                  disabled={TourUtil.isTourCompleted(tour)}
                />
              </StyledButtonWrapper>
            </>
          )}
          <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
          {(deliveryNotes || []).length > 0 && (
            <Typography.SectionTitle colorName="text-dark-3">
              Bons de livraison
            </Typography.SectionTitle>
          )}

          {(deliveryNotes || []).map((deliveryNote) => (
            <TourDeliveryNoteCard
              key={deliveryNote.id}
              deliveryNote={deliveryNote}
              onClick={onClickDeliveryNote}
              isCarrier={false}
            />
          ))}
          <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierTourProducerScreen
