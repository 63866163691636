import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import i18n from '../../../i18n'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import FormatUtils from '../../../utilities/utils/format'
import { TourProducerCardProps } from './TourProducerCard.model'
import {
  StyledCardContent,
  StyledCardWrapper,
  StyledLeftBox,
  StyledMiddleBox,
  StyledRightBox,
} from './TourProducerCard.styles'
import { ProducerStatusEnum } from '../../../../enums/producer'
import BadgeStatus from '../../../components/BadgeStatus'

const TourProducerCard = ({ producerTour, tour }: TourProducerCardProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const OnClickProducer = () => {
    navigation.navigate('CarrierTourProducer', {
      id: tour.id,
      idProducer: producerTour.producer.id,
    })
  }

  const producer = producerTour.producer

  return (
    <StyledCardWrapper onPress={OnClickProducer} activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <StyledLeftBox>
            <Typography.CardTitle ellipsis>{producer.label}</Typography.CardTitle>
            <Typography.BodySmall colorName="color-primary" bold>
              {FormatUtils.pluralizeAmountLabel('commande', producerTour?.nbOrders || 0)}
            </Typography.BodySmall>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.Body colorName="text-dark-3">
              {FormatUtils.formatPrice(producerTour.totalIncome)}
              HT - {producerTour.nbCrates || 0} colis
            </Typography.Body>
          </StyledLeftBox>
          <StyledMiddleBox>
            <View style={{ alignItems: 'flex-end' }}>
              <Typography.BodySmall colorName="text-dark-3">
                Espace : {producerTour.usedCapacity}%
              </Typography.BodySmall>
            </View>
            <View style={{ alignItems: 'flex-end' }}>
              {producerTour.producer.status !== undefined &&
              producerTour.producer.status < ProducerStatusEnum.ONBOARDING ? (
                <>
                  <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                  <BadgeStatus
                    label="En cours d'inscription"
                    color="text-light-1"
                    backgroundColor="text-dark-3"
                  />
                </>
              ) : null}
            </View>
          </StyledMiddleBox>
          <StyledRightBox>
            <Icon
              type={Icons.Ionicons}
              name="chevron-forward-outline"
              size={theme.spacingUnit * 2}
              color={theme.colors['text-dark-3']}
            />
          </StyledRightBox>
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default TourProducerCard
