import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { useTheme } from 'styled-components/native'
import { CardPageLink } from '../../../components'
import PageTitle from '../../../components/PageTitle'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'

const ProducerBillingScreen = () => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const { getAccessInfos } = useAuthenticationContext()

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Gestion financière" />
        <CardPageLink
          label={`Bons de livraison`}
          icon="arrow-right"
          onClick={() => navigation.navigate('ProducerBillingDeliveryNotes', {})}
        />
        <CardPageLink
          label={`Clients`}
          icon="arrow-right"
          onClick={() => navigation.navigate('ProducerClients', {})}
        />
        <CardPageLink
          label={`Configuration des paiements`}
          icon="arrow-right"
          onClick={() => navigation.navigate('ProducerBillingPaymentConfiguration', {})}
        />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerBillingScreen
