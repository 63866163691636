import { ProducerVatTypeEnum } from '../../../../../../enums/producer'
import { Typography, Card, Button, Row, Col, SectionTitle, Spacer } from '../../../../../components'
import { Producer } from '../../../../../domain'
import BatchCatalog from '../../../../../modules/Batch/BatchCatalog'
import CartBatchCard from '../../../../../modules/Cart/CartBatchCard'
import AccountUtil from '../../../../../utilities/utils/account'
import DirectusUtil from '../../../../../utilities/utils/directus'
import { ClientCartBatchsProps } from './ClientCartBatchs.model'
import {
  BoxWrapper,
  LeftBox,
  ProducerBox,
  RightBox,
  StyledProducerImage,
} from './ClientCartBatchs.styles'

const ClientCartBatchs = ({ producer, cartBatchs, catalog, cart }: ClientCartBatchsProps) => {
  return (
    <BoxWrapper>
      <ProducerBox>
        <LeftBox>
          <StyledProducerImage
            source={{
              uri: DirectusUtil.getProducerImageUrlFromId(producer as any as Producer),
            }}
            resizeMode="cover"
          />
        </LeftBox>
        <RightBox>
          <Typography.Body bold colorName="text-dark-3">
            {producer.label}
          </Typography.Body>
          {producer?.account && !AccountUtil.isProfileSubjectToVAT(producer.account) && (
            <>
              <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
              <Typography.BodySmall colorName="color-danger">
                Ce producteur n'est pas assujetti à la TVA, elle n'est pas déductible sur ses
                produits.
              </Typography.BodySmall>
            </>
          )}
        </RightBox>
      </ProducerBox>
      {(cartBatchs || []).map((cartBatch) => {
        return (
          <CartBatchCard key={cartBatch.id} cartBatch={cartBatch} catalog={catalog} cart={cart} />
        )
      })}
    </BoxWrapper>
  )
}

export default ClientCartBatchs
