import { useQuery } from '@tanstack/react-query'
import { Pagination } from '../../domain'
import { Invoice } from '../../domain/Invoice'
import ApiService from '../api.service'

interface GetAllParams {
  accountId: string
  limit?: number
  pageNumber?: number
  getByIssuer?: boolean
  withPayment?: boolean
  onlyLastOne?: boolean
}

const getAll = async ({
  accountId,
  limit = 30,
  pageNumber = 1,
  getByIssuer,
  withPayment,
  onlyLastOne,
}: GetAllParams): Promise<{ data: Invoice[]; pagination?: Pagination }> => {
  if (!accountId) {
    return { data: [], pagination: undefined }
  }
  const response = await ApiService.get(`/invoice`, {
    limit,
    page: pageNumber,
    accountId,
    getByIssuer: getByIssuer,
    withPayment,
    onlyLastOne,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOne = async (invoiceId: string | undefined): Promise<Invoice> => {
  const response = await ApiService.get(`/invoice/${invoiceId}`, {})
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

export type UseGetInvoicesArgs = {
  accountId: string
  limit?: number
  pageNumber?: number
  getByIssuer?: boolean
  withPayment?: boolean
  onlyLastOne?: boolean
}

function useGetInvoices(args: UseGetInvoicesArgs) {
  function getQueryKey() {
    return [
      'sf_billing_invoices',
      args.accountId,
      args.limit ? `limit_${args.limit}` : '',
      args.pageNumber ? `pageNumber_${args.pageNumber}` : '',
      args.getByIssuer ? 'getByIssuer' : '',
      args.withPayment ? 'withPayment' : '',
      args.onlyLastOne ? 'onlyLastOne' : '',
    ].filter(Boolean)
  }

  function query() {
    return useQuery<{ data: Invoice[]; pagination?: Pagination }, Error>({
      queryKey: getQueryKey(),
      queryFn: () =>
        getAll({
          accountId: args.accountId,
          limit: args.limit,
          pageNumber: args.pageNumber,
          getByIssuer: args.getByIssuer,
          withPayment: args.withPayment,
          onlyLastOne: args.onlyLastOne,
        }),
    })
  }

  return {
    getQueryKey,
    query,
  }
}
const InvoiceService = {
  getOne,
  useGetInvoices,
}

export default InvoiceService
