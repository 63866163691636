import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  PageTitle,
  Row,
  SectionTitle,
  Spacer,
  TextInput,
  Typography,
} from '../../../components'
import { AddBatch, BatchComputed, Group, Pagination, Product, ProductBatchs } from '../../../domain'
import i18n from '../../../i18n'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import BatchProducerService from '../../../services/producer/batch'
import ProductProducerService from '../../../services/producer/product'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import {
  ToursContentWrapper,
  StyledButtonWrapper,
  StyledCardContent,
  StyledCardWrapper,
  StyledGainBox,
} from './ProducerAddBatch.styles'
import { BatchEnums } from '../../../../enums'
import { BATCH_TYPES } from '../../../../enums/batch'
import BatchUtil from '../../../utilities/utils/batch'
import { TextTag } from '../../../components/Text/Text.model'
import GroupProducerService from '../../../services/producer/group'

const ProducerAddBatchScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerAddBatch'>>()
  const [crateQuantityString, setCrateQuantityString] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedType, setSelectedType] = useState<any>()
  const [selectedProduct, setSelectedProduct] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [displayProducerPriceInfos, setDisplayProducerPriceInfos] = useState<boolean>(false)
  const [displayCrossedPrice, setDisplayCrossedPrice] = useState<boolean>(false)

  const {
    data: groups,
    refetch: refetchGroups,
    isInitialLoading: groupsLoading,
  } = useQuery<Group[], Error>(
    ['p_groups'],
    () => GroupProducerService.getAllGroupsByProducerId(getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )

  const hasGroupWithCrossedPriceFeature = (groups: Group[]): boolean => {
    return groups.some((group) => group.hasCrossedPriceFeature)
  }

  const { type, start, end, tourId } = route?.params ?? {
    type: undefined,
    start: undefined,
    end: undefined,
    tourId: undefined,
  }

  const [newBatch, setNewBatch] = useState<AddBatch>({
    type: undefined,
    start: undefined,
    end: undefined,
    tourId: undefined,
    productId: undefined,
    unitPriceProducer: '',
    unitPrice: undefined,
    crossedOutPrice: undefined,
    totalQuantity: undefined,
    expiration: undefined,
  })

  useEffect(() => {
    const newBatchTemp: AddBatch = Object.assign({}, newBatch)
    if (type) {
      newBatchTemp.type = type
      setSelectedType({
        label: BATCH_TYPES.find((bt) => bt.value === type)?.label,
        value: type,
      })
    }

    if (start) {
      const tempStartDate = new Date(start)
      tempStartDate.setHours(2)
      tempStartDate.setMinutes(0)
      tempStartDate.setSeconds(0)
      newBatchTemp.start = tempStartDate
    }

    if (end) {
      const tempEndDate = new Date(end)
      tempEndDate.setHours(23)
      tempEndDate.setMinutes(59)
      tempEndDate.setSeconds(0)
      newBatchTemp.end = tempEndDate
    }

    if (tourId) {
      newBatchTemp.tourId = tourId
    }

    setNewBatch(newBatchTemp)
  }, [type, start, end, tourId])

  const {
    data: products,
    refetch: refetchNextProducts,
    isInitialLoading: nextProductsLoading,
  } = useQuery<{ data: Product[]; pagination: Pagination }, Error>(
    ['p_products'],
    () => ProductProducerService.getAllProducts(getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: computedUpdatedBatch,
    refetch: refetchComputedUpdatedBatch,
    isInitialLoading: computedUpdatedBatchLoading,
  } = useQuery<BatchComputed | null, Error>(
    ['p_add_computed_batch'],
    () =>
      BatchProducerService.getComputed(selectedProduct?.id, {
        start: newBatch?.start ? new Date(newBatch?.start) : undefined,
        end: newBatch?.end ? new Date(newBatch?.end) : undefined,
        unitPriceProducer: newBatch?.unitPriceProducer
          ? FormatUtils.stringToFloat(newBatch?.unitPriceProducer)
          : undefined,
      }),
    {
      enabled:
        selectedProduct !== undefined &&
        newBatch !== undefined &&
        newBatch.unitPriceProducer !== '',
    },
  )

  const {
    data: batchs,
    refetch: refetchBatchs,
    isInitialLoading: batchsLoading,
  } = useQuery<{ data: ProductBatchs[]; pagination: Pagination }, Error>(
    ['p_batchs_add_batch'],
    () =>
      BatchProducerService.getAllBatchsProducerComputed(
        getAccessInfos().producerId,
        newBatch?.start,
        newBatch?.end,
        undefined,
        undefined,
        100,
        1,
      ),
    {
      keepPreviousData: true,
      enabled: newBatch?.end !== undefined,
    },
  )

  const setValue = async (value: any, param: string) => {
    if (!param && !(param in newBatch)) return null
    const batchTemp = Object.assign({}, newBatch)

    batchTemp[param as keyof typeof newBatch] = value

    await setNewBatch(batchTemp)
    refetchComputedUpdatedBatch()
  }

  const onChangeCrateQuantity = (crateQuantityString: string) => {
    if (!crateQuantityString || crateQuantityString === '0') {
      setValue('0', 'totalQuantity')
    }
    const crateQuantity = parseFloat(crateQuantityString)
    const totalQuantity = crateQuantity * (selectedProduct?.packing || 1)
    setValue(totalQuantity.toString(), 'totalQuantity')
    setCrateQuantityString(crateQuantityString)
  }

  const onChangeType = async (selectedTypeTemp: any) => {
    await setSelectedType(selectedTypeTemp)
    await setValue(selectedTypeTemp.value, 'type')
    if (newBatch.start) {
      await onChangeDate('start', newBatch.start, selectedTypeTemp?.value)
    }
  }

  const onChangeProduct = async (value: any) => {
    await setSelectedProduct(value)
    await setValue('unitPriceProducer', '')
    onChangeCrateQuantity('')
    refetchComputedUpdatedBatch()
  }

  const onChangeDate = async (
    attribute: 'start' | 'end',
    value: any,
    forcedType?: BatchEnums.TYPE,
  ) => {
    setErrorMessage('')
    const batchType = forcedType || selectedType.value

    const newBatchTemp: AddBatch = Object.assign({}, newBatch)
    if (attribute === 'start') {
      const tempStartDate = new Date(value)
      tempStartDate.setHours(0)
      tempStartDate.setMinutes(0)
      tempStartDate.setSeconds(0)
      newBatchTemp.start = tempStartDate

      if (batchType === BatchEnums.TYPE.DAY) {
        const tempEndDate = new Date(tempStartDate)
        tempEndDate.setHours(23)
        tempEndDate.setMinutes(59)
        tempEndDate.setSeconds(0)
        newBatchTemp.end = tempEndDate
        newBatchTemp.expiration = undefined
      }
      if (batchType === BatchEnums.TYPE.WEEK) {
        const tempEndDate = new Date(tempStartDate)
        tempEndDate.setDate(FormatUtils.startOfWeek(tempEndDate))
        tempEndDate.setDate(tempEndDate.getDate() + 6)
        tempEndDate.setHours(23)
        tempEndDate.setMinutes(59)
        tempEndDate.setSeconds(0)
        newBatchTemp.end = tempEndDate
        newBatchTemp.expiration = undefined
      }
      if (batchType === BatchEnums.TYPE.MONTH) {
        newBatchTemp.end = undefined
      }
    }
    if (attribute === 'end') {
      const tempEndDate = new Date(value)
      tempEndDate.setHours(23)
      tempEndDate.setMinutes(59)
      tempEndDate.setSeconds(0)
      newBatchTemp.end = tempEndDate
    }

    await setNewBatch(newBatchTemp)
    refetchBatchs()
    refetchComputedUpdatedBatch()
  }

  const onChangeExpirationDate = (value: any) => {
    setErrorMessage('')

    const expirationTemp = Object.assign({}, newBatch)
    expirationTemp.expiration = value

    setNewBatch(expirationTemp)
  }

  const onAddBatch = async () => {
    setErrorMessage('')
    if (!selectedProduct) {
      setErrorMessage('Veuillez sélectionner un produit')
      return
    }
    if (!controlPrices()) {
      setErrorMessage('Votre prix de départ ne peut pas être inférieur ou égale à 0')
      return
    }
    if (!newBatch.start || !newBatch.end) {
      setErrorMessage('Date de début ou de fin de validité invalide')
      return
    }

    if (newBatch.start >= newBatch.end) {
      setErrorMessage('La date de début doit être avant la date de fin')
      return
    }

    if (newBatch.expiration !== undefined && newBatch.end > newBatch.expiration) {
      if (newBatch.end.toISOString() !== newBatch.expiration.toISOString()) {
        setErrorMessage("La date de fin de validité doit être avant la date d'expiration")
        return
      }
    }

    let tempType = newBatch.type
    // if (we change type if needed regarding dates)
    if (newBatch.start && newBatch.end) {
      const diff = newBatch.end.getTime() - newBatch.start.getTime()
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24))
      if (diffDays <= 1) {
        tempType = BatchEnums.TYPE.DAY
      } else if (
        diffDays <= 7 &&
        FormatUtils.startOfWeekDate(newBatch.start) === FormatUtils.startOfWeekDate(newBatch.end)
      ) {
        tempType = BatchEnums.TYPE.WEEK
      } else {
        tempType = BatchEnums.TYPE.MONTH
      }

      await setSelectedType({
        label: BATCH_TYPES.find((bt) => bt.value === tempType)?.label,
        value: tempType,
      })
    }

    setIsLoading(true)

    const batch = {
      start: FormatUtils.dateToUtcString(newBatch.start, '00:00:00'),
      end: FormatUtils.dateToUtcString(newBatch.end, '23:59:59'),
      type: tempType,
      productId: selectedProduct.id,
      unitPriceProducer: FormatUtils.stringToFloat(newBatch.unitPriceProducer),
      unitPrice: FormatUtils.stringToFloat(newBatch.unitPrice),
      crossedOutPrice: FormatUtils.stringToFloat(newBatch.crossedOutPrice),
      totalQuantity: FormatUtils.stringToFloat(newBatch.totalQuantity),
      expiration: newBatch.expiration,
    }

    const createdBatch = await BatchProducerService.create(getAccessInfos().producerId, batch)
    if (createdBatch && createdBatch.id) {
      queryClient.invalidateQueries(['p_producer', getAccessInfos().producerId])
      onConfirm(tempType)
    } else {
      setErrorMessage('Un problème est survenu lors de la création du lot')
    }
    setIsLoading(false)
  }

  const onConfirm = async (inType?: BatchEnums.TYPE) => {
    // await queryClient.refetchQueries(['p_batchs'])
    // await queryClient.setQueryData(['p_batchs'], undefined)
    // await queryClient.setQueryData(['p_batchs_dashboard'], undefined)

    // await queryClient.invalidateQueries(['p_batchs_dashboard'])

    let mode: 'DAY' | 'WEEK' | 'MONTH' | undefined = undefined
    const type = inType || selectedType.value
    if (type === BatchEnums.TYPE.DAY) {
      mode = 'DAY'
    } else if (type === BatchEnums.TYPE.WEEK) {
      mode = 'WEEK'
    } else if (type === BatchEnums.TYPE.MONTH) {
      mode = 'MONTH'
    }

    const params = {
      mode: mode,
      start: newBatch?.start || undefined,
    }

    // navigation.goBack()
    navigation.navigate('ProducerPropositions', params)
  }

  const controlPrices = () => {
    const unitPriceProducer = FormatUtils.stringToFloat(newBatch.unitPriceProducer)
    if (unitPriceProducer < 0.01) return false

    return true
  }

  const onApplyPriceStatement = async () => {
    await setValue(
      FormatUtils.floatToString(
        Math.round((batchComputed.computed?.pricesStats?.producerPriceBellow?.min || 0) * 100) /
          100,
      ),
      'unitPriceProducer',
    )
    refetchComputedUpdatedBatch()
  }

  const getEndDateLimit = () => {
    if (selectedType.value === BatchEnums.TYPE.WEEK && newBatch.start) {
      const limitDate = new Date(newBatch.start)
      limitDate.setDate(FormatUtils.startOfWeek(limitDate))
      limitDate.setDate(limitDate.getDate() + 6)
      return limitDate
    }
    return undefined
  }

  const getProductLabel = (product: Product) => {
    const alreadyAvailableProducts = batchs?.data

    if (
      alreadyAvailableProducts &&
      alreadyAvailableProducts.find((b) => b.product?.id === product.id)
    ) {
      return `${product.label} (déjà dans la mercuriale)`
    }
    return product.label
  }

  const batchComputed = BatchUtil.getBatchComputed({
    ...newBatch,
    id: 'NA',
    computed: computedUpdatedBatch ? computedUpdatedBatch : undefined,
    start: new Date(newBatch.start || new Date()).toString(),
    end: new Date(newBatch.end || new Date()).toString(),
    unitPriceProducer: FormatUtils.stringToFloat(newBatch.unitPriceProducer),
    unitPrice: FormatUtils.stringToFloat(newBatch.unitPrice),
    crossedOutPrice: displayCrossedPrice
      ? FormatUtils.stringToFloat(newBatch.crossedOutPrice)
      : undefined,
    totalQuantity: FormatUtils.stringToFloat(newBatch.totalQuantity),
    carrierFees: FormatUtils.stringToFloat(newBatch.carrierFees),
    availableQuantity: FormatUtils.stringToFloat(newBatch.totalQuantity) || 0,
    orderedQuantity: 0,
  })

  const hasPriceStatment = () => {
    return (
      computedUpdatedBatch?.pricesStats?.producerPrice.min !==
        computedUpdatedBatch?.pricesStats?.producerPrice.max && !computedUpdatedBatchLoading
    )
  }

  const renderPriceInformation = () => {
    const priceInformation = BatchUtil.getPriceInformation(batchComputed, selectedProduct, newBatch)

    if (!priceInformation) return null

    return (
      <>
        <Typography.BodySmall colorName="text-dark-1">
          {priceInformation?.productMinPriceStatement}
        </Typography.BodySmall>
        <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
        {priceInformation.description && (
          <>
            <Typography.BodySmall colorName="text-dark-1">
              {priceInformation.description}
            </Typography.BodySmall>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
          </>
        )}

        <Typography.BodySmall colorName="text-dark-1">
          {priceInformation.feesStatement}
        </Typography.BodySmall>
        <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
        <Typography.BodySmall colorName="text-dark-1">
          {priceInformation.clientPriceStatement}
        </Typography.BodySmall>
      </>
    )
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <ScrollableFormWrapper>
            <PageTitle title={FormatUtils.capitalize(i18n.t('pageTitleAddBatch'))} />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Dropdown
              label={'Choisir un mode'}
              onChange={onChangeType}
              multiple={false}
              optionsDefault={BATCH_TYPES.map((element) => ({
                label: element.label,
                value: element.value,
              }))}
              defaultValue={selectedType?.value}
              zIndex={1000}
              itemKey="type"
            />

            {selectedType && (
              <>
                <SectionTitle title={`Disponibilité`} />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                <DatePicker
                  id="batchStart"
                  label={
                    selectedType?.value === BatchEnums.TYPE.DAY
                      ? 'Date de validité'
                      : 'Début de validité (inclus)'
                  }
                  mode="date"
                  onChange={(value) => onChangeDate('start', value)}
                  value={newBatch.start}
                  withDay
                />
                {newBatch.start && selectedType.value !== BatchEnums.TYPE.DAY && (
                  <>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
                    <DatePicker
                      id="batchEnd"
                      label="Fin de validité (inclus)"
                      mode="date"
                      minDate={newBatch.start}
                      maxDate={getEndDateLimit()}
                      onChange={(value) => onChangeDate('end', value)}
                      value={newBatch.end}
                      withDay
                    />
                  </>
                )}
              </>
            )}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
            <Typography.BodySmall>
              Ce lot sera disponible dans au moins{' '}
              {FormatUtils.pluralizeAmountLabel(
                'tournée',
                batchComputed?.computed?.carriersFeesParams?.carrierFees?.length || 0,
              )}{' '}
              de livraison
            </Typography.BodySmall>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.5} />
            <Dropdown
              label={i18n.t('chooseProduct')}
              onChange={onChangeProduct}
              multiple={false}
              optionsDefault={(products?.data || []).map((product) => ({
                ...product,
                label: getProductLabel(product),
                value: product.id,
              }))}
              zIndex={1000}
              itemKey="product"
              hidden={!newBatch.start || !newBatch.end}
              isSearchable
            />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1.5} />
            {selectedType?.value === BatchEnums.TYPE.MONTH && (
              <DatePicker
                id="batchexpirationdate"
                label="DLC (optionnelle - visible par les clients)"
                mode="date"
                onChange={onChangeExpirationDate}
                value={newBatch.expiration}
                hidden={!selectedProduct}
              />
            )}
            {selectedProduct && (
              <>
                <SectionTitle title={`Prix du lot`} />
                <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                {batchComputed.computed?.pricesStats?.producerPriceBellow?.min && (
                  <>
                    <Card>
                      <Row>
                        <Col
                          xs={7}
                          sm={7}
                          md={7}
                          lg={7}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography.BodySmall align="center" bold>
                            Prix producteur recommandé
                          </Typography.BodySmall>
                          <Typography.BodyExtraSmall align="center" colorName="text-dark-3">
                            Vous ne pouvez pas proposer un prix inférieur. Nous prendrons toujours
                            le prix le plus haut
                          </Typography.BodyExtraSmall>
                        </Col>
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography.Body bold align="center" colorName="color-warning">
                            {FormatUtils.formatPrice(
                              batchComputed.computed?.pricesStats.producerPriceBellow?.min,
                            )}
                          </Typography.Body>
                        </Col>
                        <Col
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          alignItems="flex-end"
                          justifyContent="center"
                        >
                          <Button
                            label={'Appliquer'}
                            onPress={() => onApplyPriceStatement()}
                            disabled={isLoading}
                            small
                          />
                        </Col>
                      </Row>
                    </Card>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  </>
                )}
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={hasPriceStatment() ? 4 : 6}
                    lg={hasPriceStatment() ? 4 : 6}
                  >
                    <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                    <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={newBatch.unitPriceProducer?.toString()}
                      label={`Votre prix min. HT`}
                      field="text"
                      onChangeText={(text) => setValue(text, 'unitPriceProducer')}
                      editable
                      hidden={!selectedProduct}
                      suffix={`€/${FormatUtils.formatUnity(selectedProduct.mesureType)}`}
                    />
                    <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                    <Typography.BodyExtraSmall colorName="text-dark-3">
                      Frais Harvy déduits :{' '}
                      {batchComputed.computed?.priceParams?.producerPriceWithPlatformFeesFormatted}
                    </Typography.BodyExtraSmall>
                    <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                    <Button.Text
                      label={`${displayProducerPriceInfos ? '-' : '+'} d'informations sur le prix`}
                      onPress={() => setDisplayProducerPriceInfos(!displayProducerPriceInfos)}
                      colorName="text-dark-3"
                      small
                      textTag={TextTag.BODY_SMALL}
                    />
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={hasPriceStatment() ? 4 : 2}
                    lg={hasPriceStatment() ? 4 : 2}
                    alignItems={'center'}
                  >
                    <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                    {hasPriceStatment() && (
                      <StyledCardWrapper>
                        <Card>
                          <StyledCardContent>
                            <Typography.BodyExtraSmall>Prix réels</Typography.BodyExtraSmall>
                            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                            <Typography.BodySmall bold>
                              {batchComputed.computed?.priceParams?.producerPricesFormatted}
                            </Typography.BodySmall>
                            <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                            {batchComputed.computed?.priceParams?.producerPriceGainPercentage && (
                              <StyledGainBox>
                                <Typography.BodyExtraSmall
                                  bold
                                  colorName="text-light-1"
                                  align="center"
                                >
                                  {batchComputed.computed?.priceParams?.producerPriceGainPercentage}
                                </Typography.BodyExtraSmall>
                              </StyledGainBox>
                            )}
                          </StyledCardContent>
                        </Card>
                      </StyledCardWrapper>
                    )}
                  </Col>
                  <Col xs={6} sm={6} md={4} lg={4} alignItems={'center'}>
                    <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                    <StyledCardWrapper>
                      <Card>
                        <StyledCardContent>
                          <Typography.BodyExtraSmall>
                            Prix {FormatUtils.capitalize(FormatUtils.getLabelFromClientType(1))} HT
                          </Typography.BodyExtraSmall>
                          <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                          {batchComputed.computed?.priceParams?.unitPricesFormatted ? (
                            <>
                              <Typography.BodySmall bold>
                                {batchComputed.computed?.priceParams?.unitPricesFormatted}
                              </Typography.BodySmall>
                              <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                              {batchComputed.computed?.priceParams?.carrierFeesFormatted && (
                                <Typography.BodyExtraSmall colorName="text-dark-3" align="center">
                                  (incluant les{' '}
                                  {batchComputed.computed?.priceParams?.carrierFeesFormatted} de
                                  frais logistique)
                                </Typography.BodyExtraSmall>
                              )}
                            </>
                          ) : (
                            <Typography.BodyExtraSmall colorName="text-dark-3" align="center">
                              Aucune tournée proposé pour le moment
                            </Typography.BodyExtraSmall>
                          )}
                        </StyledCardContent>
                      </Card>
                    </StyledCardWrapper>
                    <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                    {groups && hasGroupWithCrossedPriceFeature(groups) && !displayCrossedPrice && (
                      <Button.Text
                        label={`Afficher un prix barré`}
                        onPress={() => setDisplayCrossedPrice(true)}
                        colorName="text-dark-3"
                        small
                        textTag={TextTag.BODY_SMALL}
                      />
                    )}
                  </Col>
                </Row>

                {displayProducerPriceInfos && (
                  <>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <Typography.Body colorName="text-dark-1" bold>
                      Explication de votre prix
                    </Typography.Body>
                    <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                    {renderPriceInformation()}
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                    <Typography.Body colorName="text-dark-1" bold>
                      Tournées où ce lot sera proposé :{' '}
                    </Typography.Body>
                    {batchComputed.computed?.pricesParams?.map((ps) => (
                      <>
                        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.2} />
                        <Typography.BodySmall colorName="text-dark-1" bold>
                          - {ps.tour.city?.label} -{' '}
                          {FormatUtils.formatDate(ps.tour.start, 'FullDate+StartTime')}
                        </Typography.BodySmall>
                        <Typography.BodySmall colorName="text-dark-1">
                          Prix producteur : {FormatUtils.formatPrice(ps.producerPrice)}/
                          {FormatUtils.formatUnity(selectedProduct?.mesureType)} - Com. logistique :{' '}
                          {FormatUtils.formatPercentage(ps.carrierFees / 100)} - Prix client :{' '}
                          {FormatUtils.formatPrice(ps.unitPrice)}/
                          {FormatUtils.formatUnity(selectedProduct?.mesureType)}
                        </Typography.BodySmall>
                      </>
                    ))}
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
                  </>
                )}
              </>
            )}
            {groups && hasGroupWithCrossedPriceFeature(groups) && displayCrossedPrice && (
              <Row rowStyles={{ justifyContent: 'center', alignItems: 'center' }}>
                <Col xs={6}>
                  <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={newBatch.crossedOutPrice?.toString()}
                    label={`Prix avant remise (par ${FormatUtils.formatUnity(
                      selectedProduct?.mesureType,
                    )})`}
                    field="text"
                    onChangeText={(text) => setValue(text, 'crossedOutPrice')}
                    editable
                    hidden={!selectedProduct}
                  />
                </Col>
                <Col xs={6} alignItems={'center'}>
                  <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
                  <Button.Text
                    label={'Supprimer le prix barré'}
                    onPress={() => {
                      setValue(undefined, 'crossedOutPrice')
                      setDisplayCrossedPrice(false)
                    }}
                    colorName="text-dark-3"
                    small
                    textTag={TextTag.BODY_SMALL}
                  />
                </Col>
              </Row>
            )}

            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            {newBatch.unitPriceProducer && (
              <>
                <SectionTitle title={`Quantités en vente`} />
                <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
                <Row>
                  <Col xs={6}>
                    <TextInput
                      value={crateQuantityString}
                      label={`Colis disponibles`}
                      field="text"
                      onChangeText={(text) => onChangeCrateQuantity(text)}
                      editable
                    />
                  </Col>
                  <Col xs={6} alignItems={'center'}>
                    <Typography.BodySmall>
                      {FormatUtils.formatUnity(selectedProduct?.mesureType)} / colis
                    </Typography.BodySmall>
                    <Typography.Body bold colorName="text-dark-3">
                      {FormatUtils.formatQuantity(
                        selectedProduct?.packing || 1,
                        selectedProduct?.mesureType,
                      )}
                    </Typography.Body>
                    <Spacer size={0.3} axis={Spacer.AxisEnum.VERTICAL} />
                    <Typography.BodySmall>Quantité en vente</Typography.BodySmall>
                    <Typography.Body bold colorName="text-dark-3">
                      {FormatUtils.formatQuantity(
                        parseFloat(newBatch.totalQuantity || '0'),
                        selectedProduct?.mesureType,
                      )}
                    </Typography.Body>
                  </Col>
                </Row>
              </>
            )}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            {batchComputed.computed?.statusMessage &&
            batchComputed.computed?.statusMessage?.length > 0 ? (
              <>
                <SectionTitle title={`Problèmes sur ce lot`} />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.7} />
                {batchComputed.computed.statusMessage.map((st) => (
                  <>
                    <Typography.Body bold colorName="color-danger">
                      {st.title}
                    </Typography.Body>
                    <Typography.BodySmall colorName="color-danger">
                      {st.details}
                    </Typography.BodySmall>
                  </>
                ))}
              </>
            ) : null}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <StyledButtonWrapper>
              {errorMessage ? (
                <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
              ) : null}
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              {newBatch.totalQuantity && parseFloat(newBatch.totalQuantity) > 0 && (
                <Button
                  label={FormatUtils.capitalize(i18n.t('addBatch'))}
                  onPress={() => onAddBatch()}
                  loading={isLoading}
                  // disabled={!isPricesProducerGreaterThanPriceStatement()}
                />
              )}
            </StyledButtonWrapper>
          </ScrollableFormWrapper>
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerAddBatchScreen
