import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { Button, Col, PageTitle, Row, Spacer, Typography } from '../../../../../components'
import TextInput from '../../../../../components/inputs/TextInput'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../../../utilities/styling/wrappers'
import { FormContentWrapper, StyledButtonWrapper } from './RegistrationForm.styles'
import { AuthenticationStackParamList } from '../../../../../navigation/AuthenticationNavigationStack/AuthenticationNavigationStack.model'
import { useQuery } from '@tanstack/react-query'
import { AddUserFromInvitation, Client, Producer, User, Carrier } from '../../../../../domain'
import AuthenticationService from '../../../../../services/authentication'
import { useEffect, useState } from 'react'
import { StackNavigationProp } from '@react-navigation/stack'
import useAuthenticationContext from '../../../../../utilities/hook/useAuthenticationContext'
import StorageUtil from '../../../../../utilities/storage/storage'

const RegistrationFormScreen = () => {
  const route = useRoute<RouteProp<AuthenticationStackParamList, 'RegistrationForm'>>()
  const { token } = route?.params ?? { token: '' }
  const navigation = useNavigation<StackNavigationProp<AuthenticationStackParamList>>()

  const { validateInvitation } = useAuthenticationContext()

  const {
    data: invitation,
    refetch: refetchInvitation,
    isInitialLoading: isInvitationLoading,
  } = useQuery<{ user: User; producer?: Producer; client?: Client; carrier?: Carrier }, Error>(
    ['check_invitation', token],
    () => AuthenticationService.checkInvitation(token),
    {
      keepPreviousData: true,
    },
  )

  if (!token || !invitation?.user?.email) {
    navigation.goBack()
    return <></>
  }

  const [newUser, setNewUser] = useState<AddUserFromInvitation>({
    producerLabel: invitation?.producer?.label,
    clientLabel: invitation?.client?.label,
    carrierLabel: invitation?.carrier?.name,
    email: invitation?.user.email,
    password: '',
    passwordConfirmation: '',
  })

  const [errorMessage, setErrorMessage] = useState<string>('')

  const setValue = (value: any, param: string) => {
    setErrorMessage('')
    if (!param && !(param in newUser)) return null

    const userTemp = Object.assign({}, newUser)

    userTemp[param as keyof typeof newUser] = value
    setNewUser(userTemp)
  }

  const onRegister = async () => {
    if (!newUser.email || !newUser.password || !newUser.passwordConfirmation) {
      setErrorMessage('Veuillez remplir tous les champs')
      return
    }

    if (newUser.password !== newUser.passwordConfirmation) {
      setErrorMessage('Les mots de passe ne correspondent pas')
      return
    }

    if (newUser.password.length < 8) {
      setErrorMessage('Le mot de passe doit contenir au moins 8 caractères')
      return
    }
    setErrorMessage('')
    if (newUser) {
      const createdUser = await validateInvitation(newUser.email, newUser.password)
      if (!createdUser) {
        setErrorMessage('Les données saisies sont incorrectes')
        return
      }

      navigation.navigate('ProfileSwitcher', {})
    }
  }

  const computeLabel = (): { key: string; value: string } => {
    if (newUser?.producerLabel) {
      return { key: 'producerLabel', value: newUser?.producerLabel }
    }
    if (newUser?.clientLabel) {
      return { key: 'clientLabel', value: newUser?.clientLabel }
    }
    if (newUser?.carrierLabel) {
      return { key: 'name', value: newUser?.carrierLabel }
    }
    return { key: '', value: '' }
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav noPaddingLeft>
      <ContentWrapper noDrawer noHeader>
        <FormContentWrapper>
          <PageTitle title="Inscription" />

          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ScrollableFormWrapper>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={computeLabel()?.value}
                  label={newUser?.clientLabel ? `Nom de l'enseigne` : "Nom de l'exploitation"}
                  field="text"
                  editable={false}
                  onChangeText={(text) => setValue(text, computeLabel()?.key)}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={newUser.email}
                  label="E-mail"
                  field="text"
                  onChangeText={(text) => setValue(text, 'email')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={newUser.password}
                  label="Mot de passe"
                  field="password"
                  secureTextEntry
                  onChangeText={(text) => setValue(text, 'password')}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={12}>
                <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                <TextInput
                  value={newUser.passwordConfirmation}
                  label="Confirmez votre mot de passe"
                  field="password"
                  secureTextEntry
                  onChangeText={(text) => setValue(text, 'passwordConfirmation')}
                />
              </Col>
            </Row>
            <Spacer size={1} />
            {errorMessage ? (
              <Typography.Body colorName="color-danger" align="center">
                {errorMessage}
              </Typography.Body>
            ) : null}
            <Spacer size={1} />
          </ScrollableFormWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <StyledButtonWrapper>
            <Button label={'Valider'} onPress={() => onRegister()} />
          </StyledButtonWrapper>
        </FormContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default RegistrationFormScreen
