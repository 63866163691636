import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import DeviceUtil from '../../utilities/utils/device'
import BottomTabs from '../components/BottomTabs'
import CustomDrawer from '../components/Drawer'
import { NavigationItemType } from '../Navigation.model'
import { Icons } from '../../components'
import HeaderHome from '../components/HeaderHome'
import ClientDashboardScreen from '../../screens/Client/ClientDashboard'
import CatalogClientNavigation from './CatalogClientNavigation/CatalogClientNavigation'
import OrderClientNavigation from './OrderClientNavigation/OrderClientNavigation'
import ProducerClientNavigation from './ProducerClientNavigation/ProducerClientNavigation'

const navigationItems: NavigationItemType[] = [
  {
    route: 'ClientDashboard',
    label: 'Accueil',
    type: Icons.Ionicons,
    icon: 'home',
    component: ClientDashboardScreen,
    screenOptions: {
      headerShown: true,
      header: () => <HeaderHome />,
    },
  },
  {
    route: 'ClientCatalog',
    label: 'Catalogue',
    type: Icons.Ionicons,
    icon: 'cart',
    component: CatalogClientNavigation,
    screenOptions: {
      headerShown: false,
    },
  },
  {
    route: 'ClientOrders',
    label: 'Commandes',
    type: Icons.MaterialCommunityIcons,
    icon: 'clipboard-list',
    component: OrderClientNavigation,
    screenOptions: {
      headerShown: false,
    },
  },
  {
    route: 'ClientProducers',
    label: 'Producteurs',
    type: Icons.Ionicons,
    icon: 'people-circle-sharp',
    component: ProducerClientNavigation,
    screenOptions: {
      headerShown: false,
    },
  },
  /*
  {
    route: 'ClientBilling',
    label: 'Facturation',
    type: Icons.Ionicons,
    icon: 'cash',
    component: ClientDashboardScreen,
    screenOptions: {
      headerShown: false,
    },
  },
  */
]

const Stack = createStackNavigator()

const ClientMainNavigationStack = () => {
  if (DeviceUtil.isMobileApp()) {
    return <BottomTabs items={navigationItems} />
  } else {
    return <CustomDrawer items={navigationItems} />
  }
}

export default ClientMainNavigationStack
