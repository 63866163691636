import { CarrierTourProducersProps } from './CarrierTourProducers.model'
import { Button, SectionTitle, Spacer, Typography } from '../../../../../components'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyledButtonWrapper } from './CarrierTourProducers.styles'
import TourProducerCard from '../../../../../modules/Tour/TourProducerCard'
import { CarrierStackParamList } from '../../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourUtil from '../../../../../utilities/utils/tour'
import { View } from 'react-native'

const CarrierTourProducersV2 = ({ tour }: CarrierTourProducersProps) => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  if (!tour) return null

  const producersTour = tour.producerTour || []

  const OnClickAddProducer = () => {
    navigation.navigate('CarrierTourAddProducer', { tourId: tour.id })
  }

  return (
    <>
      <SectionTitle
        title={`Participants (${producersTour.length | 0})
`}
      />
      {producersTour.map((producerTour) => (
        <TourProducerCard key={producerTour.id} producerTour={producerTour} tour={tour} />
      ))}
      {TourUtil.isTourCompleted(tour) && (
        <View style={{ width: '100%', alignItems: 'center' }}>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <Typography.Body colorName="color-grey">
            Cette tournée est terminée, vous ne pouvez plus ajouter de client à cette tournée.
          </Typography.Body>
        </View>
      )}
      <StyledButtonWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <Button
          label="Ajouter un producteur"
          onPress={() => OnClickAddProducer()}
          disabled={TourUtil.isTourCompleted(tour)}
        />
      </StyledButtonWrapper>
    </>
  )
}

export default CarrierTourProducersV2
