import { ProductEnums } from '../../../enums'
import { Cart, CartBatch, PlatformFeesRules } from '../../domain'
import FormatUtils from './format'
import GeneralUtils, { getExactNumber } from './general'

const getOrderTotals = (cart: Cart, producerId?: string) => {
  const producerCartBatches = cart?.cartBatchs?.filter(
    (cb) => cb?.batch?.product?.producer?.id === producerId,
  )

  const total = {
    totalHTBatchsProducer: 0,
    totalHTBatchs: 0,
    totalVAT55: 0,
    totalVAT10: 0,
    totalVAT20: 0,
    totalTTC: 0,
  }

  producerCartBatches?.forEach((cartBatch) => {
    const { batch } = cartBatch
    const vatRate = batch?.product?.vatRate

    const producer = batch?.product?.producer
    const htPriceProducer = (cartBatch?.unitPriceProducer || 0) * (cartBatch?.totalQuantity || 0)
    const htPrice = (cartBatch?.totalQuantity || 0) * (cartBatch?.unitPrice || 0)
    const vat = GeneralUtils.getVatFromHT(vatRate!, htPrice, producer)

    const ttcPrice = getExactNumber(htPrice + vat)

    total.totalHTBatchsProducer += htPriceProducer

    total.totalHTBatchs += htPrice
    if (vatRate == ProductEnums.VAT_ENUM.VAT55) {
      total.totalVAT55 += vat
    }
    if (vatRate == ProductEnums.VAT_ENUM.VAT10) {
      total.totalVAT10 += vat
    }
    if (vatRate == ProductEnums.VAT_ENUM.VAT20) {
      total.totalVAT20 += vat
    }
    total.totalTTC += ttcPrice
  })

  return total
}

const calculateSubtotal = (cartBatches: CartBatch[] | undefined) => {
  return cartBatches?.reduce((accumulator, cartBatch) => {
    const batchPrice = (cartBatch?.unitPrice || 0) * (cartBatch?.totalQuantity || 0)
    return accumulator + batchPrice
  }, 0)
}

const CartUtil = {
  getOrderTotals,
  calculateSubtotal,
}

export default CartUtil
