import React, { useEffect, useMemo, useState } from 'react'
import { Keyboard, Platform } from 'react-native'
import type { ItemType, ValueType } from 'react-native-dropdown-picker'
import DropDownPicker from 'react-native-dropdown-picker'
import { useTheme } from 'styled-components/native'
import { stringToKey } from '../../utilities/helpers'
import Icon, { Icons } from '../Icon'
import TextInputLabel from '../inputs/TextInputLabel'
import Badge from './components/Badge'
import ListItem from './components/ListItem'
import { DropdownMultiplePropTypes } from './Dropdown.model'
import { getDropdownStyles, StyledDropdownWrapper } from './Dropdown.styles'

function Dropdown({
  label,
  defaultValue,
  onChange,
  customItemAvailable,
  isSearchable,
  optionsDefault = [],
  itemKey,
  multiple,
  editable,
  required,
  hasError,
  zIndex,
  zIndexInverse,
  evOpen,
  evClose,
  hidden,
  dropDownDirection,
  small = false,
}: DropdownMultiplePropTypes) {
  const theme = useTheme()
  const [inputWidth, setInputWidth] = useState(0)
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<ValueType | ValueType[] | null>(null)
  const [items, setItems] = useState<ItemType<ValueType>[]>([])
  const [borderColor, setBorderColor] = useState(theme.colors['color-primary'])
  const [backgroundColor, setBackgroundColor] = useState(theme.colors['color-input'])
  const [direction, setDirection] = useState<string>(dropDownDirection || 'BOTTOM')

  useEffect(() => {
    if (hasError) {
      setBorderColor(theme.colors['color-danger'])
    } else {
      setBorderColor(theme.colors['color-border'])
      setBackgroundColor(theme.colors['color-input'])
    }
  }, [hasError, theme])

  const isActive = useMemo(() => {
    if (multiple) {
      return !!((value || value === 0) && (value as ValueType[]).length > 0)
    }
    return !!(value || value === 0)
  }, [multiple, value])

  const onFocus = () => {
    if (evOpen) evOpen()
    setBorderColor(theme.colors['color-primary'])
  }

  const onBlur = () => {
    if (evClose) evClose()
    if (hasError) {
      setBorderColor(theme.colors['color-danger'])
    } else {
      setBorderColor(theme.colors['color-border'])
      setBackgroundColor(theme.colors['color-input'])
    }
  }

  useEffect(() => {
    setValue(defaultValue as any)
  }, [defaultValue])

  useEffect(() => {
    setItems(optionsDefault)
  }, [optionsDefault])

  useEffect(() => {
    if (hidden) {
      setValue(null)
    }
  }, [hidden])

  const textColor = theme.colors['text-dark-1']

  const {
    containerStyle,
    textStyle,
    textInputStyle,
    dropdownContainerStyle,
    tickIconStyle,
    arrowIconStyle,
    scrollViewStyle,
  } = getDropdownStyles(theme, inputWidth, direction, small)

  if (hidden) return null

  // const zIndexComputed = direction === 'bottom' ? zIndexInverse : zIndex
  const zIndexComputed = 1

  return (
    <>
      <StyledDropdownWrapper
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        onLayout={(e) => {
          const { width } = e.nativeEvent.layout
          setInputWidth(width)
        }}
        zIndex={zIndexComputed || 200}
        open={open}
        small={small}
      >
        {!small && <TextInputLabel
          label={label}
          editable={editable ?? false}
          isActive={isActive}
          required={required}
        />}
        <DropDownPicker
          testID={`${stringToKey(label)}-dropdown-picker`}
          multiple={multiple}
          open={open}
          value={value as any}
          items={items}
          // itemKey={itemKey}
          setOpen={editable ? setOpen : () => null}
          setValue={setValue}
          onSelectItem={onChange as any}
          setItems={setItems}
          onPress={Keyboard.dismiss}
          onOpen={onFocus}
          onClose={onBlur}
          listMode="SCROLLVIEW"
          scrollViewProps={{
            nestedScrollEnabled: true,
            style: { ...scrollViewStyle },
          }}
          flatListProps={{ keyboardShouldPersistTaps: 'handled', nestedScrollEnabled: true }}
          mode="SIMPLE"
          placeholder={small ? label : ""}
          searchable={isSearchable}
          searchContainerStyle={{
            height: '65px',
            width: '100%',
            borderWidth: 0,
          }}
          searchTextInputStyle={{
            height: '50px',
            width: '100%',
            borderWidth: 0,
            ...Platform.select({
              web: {
                outlineWidth: 0,
              },
            }),
          }}
          searchWithRegionalAccents={true}
          searchPlaceholder="Rechercher..."
          renderListItem={(props) => (
            <ListItem {...props} direction={direction} zIndex={zIndexComputed} />
          )}
          addCustomItem={customItemAvailable}
          ArrowUpIconComponent={() => (
            <Icon
              type={Icons.Ionicons}
              name="caret-up"
              color={borderColor}
              size={theme.spacingUnit * 1.5}
            />
          )}
          ArrowDownIconComponent={() => (
            <Icon
              type={Icons.Ionicons}
              name="caret-down"
              color={textColor}
              size={theme.spacingUnit * 1.5}
            />
          )}
          // renderBadgeItem={(props) => <Badge {...props} />}
          extendableBadgeContainer
          style={[containerStyle]}
          textStyle={textStyle}
          labelStyle={textInputStyle}
          dropDownContainerStyle={[dropdownContainerStyle]}
          tickIconStyle={tickIconStyle as any}
          arrowIconContainerStyle={arrowIconStyle}
          badgeColors={[theme.colors['color-primary']]}
          badgeDotColors={[theme.colors['color-primary']]}
          translation={{
            NOTHING_TO_SHOW: 'Aucune option disponible',
            SELECTED_ITEMS_COUNT_TEXT: '{count} sélectionné(s)',
          }}
          bottomOffset={100}
          onDirectionChanged={(newDirection) => {
            setDirection(newDirection)
          }}
          zIndex={zIndexComputed}
          dropDownDirection={dropDownDirection || 'BOTTOM'}
        //
        // dropDownDirection={'BOTTOM'}
        //
        />
      </StyledDropdownWrapper>
    </>
  )
}

Dropdown.defaultProps = {
  itemKey: 'value',
  isSearchable: false,
  customItemAvailable: false,
  multiple: false,
  editable: true,
}

export default Dropdown
