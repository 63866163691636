import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useCallback, useEffect, useState } from 'react'
import { UserEnums } from '../../../../enums'
import { Button, PageTitle, SectionTitle, Spacer, TextInput } from '../../../components'
import { AuthenticationStackParamList } from '../../../navigation/AuthenticationNavigationStack/AuthenticationNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { TextInputWrapper } from './ProfileSwitcher.styles'
import { Group, Pagination, UserCarrier, UserClient, UserProducer } from '../../../domain'
import Loader from '../../../components/Loader'
import AuthenticationService from '../../../services/authentication'
import GeneralUtils from '../../../utilities/utils/general'
import { useTheme } from 'styled-components/native'
import StorageUtil from '../../../utilities/storage/storage'
import ProfileCard from './Components/ProfileCard'
import CardListPaginated from '../../../components/CardListPaginated'
import FormatUtils from '../../../utilities/utils/format'

export type ProfilesResponse = {
  userCarriers: UserCarrier[]
  userProducers: UserProducer[]
  userClients: UserClient[]
}
function ProfileSwitcherScreen() {
  const {
    authenticationData,
    changeViewType,
    logout,
    isUserAuthenticated,
    getStoredViewType,
    getAccessInfos,
  } = useAuthenticationContext()
  const navigation = useNavigation<StackNavigationProp<AuthenticationStackParamList>>()
  const [isLoading, setIsloading] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const [pageCarrierProfiles, setPageCarrierProfiles] = useState(1)
  const [pageSizeCarrierProfiles, setPageSizeCarrierProfiles] = useState(2)
  const [pageProducerProfiles, setPageProducerProfiles] = useState(1)
  const [pageSizeProducerProfiles, setPageSizeProducerProfiles] = useState(3)
  const [pageClientProfiles, setPageClientProfiles] = useState(1)
  const [pageSizeClientProfiles, setPageSizeClientProfiles] = useState(3)
  const queryClient = useQueryClient()

  const {
    data: carrierProfiles,
    refetch: refetchCarrierProfiles,
    isInitialLoading: carrierProfilesLoading,
  } = useQuery<{ data: UserCarrier[]; pagination: Pagination }, Error>(
    ['u_sf_profiles', getAccessInfos()?.userId],
    () =>
      AuthenticationService.getFilteredUserProfiles(
        pageSizeCarrierProfiles,
        pageCarrierProfiles,
        UserEnums.ViewTypeEnums.CARRIER,
        searchValue,
      ),
    {
      keepPreviousData: true,
      staleTime: 600000, // 10min
    },
  )

  const {
    data: producerProfiles,
    refetch: refetchProducerProfiles,
    isInitialLoading: producerProfilesLoading,
  } = useQuery<{ data: UserProducer[]; pagination: Pagination }, Error>(
    ['u_p_profiles', getAccessInfos()?.userId],
    () =>
      AuthenticationService.getFilteredUserProfiles(
        pageSizeProducerProfiles,
        pageProducerProfiles,
        UserEnums.ViewTypeEnums.PRODUCER,
        searchValue,
      ),
    {
      staleTime: 600000, // 10min
      keepPreviousData: true,
    },
  )

  const {
    data: clientProfiles,
    refetch: refetchClientProfiles,
    isInitialLoading: clientProfilesLoading,
  } = useQuery<{ data: UserClient[]; pagination: Pagination }, Error>(
    ['u_c_profiles', getAccessInfos()?.userId],
    () =>
      AuthenticationService.getFilteredUserProfiles(
        pageSizeClientProfiles,
        pageClientProfiles,
        UserEnums.ViewTypeEnums.CLIENT,
        searchValue,
      ),
    {
      staleTime: 600000, // 10min
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    if (!searchValue || searchValue.length > 2) {
      refetchCarrierProfiles()
      refetchProducerProfiles()
      refetchClientProfiles()
    }
  }, [searchValue])

  useEffect(() => {
    getStoredViewType()
    if (!isUserAuthenticated) {
      navigation.navigate('Login', {})
      return
    }
    //this condition checks if the user only has access to a single profile
    // if so, it will automatically redirect to that profile
    if (
      !searchValue &&
      (carrierProfiles || producerProfiles || clientProfiles) &&
      !carrierProfilesLoading &&
      !producerProfilesLoading &&
      !clientProfilesLoading
    ) {
      let availableViewTypes = computeAvailableViewTypes(UserEnums.ViewTypeEnums.CARRIER)
      availableViewTypes = [
        ...availableViewTypes,
        ...computeAvailableViewTypes(UserEnums.ViewTypeEnums.PRODUCER),
      ]
      availableViewTypes = [
        ...availableViewTypes,
        ...computeAvailableViewTypes(UserEnums.ViewTypeEnums.CLIENT),
      ]

      if (availableViewTypes.length === 1 && !searchValue) {
        if ((availableViewTypes[0].groups || []).length < 2) {
          onSelectProfileType(availableViewTypes[0].viewType, availableViewTypes[0].profileId)
        }
      }
    }
    setIsloading(false)
  }, [isUserAuthenticated, carrierProfiles, producerProfiles, clientProfiles])

  const computeAvailableViewTypes = useCallback(
    (
      viewType: UserEnums.ViewTypeEnums,
    ): {
      role: UserEnums.RoleEnums
      viewType: UserEnums.ViewTypeEnums
      label: string
      profileId: string
      groups?: Group[]
    }[] => {
      if (!authenticationData?.access) return []

      if (viewType === UserEnums.ViewTypeEnums.CARRIER) {
        const userCarriers = carrierProfiles?.data || []

        return userCarriers.map((userCarrier) => {
          const groups: Group[] = userCarrier.carrier?.carrierGroups?.map((cg) => cg.group) || []
          return {
            role: UserEnums.RoleEnums.CARRIER,
            viewType: UserEnums.ViewTypeEnums.CARRIER,
            label: userCarrier.carrier?.name || '',
            profileId: userCarrier.carrier?.id || '',
            groups: groups,
          }
        })
      } else if (viewType === UserEnums.ViewTypeEnums.PRODUCER) {
        const userProducers = producerProfiles?.data || []

        return userProducers.map((userProducer) => {
          return {
            role: UserEnums.RoleEnums.PRODUCER,
            viewType: UserEnums.ViewTypeEnums.PRODUCER,
            label: userProducer.producer?.label || '',
            profileId: userProducer.producer?.id || '',
          }
        })
      } else if (viewType === UserEnums.ViewTypeEnums.CLIENT) {
        const userClients = clientProfiles?.data || []

        return userClients.map((userClient) => {
          return {
            role: UserEnums.RoleEnums.CLIENT,
            viewType: UserEnums.ViewTypeEnums.CLIENT,
            label: userClient.client?.label || '',
            profileId: userClient.client?.id || '',
          }
        })
      }
      return []
    },
    [authenticationData, carrierProfiles, producerProfiles, clientProfiles, searchValue],
  )

  const carrierViewTypes = computeAvailableViewTypes(UserEnums.ViewTypeEnums.CARRIER)
  const producerViewTypes = computeAvailableViewTypes(UserEnums.ViewTypeEnums.PRODUCER)
  const clientViewTypes = computeAvailableViewTypes(UserEnums.ViewTypeEnums.CLIENT)

  const onSelectProfileType = async (
    type: UserEnums.ViewTypeEnums,
    profileId?: string,
    groupId?: string,
  ) => {
    await StorageUtil.deleteItem('c_cart_id')
    await StorageUtil.deleteItem('sf_cart_id')
    await StorageUtil.deleteItem('p_cart_id')

    await queryClient.cancelQueries()
    await queryClient.clear()
    await queryClient.resetQueries()

    changeViewType(type, profileId, groupId)
  }

  function getSectionTitleLabel(
    viewType: UserEnums.ViewTypeEnums,
    count: number,
  ): string | undefined {
    let label = `${FormatUtils.pluralizeAmountLabel('Compte', count, true)}`

    if (viewType === UserEnums.ViewTypeEnums.CARRIER) {
      label += ` ${FormatUtils.pluralizeAmountLabel('producteur-livreur', count, true)} (${
        carrierProfiles?.pagination.totalCount
      })`
    }
    if (viewType === UserEnums.ViewTypeEnums.PRODUCER) {
      label += ` ${FormatUtils.pluralizeAmountLabel('producteur', count, true)} (${
        producerProfiles?.pagination.totalCount
      })`
    }
    if (viewType === UserEnums.ViewTypeEnums.CLIENT) {
      label += ` ${FormatUtils.pluralizeAmountLabel('client', count, true)} (${
        clientProfiles?.pagination.totalCount
      })`
    }
    return label
  }

  const onChangePageCarrierProfiles = async (pageNumber: number) => {
    await setPageCarrierProfiles(pageNumber)
    await refetchCarrierProfiles()
  }

  const onChangePageProducerProfiles = async (pageNumber: number) => {
    await setPageProducerProfiles(pageNumber)
    await refetchProducerProfiles()
  }

  const onChangePageClientProfiles = async (pageNumber: number) => {
    await setPageClientProfiles(pageNumber)
    await refetchClientProfiles()
  }

  const onChangeSearchValue = async (newSearchValue: string) => {
    setSearchValue(newSearchValue)
    setPageCarrierProfiles(1)
    setPageClientProfiles(1)
    setPageProducerProfiles(1)
  }

  if (isLoading || carrierProfilesLoading || producerProfilesLoading || clientProfilesLoading) {
    return <Loader isLoading pageLoading paddingLeft={false} />
  }

  return (
    <ScreenSafeAreaWrapper noPaddingLeft>
      <ContentWrapper alignCenter noPaddingLeft>
        <PageTitle
          title="Vos comptes"
          subtitle="Choisissez un des comptes auquel vous souhaitez avoir accès"
          buttonRight={
            <Button label="Se déconnecter" colorName="color-grey" onPress={() => logout()} small />
          }
        />
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <TextInputWrapper>
          <TextInput
            field="text"
            label="Rechercher par nom"
            value={searchValue}
            onChangeText={onChangeSearchValue}
            small
          />
        </TextInputWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {carrierViewTypes.length > 0 && (
          <>
            <SectionTitle
              title={getSectionTitleLabel(UserEnums.ViewTypeEnums.CARRIER, carrierViewTypes.length)}
            />
            <CardListPaginated
              data={carrierViewTypes}
              pagination={carrierProfiles?.pagination}
              element={(viewType: any) => (
                <ProfileCard
                  profile={viewType}
                  onPress={onSelectProfileType}
                  key={viewType.profileId}
                />
              )}
              isLoading={carrierProfilesLoading}
              emptyMessage="Aucun compte de ce type"
              onChangePage={onChangePageCarrierProfiles}
            />
          </>
        )}

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {producerViewTypes.length > 0 && (
          <>
            <SectionTitle
              title={getSectionTitleLabel(
                UserEnums.ViewTypeEnums.PRODUCER,
                producerViewTypes.length,
              )}
            />
            <CardListPaginated
              data={producerViewTypes}
              pagination={producerProfiles?.pagination}
              element={(viewType: any) => (
                <ProfileCard
                  profile={viewType}
                  onPress={onSelectProfileType}
                  key={viewType.profileId}
                />
              )}
              isLoading={producerProfilesLoading}
              emptyMessage="Aucun compte de ce type"
              onChangePage={onChangePageProducerProfiles}
            />
          </>
        )}

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {clientViewTypes.length > 0 && (
          <>
            <SectionTitle
              title={getSectionTitleLabel(UserEnums.ViewTypeEnums.CLIENT, producerViewTypes.length)}
            />
            <CardListPaginated
              data={clientViewTypes}
              pagination={clientProfiles?.pagination}
              element={(viewType: any) => (
                <ProfileCard
                  profile={viewType}
                  onPress={onSelectProfileType}
                  key={viewType.profileId}
                />
              )}
              isLoading={clientProfilesLoading}
              emptyMessage="Aucun compte de ce type"
              onChangePage={onChangePageClientProfiles}
            />
          </>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProfileSwitcherScreen
