import { View } from 'react-native'
import Typography from './Typography'

export interface TitleWithTagProps {
  children: React.ReactNode
  tag?: string
}

export default function TitleWithTag({ children, tag }: TitleWithTagProps) {
  return (
    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {children}
      {tag && (
        <>
          {' '}
          <Typography.BodySmall colorName="text-dark-3">{' (#' + tag + ')'}</Typography.BodySmall>
        </>
      )}
    </View>
  )
}
