import { ScrollView } from 'react-native-gesture-handler'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../utilities/styling/wrappers'
import {
  RegistrationLandingPageWrapper,
  StyledImageLogo,
  StyledLogoBox,
  TextWrapper,
  ButtonWrapper,
  BottomPartWrapper,
} from './RegisterTutorial.styles'
import { Button, Spacer, Typography } from '../../../../components'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { AuthenticationStackParamList } from '../../../../navigation/AuthenticationNavigationStack/AuthenticationNavigationStack.model'

import { StackNavigationProp } from '@react-navigation/stack'
import { useState } from 'react'
import { ImageSourcePropType, Linking } from 'react-native'
import useAuthenticationContext from '../../../../utilities/hook/useAuthenticationContext'

export type TutorialStep = {
  id: number
  imageSource: ImageSourcePropType
  label: string
  description: string
  isFinal?: boolean
}

const ProducerSteps: TutorialStep[] = [
  {
    id: 1,
    imageSource: require('../../../../../assets/drawings/agri_3.png'),
    label: 'Créez votre compte producteur',
    description: 'Complétez votre profil et ajoutez les produits à votre catalogue',
  },
  {
    id: 2,
    imageSource: require('../../../../../assets/drawings/camion.png'),
    label: 'Proposez ou Rejoignez une tournée',
    description: 'Participez à des tournées de livraison existantes ou proposez la vôtre',
  },
  {
    id: 3,
    imageSource: require('../../../../../assets/drawings/manager_1.png'),
    label: 'Vendez ensemble et livrez ensemble',
    description:
      'Vos clients professionnels passent commande à tous les producteurs du groupe en même temps. Harvy vous aide dans votre organisation logistique, afin de tout livrer en une seule fois',
    isFinal: true,
  },
]

const RegisterTutorialScreen = () => {
  const route = useRoute<RouteProp<AuthenticationStackParamList, 'RegisterTutorial'>>()
  const navigation = useNavigation<StackNavigationProp<AuthenticationStackParamList>>()
  const { type, mail, password } = route?.params ?? { type: '' }
  const { login, setAuthenticationContextLoading } = useAuthenticationContext()

  const [tutorial, setTutorial] = useState<TutorialStep[] | undefined>(
    type === 'PRODUCER' ? ProducerSteps : undefined,
  )
  const [currentStep, setCurrentStep] = useState<TutorialStep | undefined>(
    type === 'PRODUCER' ? ProducerSteps[0] : undefined,
  )

  const onRegisterClick = async () => {
    if (mail && password) {
      await setAuthenticationContextLoading(true)
      await login(mail, password)
      navigation.navigate('ProfileSwitcher', {})
    } else {
      navigation.navigate('Login', {})
    }
  }

  const nextStep = () => {
    if (!tutorial) return
    const currentStepIndex = tutorial.findIndex((s) => s.id === currentStep?.id)
    if (currentStepIndex < 0) return

    const nextStep = tutorial[currentStepIndex + 1]
    if (nextStep) {
      setCurrentStep(nextStep)
    }
  }

  const renderStep = () => {
    if (!currentStep)
      return (
        <RegistrationLandingPageWrapper>
          <StyledLogoBox>
            <StyledImageLogo
              source={require('../../../../../assets/drawings/manager_1.png')}
              resizeMode="contain"
            />
            <TextWrapper>
              <Typography.Body colorName="color-primary" bold align="center">
                Un problème est survenu
              </Typography.Body>
              <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            </TextWrapper>
          </StyledLogoBox>
          <BottomPartWrapper>
            <ButtonWrapper>
              <Button
                onPress={() => navigation.goBack()}
                label="Retour"
                colorName="color-grey"
                fullWidth
              />
            </ButtonWrapper>
          </BottomPartWrapper>
        </RegistrationLandingPageWrapper>
      )

    return (
      <RegistrationLandingPageWrapper>
        <StyledLogoBox>
          {currentStep.imageSource && (
            <StyledImageLogo source={currentStep.imageSource} resizeMode="contain" />
          )}
          <TextWrapper>
            <Typography.Body colorName="color-primary" bold align="center">
              {currentStep.label}
            </Typography.Body>
            <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.Body colorName="color-primary" align="center">
              {currentStep.description}
            </Typography.Body>
          </TextWrapper>
        </StyledLogoBox>
        <BottomPartWrapper>
          <ButtonWrapper>
            {currentStep.isFinal ? (
              <Button
                onPress={() => onRegisterClick()}
                label="Terminer"
                colorName="color-secondary"
                fullWidth
              />
            ) : (
              <Button
                onPress={() => nextStep()}
                label="Suivant"
                colorName="color-secondary"
                fullWidth
              />
            )}
          </ButtonWrapper>
        </BottomPartWrapper>
      </RegistrationLandingPageWrapper>
    )
  }

  return (
    <ScreenSafeAreaWrapper noPaddingLeft>
      <ContentWrapper alignCenter noHeader noDrawer>
        {renderStep()}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default RegisterTutorialScreen
