import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useState } from 'react'
import { Button, PageTitle, SectionTitle, Spacer, Typography, TextInput } from '../../../components'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import {
  StyledButtonWrapper,
  SummaryLeftBox,
  SummaryRightBox,
  TotalSummaryCard,
  TotalSummaryCardContent,
} from './CarrierBillingProducer.styles'
import { useQuery } from '@tanstack/react-query'
import { StackNavigationProp } from '@react-navigation/stack'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import FormatUtils from '../../../utilities/utils/format'
import { CarrierFeesRecord, CarrierFeesStatsProducer } from '../../../domain/CarrierFees'
import CarrierFeesCarrierService from '../../../services/carrier/carrierFees'
import InfoCard from '../../../components/InfoCard'
import CarrierFeesCard from '../../../components/CarrierFeesCard'
import { queryClient } from '../../../utilities/queryClient'
import { Invoice } from '../../../domain/Invoice'
import CopyPasteAddressCard from '../../../components/CopyPasteAddressCard'
import DeviceUtil from '../../../utilities/utils/device'
import { View } from 'react-native'

const CarrierBillingProducerScreen = () => {
  const { getAccessInfos } = useAuthenticationContext()

  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierBillingProducer'>>()

  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [newCustomIdentifier, setNewCustomIdentifier] = useState<Partial<Invoice>>({
    customIdentifier: '',
  })
  const [isCopied, setIsCopied] = useState(false)

  const producerId = route.params?.producerId
  const carrierId = route.params?.carrierId
  const groupId = getAccessInfos().carrierGroupId

  const {
    data: carrierFees,
    refetch: refetchCarrierFees,
    isInitialLoading: carrierFeesLoading,
    isLoading: isLoadingCarrierFees,
  } = useQuery<CarrierFeesRecord[], Error>(
    ['sf_billing_carrier_fees_records', carrierId, producerId],
    () => CarrierFeesCarrierService.getCarrierFeesRecords(groupId, carrierId, producerId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: carrierFeesStatsProducers,
    refetch: refetchCarrierFeesStatsProducers,
    isInitialLoading: carrierFeesStatsProducersLoading,
    isLoading: isLoadingCarrierFeesStatsProducers,
  } = useQuery<CarrierFeesStatsProducer[], Error>(
    ['sf_billing_carrier_fees_stats_producer', getAccessInfos().carrierGroupId, carrierId],
    () =>
      CarrierFeesCarrierService.getAllGroupProducerStats(
        getAccessInfos().carrierGroupId,
        carrierId,
        undefined,
      ),
    {
      keepPreviousData: true,
    },
  )

  const statProducer = carrierFeesStatsProducers?.find(
    (statProducer) =>
      statProducer.producerId === producerId && statProducer.carrierId === carrierId,
  )
  const totalHT = statProducer?.totalCarrierFeesCharged || 0
  const totalTVA = totalHT * 0.2
  const totalTTC = totalHT + totalTVA

  const setValue = (value: any, param: string) => {
    setErrorMessage('')

    if (!param && !(param in newCustomIdentifier)) return null
    const tempContainer = Object.assign({}, newCustomIdentifier)

    tempContainer[param as keyof typeof newCustomIdentifier] = value
    setNewCustomIdentifier(tempContainer)
  }

  const handleChargeProducer = () => {
    const carrierFeesRecordIds = carrierFees?.map((record) => record.id)
    if (!carrierFeesRecordIds || carrierFeesRecordIds.length < 1) {
      return
    }
    setErrorMessage('')
    CarrierFeesCarrierService.chargeProducer(
      groupId,
      carrierId,
      producerId,
      carrierFeesRecordIds,
      newCustomIdentifier.customIdentifier,
    ).then((response) => {
      setErrorMessage('')
      queryClient.invalidateQueries(['sf_billing_invoices', response.createdInvoice.issuer.id])
      queryClient.invalidateQueries([
        'sf_billing_carrier_fees_stats_producer',
        getAccessInfos().carrierGroupId,
        carrierId,
      ])
      queryClient.invalidateQueries(['sf_billing_carrier_fees', getAccessInfos().carrierGroupId])
      navigation.navigate('CarrierBillingProducers', { carrierId: carrierId })
    })
  }

  const producerInfosCsvContent = `${carrierFees?.[0]?.producer?.label}\n${
    carrierFees?.[0]?.producer?.addressLine1
  }\n${carrierFees?.[0]?.producer?.addressLine2}\n${carrierFees?.[0]?.producer?.postalCode}${' '}${
    carrierFees?.[0]?.producer?.city
  }`

  const handleCopyProducerInfos = (isCopied: boolean) => {
    navigator.clipboard.writeText(producerInfosCsvContent)

    setIsCopied(isCopied)
  }

  const isMobile = DeviceUtil.isMobileApp()

  const firstCarrier = (carrierFees || [])[0]?.carrier || {}

  const carrierIsLogged = () => {
    return firstCarrier?.id === getAccessInfos().carrierId
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title={`Facturer`} />
        <InfoCard leftIcon="link">
          <Typography.BodySmall colorName="text-dark-3">
            {`${firstCarrier.name} facture les frais logistique à`}{' '}
          </Typography.BodySmall>
          <Typography.BodySmall bold>{statProducer?.producerLabel}</Typography.BodySmall>
        </InfoCard>{' '}
        <SectionTitle title={`À facturer`} />
        {carrierFees?.map((record) => (
          <CarrierFeesCard carrierFeesRecord={record} />
        ))}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <TotalSummaryCard isMobile={isMobile}>
          <TotalSummaryCardContent>
            <SummaryLeftBox>
              <Typography.Body>{'Total HT '}</Typography.Body>
              <Typography.Body>{'Total TVA '}</Typography.Body>
              <Typography.Body bold>{'Total TTC '}</Typography.Body>
            </SummaryLeftBox>
            <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={2} />
            <SummaryRightBox>
              <Typography.Body bold>{FormatUtils.formatPrice(totalHT)}</Typography.Body>
              <Typography.Body bold>{FormatUtils.formatPrice(totalTVA)}</Typography.Body>
              <Typography.Body bold>{FormatUtils.formatPrice(totalTTC)}</Typography.Body>
            </SummaryRightBox>
          </TotalSummaryCardContent>
          <CopyPasteAddressCard
            producerAddress={carrierFees?.[0]?.producer}
            onPress={() => handleCopyProducerInfos(isCopied)}
          />
        </TotalSummaryCard>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <Typography.BodyExtraSmall
          colorName="text-dark-3"
          align={isMobile ? 'center' : 'end'}
          style={{ width: '100%' }}
        >
          {"Copier les informations du producteur en cliquant sur l'icône ci dessus."}
        </Typography.BodyExtraSmall>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        {errorMessage ? (
          <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
        ) : null}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        {carrierIsLogged() && (
          <View style={{ width: isMobile ? '100%' : '50%', alignSelf: 'center' }}>
            <TextInput
              label="Code facture"
              value={newCustomIdentifier.customIdentifier}
              field="text"
              onChangeText={(text) => setValue(text, 'customIdentifier')}
            />
          </View>
        )}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <StyledButtonWrapper>
          <Button
            label={'Facturer'}
            onPress={() => handleChargeProducer()}
            disabled={!carrierIsLogged()}
          />
        </StyledButtonWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        {!carrierIsLogged() && (
          <View style={{ width: '100%' }}>
            <Typography.Body colorName="color-warning" align="center">
              Seulement "{firstCarrier.name}" peut facturer ce producteur.
            </Typography.Body>
          </View>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierBillingProducerScreen
