import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import { Dimensions } from 'react-native'
import Header from '../../components/Header'
import BillingCarrierNavigationStackParamList from './BillingCarrierNavigation.model'
import CarrierBillingDeliveryNotesScreen from '../../../screens/Carrier/CarrierBillingDeliveryNotes'
import CarrierBillingScreen from '../../../screens/Carrier/CarrierBilling'
import HeaderHome from '../../components/HeaderHome'
import CarrierDeliveryFeesScreen from '../../../screens/Carrier/CarrierDeliveryFees'
import CarrierBillingProducersScreen from '../../../screens/Carrier/CarrierBillingProducers'
import CarrierBillingProducerScreen from '../../../screens/Carrier/CarrierBillingProducer'
import CarrierBillingPaymentScreen from '../../../screens/Carrier/CarrierBillingPayment'
import CarrierConcludeToursScreen from '../../../screens/Carrier/CarrierConcludeTours'

const Stack = createStackNavigator<BillingCarrierNavigationStackParamList>()

const BillingCarrierNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="CarrierBilling"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="CarrierBilling"
        component={CarrierBillingScreen}
        options={{
          headerShown: true,
          header: () => <HeaderHome />,
          title: 'Reporting Prix',
        }}
      />
      <Stack.Screen
        name="CarrierBillingDeliveryNotes"
        component={CarrierBillingDeliveryNotesScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierBilling" />,
          title: 'Ajouter un relevé de prix',
        }}
      />

      <Stack.Screen
        name="CarrierDeliveryFees"
        component={CarrierDeliveryFeesScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierBilling" />,
          title: 'Gérer les frais de livraison',
        }}
      />
      <Stack.Screen
        name="CarrierBillingProducers"
        component={CarrierBillingProducersScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierBilling" />,
          title: 'Facturer les producteurs',
        }}
      />
      <Stack.Screen
        name="CarrierBillingProducer"
        component={CarrierBillingProducerScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierBilling" />,
          title: 'Facturer un producteur',
        }}
      />
      <Stack.Screen
        name="CarrierBillingPayment"
        component={CarrierBillingPaymentScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierBilling" />,
          title: 'Paiement',
        }}
      />
      <Stack.Screen
        name="CarrierConcludeTours"
        component={CarrierConcludeToursScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: false,
          headerShown: true,
          header: () => <Header title="" goBackFallback="CarrierTours" />,
          title: 'Tournées à clôturer',
        }}
      />
    </Stack.Navigator>
  )
}

export default BillingCarrierNavigation
