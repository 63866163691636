import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useQuery } from '@tanstack/react-query'
import { Button, SectionTitle, Spacer } from '../../../components'
import CardList from '../../../components/CardList'
import PageTitle from '../../../components/PageTitle'
import { Group, Pagination, PriceReporting, Tour } from '../../../domain'
import TourCard from '../../../modules/Tour/TourCard'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import TourCarrierService from '../../../services/carrier/tour'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import HelpFooter from '../../../components/HelpFooter'
import PriceReportingCarrierService from '../../../services/carrier/priceReporting'
import FormatUtils from '../../../utilities/utils/format'
import ActionCard from '../../../modules/Common/ActionCard'
import GroupCarrierService from '../../../services/carrier/group'
import { ArrivalSectionWrapper } from './CarrierDashboard.styles'
import AuthenticationService from '../../../services/authentication'
import { GroupTutorialSteps, TutorialStepData } from '../../../../enums/tutorialStep'
import { GroupEnums } from '../../../../enums/group'
import TutorialStepCards from '../../../components/TutorialStepCards'
import DashboardStats from '../../../components/DashboardStats'
import MetricsCarrierService, { CarrierDashboardMetrics } from '../../../services/carrier/metrics'
import { formatTourFillingInfo } from '../../../utilities/selector/formatTourFillingInfo'
import { RightKeyEnum } from '../../../../enums'

const currentMonth = new Date().getMonth()
const currentYear = new Date().getFullYear()
const defaultDateHour = new Date(currentYear, currentMonth, 1, 0, 0, 0)
const defaultDateHour2 = new Date(currentYear, currentMonth + 1, 1, 0, 0, 0)

const CarrierDashboardScreen = () => {
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const [metricsFilters, setMetricsFilters] = useState({
    start: new Date(defaultDateHour),
    end: new Date(defaultDateHour2),
  })

  const { getAccessInfos, carrierHasRight } = useAuthenticationContext()

  const {
    data: groupData,
    refetch,
    isInitialLoading: feedLoading,
  } = useQuery<{ group: Group; stepsData: TutorialStepData[] }, Error>(
    ['sf_group', getAccessInfos().carrierGroupId],
    () =>
      GroupCarrierService.getOneGroup(getAccessInfos().carrierGroupId, getAccessInfos().carrierId),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: nextTours,
    refetch: refetchNextTours,
    isInitialLoading: nextToursLoading,
  } = useQuery<{ data: Tour[]; pagination: Pagination }, Error>(
    ['sf_next_tours_dashboard'],
    () =>
      TourCarrierService.getAllTours(
        getAccessInfos().carrierId,
        true,
        undefined,
        100,
        undefined,
        true,
      ),
    {
      keepPreviousData: true,
      select: (data) => {
        data.data = data.data.map(formatTourFillingInfo)
        return data
      },
    },
  )

  const dateFrom = new Date()

  const {
    data: priceReportings,
    refetch: refetchPriceReportings,
    isInitialLoading: isPriceReportingsLoading,
  } = useQuery<{ data: PriceReporting[]; pagination: Pagination }, Error>(
    ['sf_price_reportings_dashboard'],
    () =>
      PriceReportingCarrierService.getAll(
        getAccessInfos().carrierGroupId,
        dateFrom,
        undefined,
        20,
        1,
        true,
      ),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: metrics,
    refetch: refetchMetrics,
    isInitialLoading: metricsLoading,
  } = useQuery<CarrierDashboardMetrics, Error>(
    ['group_metrics_dashboard', getAccessInfos().carrierGroupId],
    () =>
      MetricsCarrierService.getDashboardMetrics(
        getAccessInfos().carrierGroupId,
        metricsFilters.start,
        metricsFilters.end,
      ),
    {
      keepPreviousData: true,
    },
  )

  const OnClickTour = (tour: Tour) => {
    navigation.navigate('CarrierTours', {
      screen: 'CarrierTour',
      params: { id: tour.id },
    })
  }

  const onClickReception = () => {
    const params = {
      mode: 'DAY',
      start: new Date(),
    }
    navigation.navigate('CarrierTours', {
      screen: 'CarrierReceptionPreparation',
      params: params,
    })
  }

  const onClickActionPriceReporting = (priceReporting: PriceReporting) => {
    if (!priceReporting || !priceReporting.id) return
    navigation.navigate('PriceReportingsCarrierNavigationConfiguration', {
      screen: 'CarrierPriceReporting',
      params: { id: priceReporting.id },
    })
  }

  const onClickPriceReportings = () => {
    navigation.navigate('PriceReportingsCarrierNavigationConfiguration', {
      screen: 'CarrierPriceReportings',
      params: {},
    })
  }

  const getUrgentActions = (): { title: string; description?: string; onClick: () => void }[] => {
    let tempActions: { title: string; description?: string; onClick: () => void }[] = []
    let tempActionsPricing: { title: string; description?: string; onClick: () => void }[] = []

    if (priceReportings?.data && priceReportings.data.length > 0) {
      priceReportings.data.map((pr) => {
        if (pr.nbProductTypesWithoutStatement && pr.nbProductTypesWithoutStatement > 0) {
          let citiesList = ''
          pr.priceReportingCities?.map((c, index) => {
            citiesList = citiesList + `${index === 0 ? '' : ', '}${c.city.label}`
          })

          tempActionsPricing.push({
            title: 'Prix conseillé manquant',
            description: `${
              pr.nbProductTypesWithoutStatement
            } types de produit sans relevé de prix pour ${citiesList} du ${FormatUtils.formatDate(
              pr.validityStart,
              'Date',
            )} au ${FormatUtils.formatDate(pr.validityEnd, 'Date')}`,
            onClick: () => onClickActionPriceReporting(pr),
          })
        }
      })
    }

    if (nextTours?.data && nextTours.data.length > 0) {
      nextTours.data.map((tour) => {
        if (!tour.priceReportings || tour.priceReportings.length < 1) {
          tempActionsPricing.push({
            title: 'Relevé de prix manquant',
            description: `Aucun relevé de prix pour la tournée du ${FormatUtils.formatDate(
              tour.start,
              'Date',
            )} à ${tour.city?.label}`,
            onClick: () => onClickPriceReportings(),
          })
        }
      })
    }

    if (groupData?.group?.hasPricingModule) {
      tempActions = tempActionsPricing
    }

    if (
      groupData?.group?.groupRequestsPending &&
      groupData?.group?.groupRequestsPending.length > 0
    ) {
      tempActions.push({
        title: 'Nouvelle demande producteur',
        description: `Vous avez ${groupData?.group?.groupRequestsPending.length} demandes de nouveaux producteurs souhaitant rejoindre le groupe.`,
        onClick: () => navigation.navigate('CarrierGroup', {}),
      })
    }

    return tempActions
  }

  useEffect(() => {
    refetchMetrics()
  }, [metricsFilters])

  const onClickMetricsChangeDate = async (metricsFilters: { start: Date; end: Date }) => {
    let currentMonthNew = metricsFilters.start.getMonth()
    let currentYear = metricsFilters.start.getFullYear()

    if (currentMonthNew < 0) {
      currentMonthNew = 11
      currentYear = currentYear - 1
    }

    if (currentMonthNew > 11) {
      currentMonthNew = 0
      currentYear = currentYear + 1
    }

    const newStartDate = new Date(currentYear, currentMonthNew, 1, 0, 0, 0)

    const newEndDate = new Date(currentYear, currentMonthNew + 1, 1, 0, 0, 0)

    const tempMetricsFilter = { start: newStartDate, end: newEndDate }

    await setMetricsFilters(tempMetricsFilter)
  }

  const selectedMetrics = [
    {
      label: 'Ventes Client HT',
      value: metrics?.totalHT ? `${FormatUtils.formatPrice(metrics?.totalHT)}` : '-',
    },
    { label: 'Commandes', value: metrics?.nbOrders ? metrics?.nbOrders.toString() : '-' },
    { label: 'Tournées', value: metrics?.nbTours ? metrics?.nbTours.toString() : '-' },
  ]

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Accueil" />
        {carrierHasRight(RightKeyEnum.C_DASHBOARD_STATS) && (
          <>
            <DashboardStats
              metrics={selectedMetrics}
              metricsFilters={metricsFilters}
              metricsType="MONTH"
              onChangeDates={onClickMetricsChangeDate}
              isLoading={metricsLoading}
            />
          </>
        )}
        {carrierHasRight(RightKeyEnum.C_DASHBOARD_URGENT_ACTIONS) &&
          (getUrgentActions() && getUrgentActions().length > 0 ? (
            <>
              <SectionTitle title="Actions urgentes" />
              {getUrgentActions().map((action, index) => (
                <ActionCard
                  title={action.title}
                  description={action.description || ''}
                  onClick={action.onClick}
                  key={index}
                />
              ))}
            </>
          ) : null)}

        {groupData?.group?.status === GroupEnums.GroupStatusEnum.ONBOARDING &&
          groupData?.stepsData?.length > 0 && (
            <>
              <SectionTitle title="Finalisez votre inscription" />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <TutorialStepCards
                tutorialStepsData={groupData?.stepsData}
                tutorialSteps={GroupTutorialSteps}
                navigationType="CARRIER"
              />
            </>
          )}

        <SectionTitle
          title={`Prochaines tournées (${nextTours?.data ? nextTours?.data.length : null})`}
        />
        <CardList
          data={nextTours?.data}
          element={function (tour: any) {
            return (
              <TourCard
                key={tour.id}
                tour={tour}
                onClick={OnClickTour}
                isCarrier
                fromScreen="CarrierDashboard"
              />
            )
          }}
          isLoading={nextToursLoading}
          emptyMessage="Aucune tournée à venir"
        />
        <SectionTitle title={`Réception des commandes`} />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <ArrivalSectionWrapper>
          <Button
            onPress={onClickReception}
            label="Accéder à la page de réception des commandes"
            colorName="color-grey"
            small
          />
          <HelpFooter />
        </ArrivalSectionWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierDashboardScreen
