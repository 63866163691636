import { Typography, Card, Button, Row, Col, SectionTitle, Spacer } from '../../../../../components'
import { Producer } from '../../../../../domain'
import BatchCatalog from '../../../../../modules/Batch/BatchCatalog'
import DirectusUtil from '../../../../../utilities/utils/directus'
import { CatalogProducerBatchsProps } from './CatalogProducerBatchs.model'
import {
  BoxWrapper,
  LeftBox,
  ProducerBox,
  RightBox,
  StyledProducerImage,
} from './CatalogProducerBatchs.styles'

const CatalogProducerBatchs = ({
  cart,
  producer,
  batchs,
  clientLabels,
  group,
}: CatalogProducerBatchsProps) => {
  return (
    <BoxWrapper>
      <ProducerBox>
        <LeftBox>
          <StyledProducerImage
            source={{
              uri: DirectusUtil.getProducerImageUrlFromId(producer as any as Producer),
            }}
            resizeMode="cover"
          />
        </LeftBox>
        <RightBox>
          <Typography.CardTitle>{producer.label}</Typography.CardTitle>
          <Typography.BodySmall bold colorName="text-dark-3">
            {producer.owners} - {producer.location}
          </Typography.BodySmall>
          <Typography.BodySmall colorName="text-dark-3">
            {producer.description}
          </Typography.BodySmall>
        </RightBox>
      </ProducerBox>
      <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
      <Row>
        {batchs.map((batch) => (
          <Col xs={12} sm={6} md={4} lg={3} smAlignItems="center" xsAlignItems="center">
            <BatchCatalog
              key={batch.id}
              cart={cart}
              batch={batch}
              clientLabels={clientLabels}
              group={group}
            />
          </Col>
        ))}
      </Row>
    </BoxWrapper>
  )
}

export default CatalogProducerBatchs
