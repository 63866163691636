import React, { createElement, useEffect, useRef } from 'react'
import { TextInput } from 'react-native-gesture-handler'
import DeviceUtil from '../../utilities/utils/device'
import Typography from '../Typography'
import { DropdownPropTypes } from './DatePicker.model'
import { StyledPickerWrapper, StyledTextInput, StyledWeekDayBox } from './DatePicker.styles'
import DatePickerMobile from './DatePickerMobile'
import { useTheme } from 'styled-components/native'
import TextInputLabel from '../inputs/TextInputLabel'
import FormatUtils from '../../utilities/utils/format'
import { StyleSheet, Platform } from 'react-native'
import { format } from 'date-fns'

const styles = StyleSheet.create({
  textInput: {
    flex: 1,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
})

function DatePicker({
  id,
  label,
  onChange,
  value,
  required,
  hasError,
  onOpen,
  mode,
  outputFormat,
  hidden,
  minDate,
  maxDate,
  withDay,
}: DropdownPropTypes) {
  const theme = useTheme()

  const isMobileDevice = DeviceUtil.isMobileDevice()

  if (hidden) return null

  if (!isMobileDevice) {
    const inputRef = useRef<TextInput>(null)

    const onChangeDate = (event: any) => {
      const targetValue = event.target.value

      if (targetValue) {
        if (mode === 'date') {
          if (typeof value === 'object') {
            const oldDate = new Date(value)
            const tempResult = new Date(targetValue)
            tempResult.setHours(oldDate.getHours())
            tempResult.setMinutes(oldDate.getMinutes())
            onChange(tempResult)
          } else {
            onChange(new Date(targetValue))
          }
        }
        if (mode === 'time' && typeof value === 'object') {
          const tempResult = new Date(value)
          tempResult.setHours(targetValue.split(':')[0])
          tempResult.setMinutes(targetValue.split(':')[1])

          onChange(tempResult)
        }
      }
    }

    let dateString = value ? new Date().toISOString() : null
    if (value) {
      if (value instanceof Date) {
        dateString = value.toISOString()
      }
      if (typeof value === 'string') {
        dateString = value
      }
    }

    const defaultDate = dateString
      ? FormatUtils.formatDate(dateString, mode === 'time' ? 'InputTime' : 'InputDate')
      : null

    useEffect(() => {
      if (inputRef?.current) {
        inputRef?.current?.setNativeProps({
          type: mode,
          min: minDate ? format(minDate, 'yyyy-MM-dd') : undefined,
          max: maxDate ? format(maxDate, 'yyyy-MM-dd') : undefined,
          pattern: 'd{4}-d{2}-d{2}',
          value: defaultDate,
        })
      }
    }, [inputRef?.current, defaultDate])

    let inputMode = 'date'
    if (mode === 'time') {
      inputMode = 'time'
    }

    const getTextWidth = (text: string) => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      if (context) {
        let width = context.measureText(text).width
        if (width < 25) {
          width = width * 1.1
        }
        return width * 1.3
      }
      return text.length * 0
    }

    const paddingLeftDay =
      defaultDate && withDay
        ? theme.spacingUnit * 2.7 + getTextWidth(FormatUtils.formatDate(defaultDate, 'WeekDay'))
        : 0

    return (
      <StyledPickerWrapper backgroundColor={theme.colors['color-input']}>
        <TextInputLabel editable label={label} isActive={true} required={required} />

        <StyledTextInput
          style={{ ...styles.textInput }}
          ref={inputRef}
          onChange={onChangeDate}
          paddingLeftValue={paddingLeftDay}
        />
        {defaultDate && withDay && (
          <StyledWeekDayBox>
            <Typography.Body>
              {FormatUtils.capitalize(FormatUtils.formatDate(defaultDate, 'WeekDay'))}
            </Typography.Body>
          </StyledWeekDayBox>
        )}
      </StyledPickerWrapper>
    )
  }

  return (
    <DatePickerMobile
      label={label}
      onChange={onChange}
      value={value}
      required={required}
      hasError={hasError}
      onOpen={onOpen}
      mode={mode}
      outputFormat={outputFormat}
    />
  )
}

export default DatePicker
