import React, { useCallback, useMemo, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import { useTheme } from 'styled-components/native'
import { stringToKey } from '../../utilities/helpers'
import Spacer from '../Spacer'
import Text from '../Text'
import { ButtonProps } from './Button.model'
import {
  StyledTouchableOpacity,
  DoubleValidationWrapper,
  ButtonsValidationWrapper,
} from './Button.styles'
import ButtonIcon from './ButtonIcon'
import ButtonText from './ButtonText'
import { InterfaceColors } from '../../utilities/styling/theme/theme'
import Typography from '../Typography'

const Button = ({
  children,
  label,
  onPress,
  disabled,
  fullWidth,
  style,
  loading,
  outlined,
  danger,
  small,
  colorName,
  lowPadding,
  rounded,
  hasDoubleValidation,
  confirmationLabel,
}: ButtonProps) => {
  const theme = useTheme()
  const accessibilityLabel = label ? `button-${stringToKey(label)}` : 'button'
  const [showDoubleValidation, setShowDoubleValidation] = useState(false)

  const isButtonDisabled = useMemo(() => {
    return disabled || loading
  }, [disabled, loading])

  const textColorName = useMemo((): keyof InterfaceColors => {
    if (!isButtonDisabled && outlined && danger) {
      return 'color-danger'
    }

    if (!outlined && colorName && colorName === 'color-grey') {
      return 'text-light-1'
    }

    if (!outlined && colorName && colorName === 'color-primary') {
      return 'text-light-1'
    }

    if (!outlined && colorName && colorName === 'color-primary-2') {
      return 'text-light-1'
    }

    if (!isButtonDisabled && outlined) {
      return 'text-dark-3'
    }

    if (danger || colorName === 'color-danger') {
      return 'text-light-1'
    }

    return 'text-dark-1'
  }, [isButtonDisabled, outlined, danger, colorName])

  const opacity = useMemo((): number => {
    if (isButtonDisabled && !outlined) {
      return 0.4
    }
    return 1
  }, [isButtonDisabled, outlined, danger])

  const onPressButton = () => {
    if (!loading) {
      if (hasDoubleValidation) {
        setShowDoubleValidation(true)
      } else {
        onPress()
      }
    }
  }

  const onConfirm = () => {
    setShowDoubleValidation(false)
    onPress()
  }

  const onReject = () => {
    setShowDoubleValidation(false)
  }

  const renderLoading = useCallback(() => {
    const color = theme.colors[textColorName]
    return loading ? (
      <>
        <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={2} />
        <ActivityIndicator size="small" color={color} />
      </>
    ) : null
  }, [loading, textColorName, theme.colors])

  const renderDoubleValidationButtons = () => {
    return (
      <DoubleValidationWrapper>
        <Typography.BodyExtraSmall colorName="text-dark-1" align="center">
          {confirmationLabel}
        </Typography.BodyExtraSmall>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
        <ButtonsValidationWrapper>
          <StyledTouchableOpacity
            activeOpacity={0.8}
            onPress={onConfirm}
            small={small}
            style={{ width: '50%' }}
            lowPadding={lowPadding}
          >
            <Text
              tag={Text.TextTag.BUTTON_LABEL}
              colorName="color-primary"
              align="center"
              style={small ? { fontSize: `${theme.fontSizes.BUTTON_LABEL * 0.8}px` } : {}}
            >
              Oui
            </Text>
          </StyledTouchableOpacity>
          <StyledTouchableOpacity
            activeOpacity={0.8}
            onPress={onReject}
            small={small}
            colorName="color-danger"
            style={{ width: '50%' }}
            lowPadding={lowPadding}
          >
            <Text
              tag={Text.TextTag.BUTTON_LABEL}
              colorName="text-light-1"
              align="center"
              style={small ? { fontSize: `${theme.fontSizes.BUTTON_LABEL * 0.8}px` } : {}}
            >
              Non
            </Text>
          </StyledTouchableOpacity>
        </ButtonsValidationWrapper>
      </DoubleValidationWrapper>
    )
  }

  return (
    <>
      {showDoubleValidation ? (
        <>
          {renderDoubleValidationButtons()}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.5} />
        </>
      ) : (
        <StyledTouchableOpacity
          accessible
          accessibilityRole="button"
          accessibilityLabel={accessibilityLabel}
          testID={accessibilityLabel}
          onPress={onPressButton}
          disabled={isButtonDisabled}
          activeOpacity={0.8}
          opacity={opacity}
          fullWidth={fullWidth}
          style={style}
          outlined={outlined}
          danger={danger}
          small={small}
          colorName={colorName}
          rounded={rounded}
          lowPadding={lowPadding}
        >
          {label ? (
            <Text
              tag={Text.TextTag.BUTTON_LABEL}
              colorName={textColorName}
              style={small ? { fontSize: `${theme.fontSizes.BUTTON_LABEL * 0.8}px` } : {}}
              align="center"
            >
              {label}
            </Text>
          ) : (
            children
          )}
          {renderLoading()}
        </StyledTouchableOpacity>
      )}
    </>
  )
}

Button.defaultProps = {
  label: '',
  children: null,
  disabled: false,
  fullWidth: false,
  style: {},
}

Button.Text = ButtonText
Button.Icon = ButtonIcon

export default Button
