import { createStackNavigator, TransitionPresets } from '@react-navigation/stack'
import React from 'react'
import { Dimensions } from 'react-native'
import Header from '../../components/Header'
import BillingProducerNavigationStackParamList from './BillingProducerNavigation.model'
import ProducerBillingDeliveryNotesScreen from '../../../screens/Producer/ProducerBillingDeliveryNotes'
import ProducerBillingScreen from '../../../screens/Producer/ProducerBilling'
import HeaderHome from '../../components/HeaderHome'
import ProducerClientsScreen from '../../../screens/Producer/ProducerClients'
import ProducerClientScreen from '../../../screens/Producer/ProducerClient'
import ProducerBillingPaymentConfigurationScreen from '../../../screens/Producer/ProducerBillingPaymentsConfiguration'

const Stack = createStackNavigator<BillingProducerNavigationStackParamList>()

const BillingProducerNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="ProducerBilling"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="ProducerBilling"
        component={ProducerBillingScreen}
        options={{
          headerShown: true,
          header: () => <HeaderHome />,
          title: 'Reporting Prix',
        }}
      />
      <Stack.Screen
        name="ProducerBillingDeliveryNotes"
        component={ProducerBillingDeliveryNotesScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerBillings" />,
          title: 'Ajouter un relevé de prix',
        }}
      />
      <Stack.Screen
        name="ProducerClients"
        component={ProducerClientsScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerBilling" />,
          title: '',
        }}
      />
      <Stack.Screen
        name="ProducerBillingPaymentConfiguration"
        component={ProducerBillingPaymentConfigurationScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerBillings" />,
          title: 'Configuration des paiements',
        }}
      />
      <Stack.Screen
        name="ProducerClient"
        component={ProducerClientScreen}
        options={{
          presentation: 'modal',
          ...TransitionPresets.SlideFromRightIOS,
          gestureResponseDistance: Dimensions.get('window').height,
          gestureEnabled: true,
          headerShown: true,
          header: () => <Header title="" goBackFallback="ProducerClients" />,
          title: '',
        }}
      />
    </Stack.Navigator>
  )
}

export default BillingProducerNavigation
