import { Spacer } from '../../../components'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { RouteProp, useRoute } from '@react-navigation/native'
import CatalogClientNavigationStackParamList from '../../../navigation/ClientNavigationStack/CatalogClientNavigation/CatalogClientNavigation.model'
import ProductInfos from './components/ProductInfos'
import ProducerInfos from './components/ProducerInfos'
import { ClientBatchPageWrapper } from './ProductInfos.styles'
import { useEffect, useState } from 'react'
import Loader from '../../../components/Loader'
import { Batch, Catalog, Cart } from '../../../domain'
import { queryClient } from '../../../utilities/queryClient'
import StorageUtil from '../../../utilities/storage/storage'

const ClientBatchPage = () => {
  const route = useRoute<RouteProp<CatalogClientNavigationStackParamList, 'ClientBatchPage'>>()
  const { id } = route?.params ?? { id: '' }
  const [batch, setBatch] = useState<Batch | undefined>()
  const [cartId, setCartId] = useState<string>('')
  const catalog: Catalog | undefined = queryClient.getQueryData(['c_catalog'])

  const getStoredCart = async () => {
    return await StorageUtil.getItem('c_cart_id')
  }

  const cart: Cart | undefined = queryClient.getQueryData([
    'c_cart',
    cartId,
  ])

  useEffect(() => {
    if (!catalog || !catalog.batchs) return
    const foundBatch = catalog.batchs.find((batch) => batch?.id === id)
    if (foundBatch) {
      setBatch(foundBatch)
    }

    getStoredCart().then((cartId) => {
      cartId && setCartId(JSON.parse(cartId))
    })
  }, [])

  if (!batch || !batch.product || !cart) {
    return <Loader isLoading pageLoading />
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={4} />
        <ClientBatchPageWrapper>
          <ProductInfos id={batch.product.id} cart={cart} batch={batch} />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <ProducerInfos id={batch.product.id} />
        </ClientBatchPageWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ClientBatchPage
