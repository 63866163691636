import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import { Col, PageTitle, Row, SectionTitle, Spacer, Typography } from '../../../components'
import Loader from '../../../components/Loader'
import { Batch, Group, Tour } from '../../../domain'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import { View } from 'react-native'
import BatchCartCardArrival from '../../../modules/Batch/BatchCartCardArrival'
import { UserEnums } from '../../../../enums'
import CarrierArrivalPreparationFilters from './components/CarrierArrivalPreparationFilters'
import TourProducerService from '../../../services/producer/tour'

export type PropositionsFiltersObject = {
  mode: 'DAY'
  start: Date
  end: Date
  selectedTour?: Tour
}

export type batchsByGroup = {
  tour: Tour
  group: Group
  batchs: Batch[]
}[]

const ProducerArrivalPreparationScreen = () => {
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerReceptionPreparation'>>()
  const { tourId, mode, start } = (route?.params as any) ?? {
    tourId: '',
    mode: undefined,
    start: undefined,
  }
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const { getAccessInfos } = useAuthenticationContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  let tempStartDate = new Date()
  let tempEndDate = new Date()
  let tempMode: 'DAY' = 'DAY'

  if (mode && start) {
    tempStartDate = new Date(start)
    tempEndDate = new Date(start)

    tempStartDate.setHours(0)
    tempStartDate.setMinutes(0)
    tempStartDate.setSeconds(0)

    tempEndDate.setHours(23)
    tempEndDate.setMinutes(59)
    tempEndDate.setSeconds(59)
  }

  const [propositionsFilters, setPropositionsFilter] = useState<PropositionsFiltersObject>({
    mode: tempMode,
    start: tempStartDate,
    end: tempEndDate,
    selectedTour: undefined,
  })

  useEffect(() => {}, [mode, start])

  const dateHourMinusDays = new Date()
  dateHourMinusDays.setUTCDate(-5)

  const {
    data: arrivalBatchs,
    refetch: refetchProductBatchs,
    isRefetching: productBatchsRefetching,
    isLoading: productBatchsLoading,
  } = useQuery<{ batchs: batchsByGroup; tours: Tour[] }, Error>(
    ['p_arrival_batchs'],
    () =>
      TourProducerService.getAllDayArrivalBatchs(
        getAccessInfos().producerId,
        propositionsFilters.start,
        propositionsFilters.end,
      ),
    {
      keepPreviousData: true,
    },
  )
  const productsBatchs = arrivalBatchs?.batchs
  const tours = arrivalBatchs?.tours

  useEffect(() => {
    refetchProductBatchs()
  }, [propositionsFilters])

  const onChangeFilters = async (newFilters: PropositionsFiltersObject) => {
    await setPropositionsFilter(newFilters)
    refetchProductBatchs()
  }

  const renderBatchs = () => {
    if (productBatchsLoading) {
      return <Loader isLoading />
    }

    return (
      productsBatchs &&
      productsBatchs.length > 0 &&
      productsBatchs.map((productBatch) => (
        <>
          <SectionTitle title={`${productBatch?.group?.label}`} />

          {productBatch.batchs.map((batch) => (
            <BatchCartCardArrival
              key={batch.id}
              batch={batch}
              userRole={UserEnums.RoleEnums.PRODUCER}
            />
          ))}
        </>
      ))
    )
  }
  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Dépôt de la marchandise" />

        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <CarrierArrivalPreparationFilters
          propositionsFilters={propositionsFilters}
          onChangeFilters={onChangeFilters}
          nextTours={tours}
        />
        {(!productsBatchs || productsBatchs.length < 1) && (
          <View style={{ alignItems: 'center', width: '100%' }}>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <Typography.Body colorName="text-dark-3" align="center">
              Aucun lot disponible à la journée ou à la semaine
            </Typography.Body>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
          </View>
        )}
        {renderBatchs()}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerArrivalPreparationScreen
