// storage.native.js
// import * as SecureStore from 'expo-secure-store'
import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

const getItem = (key: string) => {
  if (!key || key === null) return ''
  if (Platform.OS === 'web') {
    // return AsyncStorage.getItem(key)
    return localStorage.getItem(key)
  }
  // return SecureStore.getItemAsync(key)
  return AsyncStorage.getItem(key)
}

const setItem = (key: string, data: any) => {
  if (!key || key === null) return ''
  if (Platform.OS === 'web') {
    return localStorage.setItem(key, JSON.stringify(data))
  }
  // return SecureStore.setItemAsync(key, JSON.stringify(data))
  return AsyncStorage.setItem(key, JSON.stringify(data))
}

const deleteItem = (key: string) => {
  if (!key || key === null) return ''
  if (Platform.OS === 'web') {
    // return AsyncStorage.getItem(key)
    localStorage.removeItem(key)
  }
  // return SecureStore.deleteItemAsync(key)
  return AsyncStorage.removeItem(key)
}

const clearStorage = () => {
  if (Platform.OS === 'web') {
    localStorage.clear()
  }
  return AsyncStorage.clear()
}

const StorageUtil = {
  getItem,
  setItem,
  deleteItem,
  clearStorage,
}

export default StorageUtil
