import { useQuery } from '@tanstack/react-query'
import { Cart, Tour } from '../../../domain'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import TourTopBox from '../../../components/TourTopBox'
import { UserEnums } from '../../../../enums'
import CartCarrierService from '../../../services/carrier/cart'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { CommonActions, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import TourCarrierService from '../../../services/carrier/tour'
import { Button, SectionTitle, Spacer, Typography } from '../../../components'
import { useEffect, useState } from 'react'
import StorageUtil from '../../../utilities/storage/storage'
import GeneralUtils from '../../../utilities/utils/general'
import { StackNavigationProp } from '@react-navigation/stack'
import FormatUtils from '../../../utilities/utils/format'
import {
  StyledButtonWrapper,
  SummaryLeftBox,
  SummaryRightBox,
  TotalSummaryCard,
  TotalSummaryCardContent,
} from './CarrierUpdateCart.styles'
import { queryClient } from '../../../utilities/queryClient'
import { Alert } from 'react-native'
import OrderCarrierService from '../../../services/carrier/order'
import Loader from '../../../components/Loader'
import CartBatchCardNew from '../../../components/CartBatchCardNew'
import { TutorialStepData } from '../../../../enums/tutorialStep'

const CarrierUpdateCart = () => {
  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierUpdateCart'>>()
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  const cartIdParam = route?.params?.cartId
  const tourId = route?.params?.tourId
  const [cartId, setCartId] = useState<string | undefined>(cartIdParam)
  const [isNewOrder, setIsNewOrder] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const stopId = route?.params?.stopId ?? ''

  const {
    data: cart,
    refetch: refetchCart,
    isInitialLoading: cartLoading,
  } = useQuery<Cart | null, Error>(['sf_cart'], () => CartCarrierService.getOneCart(cartId), {
    keepPreviousData: true,
    enabled: cartId !== undefined,
  })

  const { data: tour, isInitialLoading: tourLoading } = TourCarrierService.getOne.query(tourId)

  useEffect(() => {
    if (!tour || !tourId) return

    const getStoredCart = async () => {
      let storedCartId = await StorageUtil.getItem('sf_cart_id')
      let isNewOrderStorage = await StorageUtil.getItem('sf_cart_is_new_order')
      const storedCartIdExpirationDate = await StorageUtil.getItem('sf_cart_id_expiration_date')

      if (storedCartIdExpirationDate) {
        const isTooOld = GeneralUtils.isDateBefore(
          new Date(JSON.parse(storedCartIdExpirationDate)),
          new Date(),
        )
        if (isTooOld) {
          storedCartId = null
          StorageUtil.deleteItem('sf_cart_id')
          StorageUtil.deleteItem('sf_cart_is_new_order')
          StorageUtil.deleteItem('sf_cart_id_expiration_date')
        }
      }

      if (storedCartId) {
        setCartId(JSON.parse(storedCartId))
        setIsNewOrder(isNewOrderStorage !== null ? true : false)
        refetchCart()
      } else {
        navigation.navigate('CarrierTourClient', {
          id: stopId,
          cartId: cart?.id,
          tourId: tour.id,
        })
      }
    }
    getStoredCart()
  }, [tour, tourId])

  if (!cartId) return null
  if (!cart?.client?.id) return null

  const onClickAddCartBatch = () => {
    if (!cartId || !tourId) return
    navigation.navigate('CarrierAddCartBatchV2', {
      cartId: cartId,
      clientId: cart.client?.id ?? '',
      tourId: tourId,
    })
  }

  if (cartLoading || tourLoading) return <Loader isLoading pageLoading />

  const onValidateOrder = async () => {
    if (!cart || !cart.id) return
    setIsLoading(true)
    const isUpdatedOrder = cart.targetCart
    let order = null
    if (isUpdatedOrder && cart.order?.id) {
      order = await OrderCarrierService.update({ cartId: cart.id })
    } else {
      order = await OrderCarrierService.create({ cartId: cart.id })
    }
    if (order && order.id) {
      StorageUtil.deleteItem('sf_cart_id')
      StorageUtil.deleteItem('sf_cart_is_new_order')

      await queryClient.refetchQueries(['sf_stop', stopId])
      TourCarrierService.getOne.invalidate(tourId)

      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            {
              name: 'CarrierTour',
              params: { id: tourId },
            },
            {
              name: 'CarrierTourClient',
              params: { id: stopId, cartId: order.cart?.id, tourId: tourId },
            },
          ],
        }),
      )

      setIsLoading(false)
      return
    } else {
      Alert.alert('Erreur lors de la création de la commande')
    }
    setIsLoading(false)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      {cart && tour && (
        <TourTopBox tour={tour} cart={cart} userRole={UserEnums.RoleEnums.CARRIER} />
      )}
      <ContentWrapper marginTopLarge>
        <Spacer fixedSize={50} axis={Spacer.AxisEnum.VERTICAL} />
        <SectionTitle title={!isNewOrder ? 'Contenu de la commande' : "Création d'une commande"} />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        {cart?.targetCart && !isNewOrder && (
          <>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.CardTitle>
              Modification de la commande #{FormatUtils.formatId(cart.order?.id)}
            </Typography.CardTitle>
            <Typography.BodySmall colorName="text-dark-3">
              Validée initialement le{' '}
              {FormatUtils.capitalize(
                FormatUtils.formatDate(cart.order?.createdDate, 'FullDate+StartTime'),
              )}
            </Typography.BodySmall>
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          </>
        )}
        {cart?.cartBatchs?.map((cb) => (
          <CartBatchCardNew
            key={cb.id}
            cartBatch={cb}
            cart={cart}
            displayClientPrice
            displayProducerLabel
          />
        ))}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        <StyledButtonWrapper>
          <Button label="Ajouter un lot" onPress={() => onClickAddCartBatch()} small />
        </StyledButtonWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
        {cart?.cartBatchs && cart?.cartBatchs?.length > 0 && (
          <>
            <TotalSummaryCard>
              <TotalSummaryCardContent>
                <SummaryLeftBox>
                  <Typography.Body>{'Total HT'}</Typography.Body>
                  {cart.totalVAT55 && cart.totalVAT55 > 0 ? (
                    <Typography.Body>{'TVA 5,5%'}</Typography.Body>
                  ) : null}
                  {cart.totalVAT10 && cart.totalVAT10 > 0 ? (
                    <Typography.Body>{'TVA 10%'}</Typography.Body>
                  ) : null}
                  {cart.totalVAT20 && cart.totalVAT20 > 0 ? (
                    <Typography.Body>{'TVA 20%'}</Typography.Body>
                  ) : null}
                  <Typography.Body>{'Total TVA'}</Typography.Body>
                  <Typography.Body bold>{'Total TTC '}</Typography.Body>
                </SummaryLeftBox>
                <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={2} />
                <SummaryRightBox>
                  <Typography.Body>
                    {FormatUtils.formatPrice(cart?.totalHTBatchs || 0)}
                  </Typography.Body>
                  {cart.totalVAT55 && cart.totalVAT55 > 0 ? (
                    <Typography.Body>{FormatUtils.formatPrice(cart.totalVAT55)}</Typography.Body>
                  ) : null}
                  {cart.totalVAT10 && cart.totalVAT10 > 0 ? (
                    <Typography.Body>{FormatUtils.formatPrice(cart.totalVAT10)}</Typography.Body>
                  ) : null}
                  {cart.totalVAT20 && cart.totalVAT20 > 0 ? (
                    <Typography.Body>{FormatUtils.formatPrice(cart.totalVAT20)}</Typography.Body>
                  ) : null}
                  <Typography.Body>
                    {FormatUtils.formatPrice(
                      (cart.totalVAT20 || 0) + (cart.totalVAT10 || 0) + (cart.totalVAT55 || 0),
                    )}
                  </Typography.Body>
                  <Typography.Body bold>{FormatUtils.formatPrice(cart.totalTTC)}</Typography.Body>
                </SummaryRightBox>
              </TotalSummaryCardContent>
            </TotalSummaryCard>
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <StyledButtonWrapper>
              <Button
                label={isNewOrder ? 'Valider la commande' : 'Modifier la commande'}
                onPress={() => onValidateOrder()}
                loading={isLoading}
              />
            </StyledButtonWrapper>
          </>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierUpdateCart
