import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { ScrollView, Switch } from 'react-native-gesture-handler'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Card,
  Col,
  Dropdown,
  PageTitle,
  Row,
  Spacer,
  TitleWithTag,
  Typography,
} from '../../../components'
import {
  ClientGroup,
  DeliveryNote,
  DeliveryNotesCartBatchs,
  Group,
  Pagination,
  ProducerGroup,
} from '../../../domain'
import { CarrierStackParamList } from '../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import {
  CartBatchCardContentWrapper,
  StyledButtonWrapper,
  StyledModeSwitchWrapper,
  ToursContentWrapper,
} from './CarrierBillingDeliveryNotes.styles'
import { useQuery } from '@tanstack/react-query'
import DeliveryNoteCarrierService from '../../../services/carrier/deliveryNote'
import Loader from '../../../components/Loader'
import TourDeliveryNoteCard from '../../../modules/DeliveryNote/TourDeliveryNoteCard'
import { StackNavigationProp } from '@react-navigation/stack'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import CarrierBillingDateFilter from './components/CarrierBillingDateFilter'
import GroupCarrierService from '../../../services/carrier/group'
import { View } from 'react-native'
import DeliveryNoteProducerService from '../../../services/producer/deliveryNote'
import DirectusUtil from '../../../utilities/utils/directus'
import CardListPaginated from '../../../components/CardListPaginated'
import CarrierBillingDeliveryNotesMetrics from './components/CarrierBillingDeliveryNotesMetrics'
import OrderCarrierService from '../../../services/carrier/order'
import FormatUtils from '../../../utilities/utils/format'
import { PRODUCER_BILLING_TYPES, ProducerVatTypeEnum } from '../../../../enums/producer'
import GeneralUtils from '../../../utilities/utils/general'
import { queryClient } from '../../../utilities/queryClient'
import { TutorialStepData } from '../../../../enums/tutorialStep'
import AccountUtil from '../../../utilities/utils/account'

const currentMonth = new Date().getMonth()
const currentYear = new Date().getFullYear()
const defaultDateHour = new Date(currentYear, currentMonth, 1, 0, 0, 0)
const defaultDateHour2 = new Date(currentYear, currentMonth + 1, 1, 0, 0, 0)

const CarrierBillingDeliveryNotesScreen = () => {
  const theme = useTheme()
  const { getAccessInfos } = useAuthenticationContext()

  const route = useRoute<RouteProp<CarrierStackParamList, 'CarrierBillingDeliveryNotes'>>()
  const [displayFilters, setDisplayFilters] = useState(false)
  const [selectedProducer, setSelectedProducer] = useState<any>()
  const [selectedClient, setSelectedClient] = useState<any>()
  const [cartBatchsStats, setCartBatchsStats] = useState({
    totalHTProducer: 0,
    totalHT: 0,
    totalHTCheck: 0,
  })
  const [mode, setMode] = useState<number>(1)

  const [pageSizeDeliveryNotes, setPageSizeDeliveryNotes] = useState(10)
  const [pageNumberDeliveryNotes, setPageNumberDeliveryNotes] = useState(1)

  const [metricsFilters, setMetricsFilters] = useState({
    start: defaultDateHour,
    end: defaultDateHour2,
  })
  const navigation = useNavigation<StackNavigationProp<CarrierStackParamList>>()
  // const []

  const {
    data: clientGroups,
    refetch: refetchClientGroups,
    isLoading: isClientGroupsLoading,
  } = useQuery<{ data: ClientGroup[]; pagination: Pagination }, Error>(
    ['sf_group_clients', 500, 1],
    () =>
      GroupCarrierService.getOneGroupClients(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        500,
        1,
        undefined,
      ),
    {
      keepPreviousData: true,
      staleTime: 600000, // 10min
    },
  )

  const {
    data: producerGroups,
    refetch: refetchProducerGroups,
    isLoading: isProducerGroupsLoading,
  } = useQuery<{ data: ProducerGroup[]; pagination: Pagination }, Error>(
    ['sf_group_producers', 500, 1],
    () =>
      GroupCarrierService.getOneGroupProducers(
        getAccessInfos().carrierGroupId,
        getAccessInfos().carrierId,
        500,
        1,
        undefined,
      ),
    {
      keepPreviousData: true,
      staleTime: 600000, // 10min
    },
  )

  const {
    data: deliveryNotes,
    refetch: refetchDeliveryNotes,
    isInitialLoading: deliveryNotesLoading,
    isLoading: isLoadingDeliveryNotes,
  } = useQuery<{ data: DeliveryNote[]; pagination: Pagination; stats: any }, Error>(
    ['sf_billing_delivery_notes', getAccessInfos().carrierGroupId],
    () =>
      DeliveryNoteCarrierService.getAll(
        getAccessInfos().carrierGroupId,
        undefined,
        undefined,
        selectedClient ? selectedClient.id : undefined,
        selectedProducer ? selectedProducer.id : undefined,
        metricsFilters.end,
        metricsFilters.start,
        pageSizeDeliveryNotes,
        pageNumberDeliveryNotes,
      ),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: deliveryNotesCartBatchs,
    refetch: refetchDeliveryNotesCartBatchs,
    isInitialLoading: deliveryNotesCartBatchsLoading,
    isLoading: isLoadingDeliveryNotesCartBatchs,
  } = useQuery<{ data: DeliveryNotesCartBatchs[]; pagination: Pagination; stats: any }, Error>(
    ['sf_billing_cart_batchs', getAccessInfos().carrierGroupId],
    () =>
      OrderCarrierService.getAllCartBatchs(
        getAccessInfos().carrierGroupId,
        undefined,
        selectedClient ? selectedClient.id : undefined,
        selectedProducer ? selectedProducer.id : undefined,
        metricsFilters.end,
        metricsFilters.start,
        pageSizeDeliveryNotes,
        pageNumberDeliveryNotes,
      ),
    {
      enabled:
        (selectedProducer && selectedProducer.id !== undefined) ||
        (selectedClient && selectedClient.id !== undefined),
    },
  )

  useEffect(() => {
    let totalHTProducer = 0
    let totalHT = 0
    let totalHTCheck = 0

    deliveryNotesCartBatchs?.data?.map((cb) => {
      totalHTProducer +=
        (cb.cartBatchUnitPriceProducer || cb.batchUnitPriceProducer || 0) * (cb.totalQuantity || 0)
      totalHT += (cb.cartBatchUnitPrice || 0) * (cb.totalQuantity || 0)
      totalHTCheck += cb.totalIncome || 0
    })

    setCartBatchsStats({
      totalHTProducer,
      totalHT,
      totalHTCheck,
    })
  }, [deliveryNotesCartBatchs, setCartBatchsStats])

  const onClickDeliveryNote = async (deliveryNote: DeliveryNote) => {
    if (!deliveryNote.id) return

    const deliveryNoteWithAccess = await DeliveryNoteProducerService.getOne(deliveryNote.id)
    if (!deliveryNoteWithAccess.documentId || !deliveryNoteWithAccess.accessToken) return

    window.open(
      DirectusUtil.getDocumentDownloadUrlFromId(
        deliveryNoteWithAccess.documentId,
        deliveryNoteWithAccess.accessToken,
      ),
      '_blank',
    )
  }

  const [isLoading, setIsLoading] = useState(false)

  const cartBatchsCsvCardTitle =
    'Produit\tTournées\tcommandes\tQuantité acheté\tPrix producteur unitaire\ttotal\n'
  let cartBatchsCsvContent = ''

  deliveryNotesCartBatchs?.data.map((cb) => {
    cartBatchsCsvContent += `${cb.productLabel}\t${cb.nbTours}\t${
      cb.nbCarts
    }\t${FormatUtils.formatQuantity(cb.totalQuantity, cb.mesureType)}\t${FormatUtils.formatPrice(
      cb.cartBatchUnitPriceProducer || cb.cartBatchUnitPrice,
    )}\t${FormatUtils.formatPrice(cb.totalCostProducer)}\n`
  })

  const copyCartBatchs = () => {
    navigator.clipboard.writeText(cartBatchsCsvCardTitle + cartBatchsCsvContent)
  }

  // Code Produit Colis Quantité Prix HT Total HT TVA
  const cartBatchsClientCsvCardTitle = 'Code\tProduit\tColis\tQuantité\tPrix HT\tTotal HT\t TVA\n'
  let cartBatchsClientCsvContent = ''

  deliveryNotes?.data.map((dn) => {
    cartBatchsClientCsvContent += `BL n°${dn.identifier}\t Livraison du ${FormatUtils.formatDate(
      dn.tour?.start,
      'Date',
    )}\t\t${FormatUtils.formatPrice(dn.totalHTBatchs)}\n`
    dn?.order?.cart?.cartBatchs?.map((cb) => {
      cartBatchsClientCsvContent += `${cb.batch?.id.slice(-4)}\t${cb.batch?.product?.label}\t${
        cb.packingQuantity
      }\t${FormatUtils.formatQuantity(
        cb.totalQuantity,
        cb.batch?.product?.mesureType,
      )}\t${FormatUtils.formatPrice(cb.unitPrice)}\t${FormatUtils.formatPrice(
        (cb.unitPrice || 0) * (cb.totalQuantity || 0),
      )}\t ${
        cb.batch?.product?.producer?.account &&
        !AccountUtil.isProfileSubjectToVAT(cb.batch?.product?.producer?.account)
          ? '0'
          : cb.batch?.product?.vatRate
      }\n`
    })
  })

  const copyCartBatchsClient = () => {
    navigator.clipboard.writeText(cartBatchsClientCsvCardTitle + cartBatchsClientCsvContent)
  }

  const onClickMetricsChangeDate = async (value?: number, exactDate?: any, paramDate?: string) => {
    let tempMetricsFilter = { start: metricsFilters.start, end: metricsFilters.end }
    if (exactDate && paramDate) {
      // date changed with date input
      if (paramDate === 'start') {
        tempMetricsFilter.start = exactDate
      } else if (paramDate === 'end') {
        tempMetricsFilter.end = exactDate
      }
    } else {
      // date changed with month switcher
      let currentMonthNew = metricsFilters.start.getMonth()
      let currentYear = metricsFilters.start.getFullYear()
      currentMonthNew = currentMonthNew + (value || 0)

      if (currentMonthNew < 0) {
        currentMonthNew = 11
        currentYear = currentYear - 1
      }

      if (currentMonthNew > 11) {
        currentMonthNew = 0
        currentYear = currentYear + 1
      }

      const newStartDate = new Date(currentYear, currentMonthNew, 1, 0, 0, 0)

      const newEndDate = new Date(currentYear, currentMonthNew + 1, 1, 0, 0, 0)

      tempMetricsFilter = { start: newStartDate, end: newEndDate }
    }

    await setMetricsFilters(tempMetricsFilter)
    if (!exactDate) {
      onValidateFilter()
    }
  }

  const isDateIntervalValidForBatchs = () => {
    if (
      (selectedProducer && selectedProducer.id !== undefined) ||
      (selectedClient && selectedClient.id !== undefined)
    ) {
      if (GeneralUtils.diffDays(metricsFilters.start, metricsFilters.end) <= 46) {
        return true
      }
    }
    queryClient.setQueryData(['sf_billing_cart_batchs', getAccessInfos().carrierGroupId], undefined)
    return false
  }

  const onValidateFilter = () => {
    refetchDeliveryNotes()
    if (isDateIntervalValidForBatchs()) {
      refetchDeliveryNotesCartBatchs()
    }
  }

  if (deliveryNotesLoading) {
    return <Loader isLoading pageLoading />
  }

  const removeFilters = async () => {
    await setSelectedProducer(undefined)
    await setSelectedClient(undefined)
    await setDisplayFilters(false)
    await setMode(1)
    setTimeout(() => {
      onValidateFilter()
    }, 500)
  }

  const onChangePageDeliveryNotes = async (pageNumber: number) => {
    await setPageNumberDeliveryNotes(pageNumber)
    onValidateFilter()
  }

  const onSwitchMode = async () => {
    if (mode === 1 && GeneralUtils.diffDays(metricsFilters.start, metricsFilters.end) > 45) {
      await setMetricsFilters({
        start: defaultDateHour,
        end: defaultDateHour2,
      })
      onValidateFilter()
    }
    setMode(mode === 1 ? 2 : 1)
  }

  const displayCartBatchs = () => {
    if (selectedProducer && selectedProducer.id !== undefined) {
      return (
        <>
          <StyledButtonWrapper>
            <Button
              small
              colorName="color-grey"
              label={'Copier'}
              onPress={() => copyCartBatchs()}
            />
          </StyledButtonWrapper>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Typography.Body colorName="text-dark-1">
            Récapitulatif des ventes du producteur
          </Typography.Body>
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          <Card>
            <CartBatchCardContentWrapper>
              <Row>
                <Col xs={5}>
                  <Typography.BodySmall colorName="text-dark-1">Produit</Typography.BodySmall>
                </Col>
                <Col xs={2} alignItems="center">
                  <Typography.BodySmall colorName="text-dark-3" align="center">
                    Tournées-commandes
                  </Typography.BodySmall>
                </Col>
                <Col xs={2} alignItems="center">
                  <Typography.BodySmall colorName="text-dark-3" align="center">
                    Quantité acheté
                  </Typography.BodySmall>
                </Col>
                <Col xs={3} alignItems="flex-end" justifyContent="flex-end">
                  <Typography.BodySmall colorName="text-dark-3" align="right">
                    Prix producteur unitaire - total
                  </Typography.BodySmall>
                </Col>
              </Row>
            </CartBatchCardContentWrapper>
          </Card>

          {deliveryNotesCartBatchs?.data.map((cb) => {
            return (
              <>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />
                <Card>
                  <CartBatchCardContentWrapper>
                    <Row>
                      <Col xs={5}>
                        <TitleWithTag tag={cb.internalReference}>
                          <Typography.BodySmall colorName="text-dark-1">
                            {cb.productLabel}
                          </Typography.BodySmall>
                        </TitleWithTag>
                      </Col>
                      <Col xs={2} alignItems="center">
                        <Typography.BodySmall colorName="text-dark-3">
                          {cb.nbTours}-{cb.nbCarts}
                        </Typography.BodySmall>
                      </Col>
                      <Col xs={2} alignItems="center">
                        <Typography.BodySmall colorName="text-dark-3">
                          {FormatUtils.formatQuantity(cb.totalQuantity, cb.mesureType)}
                        </Typography.BodySmall>
                      </Col>
                      <Col xs={3} alignItems="flex-end">
                        <Typography.BodySmall colorName="text-dark-3">
                          {FormatUtils.formatPrice(
                            cb.cartBatchUnitPriceProducer || cb.cartBatchUnitPrice,
                          )}{' '}
                          - {FormatUtils.formatPrice(cb.totalCostProducer)}
                        </Typography.BodySmall>
                      </Col>
                    </Row>
                  </CartBatchCardContentWrapper>
                </Card>
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />
              </>
            )
          })}
        </>
      )
    }

    return (
      <>
        <StyledButtonWrapper>
          <Button
            small
            colorName="color-grey"
            label={'Copier'}
            onPress={() => copyCartBatchsClient()}
          />
        </StyledButtonWrapper>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.2} />
        <Typography.Body colorName="text-dark-1">
          Récapitulatif des achats du client
        </Typography.Body>
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
        <Card>
          <CartBatchCardContentWrapper>
            <Row>
              <Col xs={5}>
                <Typography.BodySmall colorName="text-dark-1">Produit</Typography.BodySmall>
              </Col>
              <Col xs={2} alignItems="center">
                <Typography.BodySmall colorName="text-dark-3" align="center">
                  Colis (Quantité)
                </Typography.BodySmall>
              </Col>
              <Col xs={4} alignItems="center">
                <Typography.BodySmall colorName="text-dark-3" align="center">
                  Prix HT (Total HT)
                </Typography.BodySmall>
              </Col>
              <Col xs={1} alignItems="flex-end" justifyContent="flex-end">
                <Typography.BodySmall colorName="text-dark-3" align="right">
                  TVA
                </Typography.BodySmall>
              </Col>
            </Row>
          </CartBatchCardContentWrapper>
        </Card>

        {deliveryNotes?.data.map((dn) => {
          return (
            <>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />
              <Card>
                <CartBatchCardContentWrapper>
                  <Row>
                    <Col xs={3}>
                      <Typography.BodySmall bold colorName="text-dark-1">
                        BL n°{dn.identifier}
                      </Typography.BodySmall>
                    </Col>
                    <Col xs={5} alignItems="center">
                      <Typography.BodySmall bold colorName="text-dark-1">
                        Livraison du {FormatUtils.formatDate(dn.tour?.start, 'Date')}
                      </Typography.BodySmall>
                    </Col>
                    <Col xs={4} alignItems="center">
                      <Typography.BodySmall bold colorName="text-dark-1">
                        {FormatUtils.formatPrice(dn.totalHTBatchs)}HT
                      </Typography.BodySmall>
                    </Col>
                  </Row>
                </CartBatchCardContentWrapper>
              </Card>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />
              {dn.order?.cart?.cartBatchs?.map((cb) => {
                return (
                  <>
                    <Card>
                      <CartBatchCardContentWrapper>
                        <Row>
                          <Col xs={5}>
                            <Typography.BodySmall colorName="text-dark-1">
                              {cb.batch?.product?.label}
                            </Typography.BodySmall>
                          </Col>
                          <Col xs={2} alignItems="center">
                            <Typography.BodySmall colorName="text-dark-3" align="center">
                              {cb.packingQuantity} (
                              {FormatUtils.formatQuantity(
                                cb.totalQuantity,
                                cb.batch?.product?.mesureType,
                              )}
                              )
                            </Typography.BodySmall>
                          </Col>
                          <Col xs={4} alignItems="center">
                            <Typography.BodySmall colorName="text-dark-3" align="center">
                              {FormatUtils.formatPrice(cb.unitPrice)}HT (
                              {FormatUtils.formatPrice(
                                (cb.unitPrice || 0) * (cb.totalQuantity || 0),
                              )}{' '}
                              HT)
                            </Typography.BodySmall>
                          </Col>
                          <Col xs={1} alignItems="flex-end" justifyContent="flex-end">
                            <Typography.BodySmall colorName="text-dark-3" align="right">
                              {cb.batch?.product?.producer?.account?.legalInfo.vatType ===
                              ProducerVatTypeEnum.WITHOUT_VAT
                                ? '0'
                                : cb.batch?.product?.vatRate}
                            </Typography.BodySmall>
                          </Col>
                        </Row>
                      </CartBatchCardContentWrapper>
                    </Card>
                    <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.8} />
                  </>
                )
              })}
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            </>
          )
        })}
      </>
    )
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <ToursContentWrapper>
          <PageTitle
            title={`Bons de livraison (${deliveryNotes?.pagination?.totalCount || 0})`}
            buttonRight={
              <Button small label={'Filtres'} onPress={() => setDisplayFilters(!displayFilters)} />
            }
          />
          {!displayFilters && (selectedClient || selectedProducer) ? (
            <Typography.Body colorName="text-dark-3">
              Filtres : {selectedProducer ? `producteur (${selectedProducer.label})` : ''}{' '}
              {selectedClient ? `client (${selectedClient.label})` : ''}
            </Typography.Body>
          ) : null}
          {displayFilters && (
            <>
              <Typography.Body colorName="text-dark-3">Filtres</Typography.Body>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Dropdown
                label={'Choisir un producteur'}
                onChange={(value) => setSelectedProducer(value)}
                multiple={false}
                defaultValue={selectedProducer?.id}
                optionsDefault={(producerGroups?.data || []).map((producerGroup) => ({
                  ...producerGroup.producer,
                  label: `${producerGroup.producer?.label} (facturation ${
                    PRODUCER_BILLING_TYPES.find((bt) => bt.value === producerGroup?.billingType)
                      ?.label
                  })`,
                  value: producerGroup.producer?.id,
                }))}
                zIndex={2000}
                itemKey="producers"
                isSearchable
              />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <Dropdown
                label={'Choisir un client'}
                onChange={(value) => setSelectedClient(value)}
                multiple={false}
                defaultValue={selectedClient?.id}
                optionsDefault={(clientGroups?.data || []).map((clientGroup) => ({
                  ...clientGroup.client,
                  label: clientGroup.client?.label,
                  value: clientGroup.client?.id,
                }))}
                zIndex={1000}
                itemKey="producers"
                isSearchable
              />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
              <View style={{ flexDirection: 'row' }}>
                <Button
                  small
                  colorName="color-danger"
                  label={'Supprimer filtres'}
                  onPress={() => removeFilters()}
                />
                <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
                <Button
                  small
                  label={'Valider'}
                  onPress={() => {
                    onValidateFilter()
                    setDisplayFilters(false)
                  }}
                />
              </View>
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            </>
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
          <CarrierBillingDateFilter
            onClickMetricsChangeDate={onClickMetricsChangeDate}
            metricsFilters={metricsFilters}
            metricsLoading={false} //metricsLoading}
            refetchDeliveryNotes={onValidateFilter}
          />
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />

          {deliveryNotes?.stats ? (
            <CarrierBillingDeliveryNotesMetrics
              metrics={deliveryNotes?.stats}
              cartBatchsStats={cartBatchsStats}
            />
          ) : null}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />

          {(selectedProducer || selectedClient) && (
            <StyledModeSwitchWrapper>
              <Typography.Body>Affichage par BL</Typography.Body>
              <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
              <Switch
                trackColor={{ false: '#767577', true: theme.colors['color-primary'] }}
                onValueChange={() => onSwitchMode()}
                value={mode === 2}
              />
              <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={1} />
              <Typography.Body>Affichage par lots</Typography.Body>
            </StyledModeSwitchWrapper>
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
          {isLoadingDeliveryNotes || deliveryNotesLoading ? (
            <Loader isLoading />
          ) : (
            <>
              {mode === 1 ? (
                <CardListPaginated
                  data={deliveryNotes?.data}
                  pagination={deliveryNotes?.pagination}
                  element={function (deliveryNote: any) {
                    return (
                      <TourDeliveryNoteCard
                        key={deliveryNote.id}
                        deliveryNote={deliveryNote}
                        onClick={onClickDeliveryNote}
                        isCarrier
                        isBilling
                      />
                    )
                  }}
                  isLoading={deliveryNotesLoading}
                  emptyMessage="Aucune tournée passée"
                  onChangePage={onChangePageDeliveryNotes}
                />
              ) : (
                <>
                  {!isDateIntervalValidForBatchs() ? (
                    <Typography.Body colorName="color-danger">
                      L'intervalle de date ne peut pas dépasser 45 jours
                    </Typography.Body>
                  ) : (
                    displayCartBatchs()
                  )}
                </>
              )}
            </>
          )}
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={3} />
        </ToursContentWrapper>
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CarrierBillingDeliveryNotesScreen
