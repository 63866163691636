import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Button } from '../../../../../components'
import StyledWrapper from './PasswordForgottenButton.styles'
import { AuthenticationStackParamList } from '../../../../../navigation/AuthenticationNavigationStack/AuthenticationNavigationStack.model'
import { PasswordForgottenButtonProps } from './PasswordForgottenButton.model'

const PasswordForgottenButton = ({ email }: PasswordForgottenButtonProps) => {
  const navigation = useNavigation<StackNavigationProp<AuthenticationStackParamList>>()

  const goToPasswordForgotten = () => {
    navigation.navigate('ForgottenPassword', {})
  }

  return (
    <StyledWrapper>
      <Button.Text
        label="Mot de passe oublié?"
        onPress={goToPasswordForgotten}
        colorName="color-grey"
      />
    </StyledWrapper>
  )
}
export default PasswordForgottenButton
