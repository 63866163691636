import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import { ProductEnums } from '../../../../enums'
import {
  Button,
  Card,
  Col,
  DocumentPickerInput,
  Dropdown,
  Icon,
  Icons,
  PageTitle,
  Row,
  Spacer,
  Typography,
} from '../../../components'
import TextInput from '../../../components/inputs/TextInput'
import Loader from '../../../components/Loader'
import { AddContainer, Container, Product, ProductType, UpdateProduct } from '../../../domain'
import i18n from '../../../i18n'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import ProductProducerService from '../../../services/producer/product'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import {
  ContentWrapper,
  ScreenSafeAreaWrapper,
  ScrollableFormWrapper,
} from '../../../utilities/styling/wrappers'
import DirectusUtil from '../../../utilities/utils/directus'
import FormatUtils from '../../../utilities/utils/format'
import {
  UpdateProductContentWrapper,
  StyledButtonWrapper,
  StyledImage,
  StyledImageBox,
  BackToListWrapper,
  StyledBackButtonWrapper,
  CardContentWrapper,
} from './ProducerUpdateProduct.styles'
import { View } from 'react-native'
import { Category } from '../../../domain/Category'
import AddContainerForm from '../ProducerAddProduct/AddContainerForm'
import { getProductLabelsQO } from '../../../services/common/product'
import { InternalReferenceInput } from '../ProducerAddProduct/InternalReferenceInput'

const ProducerUpdatePoductScreen = () => {
  const theme = useTheme()
  const [updatedProduct, setUpdatedProduct] = useState<UpdateProduct>()
  const route = useRoute<RouteProp<ProducerStackParamList, 'ProducerUpdateProduct'>>()
  const { getAccessInfos } = useAuthenticationContext()
  const [selectedContainer, setSelectedContainer] = useState<any>()
  const [selectedProductType, setSelectedProductType] = useState<any>()
  const [selectedMesureType, setSelectedMesureType] = useState<any | undefined>(undefined)
  const [selectedVat, setSelectedVat] = useState<any | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isAddingProductType, setIsAddingProductType] = useState(false)
  const [isAddingContainerType, setIsAddingContainerType] = useState(false)
  const [similarProductTypes, setSimilarProductTypes] = useState<ProductType[] | undefined>(
    undefined,
  )
  const [newProductTypeLabel, setNewProductTypeLabel] = useState<any>()
  const [selectedCategory, setSelectedCategory] = useState<any | undefined>()
  const [selectedLabels, setSelectedLabels] = useState<string[] | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const { id } = route?.params ?? { id: '' }

  const {
    data: productTypes,
    refetch: refetchProductTypes,
    isInitialLoading: productTypesLoading,
  } = useQuery<ProductType[], Error>(
    ['p_product_types'],
    () => ProductProducerService.getAllProductTypes(),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: containers,
    refetch: refetchContainers,
    isInitialLoading: containersLoading,
  } = useQuery<Container[], Error>(
    ['p_containers'],
    () => ProductProducerService.getAllContainers(getAccessInfos().producerId),
    {
      keepPreviousData: true,
    },
  )

  const productLabels = useQuery(getProductLabelsQO()).data

  const {
    data: product,
    refetch: refetchProduct,
    isInitialLoading: productLoading,
  } = useQuery<Product, Error>(
    ['p_product'],
    () => ProductProducerService.getOneProduct(getAccessInfos().producerId, id),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: categories,
    refetch: refetchCategories,
    isInitialLoading: categoriesLoading,
  } = useQuery<Category[], Error>(
    ['p_categories'],
    () => ProductProducerService.getAllCategories(),
    {
      keepPreviousData: true,
    },
  )

  const MAX_DESCRIPTION_LENGTH = 1000

  useEffect(() => {
    if (!product) return
    if (product) {
      setUpdatedProduct({
        ...product,
        newImageUri: undefined,
        packing: FormatUtils.floatToString(product.packing),
        minUnitPrice: FormatUtils.floatToString(product.minUnitPrice),
        unitWeight: product.unitWeight ? FormatUtils.floatToString(product.unitWeight) : undefined,
      })
      setSelectedContainer({
        ...product.container,
        value: product.container?.id,
      })
      setSelectedProductType({
        ...product.productType,
        value: product.productType?.id,
      })
      const mesureType = ProductEnums.MESURE_TYPES.find((mt) => mt.value === product.mesureType)
      setSelectedMesureType({
        ...mesureType,
      })
      const vat = ProductEnums.VATS.find((v) => v.valueDecimal === product.vatRate.toString())
      setSelectedVat({
        ...vat,
        value: vat?.value,
      })
      setSelectedLabels(product.productLabels?.map((pl) => pl.id))
    }
  }, [id, setUpdatedProduct, product])

  const setValue = (value: any, param: string) => {
    if (!updatedProduct || !param || !(param in updatedProduct)) return null
    const tourTemp = Object.assign({}, updatedProduct)

    tourTemp[param as keyof typeof updatedProduct] = value
    setUpdatedProduct(tourTemp)
  }

  const onChangeContainer = (value: any) => {
    setSelectedContainer(value)
  }

  const onChangeProductType = (value: any) => {
    setSelectedProductType(value)
  }

  const onChangeMesureType = (value: any) => {
    setSelectedMesureType(value)
  }

  const onChangeVat = (value: any) => {
    setSelectedVat(value)
  }

  const onChangeCategory = async (value: any) => {
    await setSelectedCategory(value)
    fetchSimilarProductTypes(value.id)
  }

  const onChangeProductLabels = (selectedOptions: any[]) => {
    const selectedLabelIds = selectedOptions.map((option: any) => option.value)
    setSelectedLabels(selectedLabelIds)
  }

  const handleAddContainer = async (containerToAdd: AddContainer) => {
    setErrorMessage('')
    setIsLoading(true)

    const container = {
      type: containerToAdd.type,
      length: containerToAdd.length,
      width: containerToAdd.width,
      height: containerToAdd.height,
      producerId: getAccessInfos().producerId,
    }

    await ProductProducerService.createContainer(container)
      .then((createdContainer) => {
        setSelectedContainer(createdContainer)
        setIsAddingContainerType(false)
        queryClient.fetchQuery(['p_containers'])
      })
      .catch((error) => {
        setErrorMessage('Un problème est survenu lors de la création du type de colis')
      })

    setIsLoading(false)
  }

  const fetchSimilarProductTypes = async (categoryId?: string) => {
    if (newProductTypeLabel.length > 3) {
      await ProductProducerService.getAllProductTypes(
        newProductTypeLabel,
        categoryId || selectedCategory?.id,
      ).then((result) => {
        result && setSimilarProductTypes(result)
      })
    }
  }
  const onChangeNewProductTypeLabel = async (value: string) => {
    setNewProductTypeLabel(value)
  }

  const onUpdateProduct = async () => {
    if (!updatedProduct || !updatedProduct?.id) return
    setErrorMessage('')
    setIsLoading(true)

    const bioLabelId = productLabels?.find((label) => label.label === 'BIO')?.id

    if (
      bioLabelId &&
      selectedLabels?.includes(bioLabelId) &&
      !updatedProduct.label?.toLowerCase().includes('bio')
    ) {
      setErrorMessage(
        'Vous avez ajouté le label BIO, la mention “BIO” doit apparaître dans le titre de votre produit.',
      )
      setIsLoading(false)
      return
    }

    const product = {
      label: updatedProduct.label,
      description: updatedProduct.description,
      internalReference: updatedProduct.internalReference,
      packing: FormatUtils.stringToFloat(updatedProduct.packing),
      minUnitPrice: FormatUtils.stringToFloat(updatedProduct.minUnitPrice),
      mesureType: selectedMesureType.value,
      vatRate: selectedVat.value,
      containerId: selectedContainer?.id,
      productTypeId: selectedProductType?.id,
      newImageUri: updatedProduct.newImageUri,
      unitWeight: FormatUtils.stringToFloat(updatedProduct.unitWeight),
      newProductTypeLabel: newProductTypeLabel,
      categoryId: selectedCategory?.id,
      productLabels: selectedLabels,
    }

    if (product.newProductTypeLabel) {
      product.productTypeId = undefined
    }

    await ProductProducerService.update(updatedProduct?.id, product)
      .then((updatedProductTemp) => {
        queryClient.fetchQuery(['p_products'])

        navigation.navigate('ProducerCatalog', {})
        setIsLoading(false)
      })
      .catch((error) => {
        setErrorMessage('Un problème est survenu lors de la création du produit')
        setIsLoading(false)
      })
  }

  const onChangeProductImage = (itemsDroppedWithId: any[]) => {
    setIsLoadingImage(true)
    if (itemsDroppedWithId && itemsDroppedWithId[0]) {
      setIsDisabled(true)
      setValue(itemsDroppedWithId[0].uri, 'newImageUri')
      setTimeout(() => {
        setIsDisabled(false)
        setIsLoadingImage(false)
      }, 5000)
    }
  }

  const resetForm: () => void = async () => {
    setSelectedCategory(undefined)
    setNewProductTypeLabel('')
  }

  const renderSimilarProductTypes = () => {
    if (!similarProductTypes || similarProductTypes.length < 1) return <></>
    let productTypes = [...similarProductTypes]
    if (similarProductTypes.length > 3) {
      productTypes = similarProductTypes.slice(0, 3)
    }

    return (
      <View>
        <Spacer size={0.7} axis={Spacer.AxisEnum.VERTICAL} />
        <View style={{ flexDirection: 'row' }}>
          <Typography.Body semiBold>
            Il existe des types de produits similaires. Souhaitez-vous en sélectionner un ?
          </Typography.Body>
          <Button
            small
            label="Non"
            colorName="color-grey"
            onPress={() => {
              setSelectedProductType(undefined)
              setIsAddingProductType(true)
              setSimilarProductTypes(undefined)
            }}
          />
          <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
        </View>
        <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
        {productTypes.map((productType) => (
          <>
            <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
            <Card key={productType?.id}>
              <CardContentWrapper>
                <Typography.Body>{productType?.label}</Typography.Body>
                <Button
                  label="Sélectionner"
                  onPress={() => {
                    setSelectedProductType(productType)
                    setIsAddingProductType(false)
                    setNewProductTypeLabel('')
                  }}
                  small
                />
              </CardContentWrapper>
            </Card>
          </>
        ))}
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
      </View>
    )
  }

  const goBack = () => {
    if (!navigation) return

    if (isAddingContainerType) {
      setIsAddingContainerType(false)
      return
    }

    if (navigation.canGoBack()) {
      navigation.goBack()
    }
  }
  if (productLoading) {
    return <Loader isLoading pageLoading />
  }

  if (!updatedProduct) return null

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper noHeader>
        <StyledBackButtonWrapper onPress={goBack}>
          <Icon
            type={Icons.Ionicons}
            name={'chevron-back-outline'}
            color={theme.colors['color-primary']}
            size={32}
          />
          <View>
            <Spacer size={1.4} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall style={{ textAlign: 'center' }} colorName="text-dark-3">
              retour
            </Typography.BodySmall>
          </View>
        </StyledBackButtonWrapper>
        {isAddingContainerType ? (
          <>
            <AddContainerForm onAddContainer={handleAddContainer} />
            <BackToListWrapper>
              <Button.Text
                label={'Revenir à la liste'}
                onPress={() => {
                  setIsAddingContainerType(false)
                }}
              />
            </BackToListWrapper>
          </>
        ) : (
          <UpdateProductContentWrapper>
            <PageTitle title={FormatUtils.capitalize(i18n.t('pageTitleUpdateProduct'))} />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
            <ScrollableFormWrapper>
              <StyledImageBox>
                <StyledImage
                  source={DirectusUtil.getProductImageUrlFromId(updatedProduct as any as Product)}
                  resizeMode="cover"
                />
                <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />

                <DocumentPickerInput
                  customAcceptedFileExtensions={['png', 'jpg', 'jpeg', 'webp']}
                  onAddFiles={onChangeProductImage}
                />
              </StyledImageBox>
              {isAddingProductType ? (
                <>
                  <Dropdown
                    label={'Catégorie'}
                    onChange={onChangeCategory}
                    defaultValue={selectedCategory?.value}
                    multiple={false}
                    optionsDefault={
                      !categories
                        ? []
                        : categories.map((category) => ({
                            ...category,
                            label: category.label,
                            value: category.id,
                          }))
                    }
                    zIndex={1000}
                    zIndexInverse={4000}
                    itemKey="productType"
                  />
                  <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />

                  {selectedCategory && (
                    <>
                      <TextInput
                        value={newProductTypeLabel || ''}
                        label="Nouveau type de produit"
                        field="text"
                        onChangeText={onChangeNewProductTypeLabel}
                        onBlurCallBack={() => fetchSimilarProductTypes()}
                      />
                      <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                      <Typography.BodyExtraSmall colorName="color-grey">
                        Ex: Fraise guariguette
                      </Typography.BodyExtraSmall>
                      <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                      {renderSimilarProductTypes()}
                    </>
                  )}

                  <BackToListWrapper>
                    <Button.Text
                      label={'Revenir à la liste'}
                      onPress={() => {
                        setIsAddingProductType(false)
                      }}
                    />
                    <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                  </BackToListWrapper>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                </>
              ) : (
                <>
                  <Dropdown
                    label={'Type de produit'}
                    onChange={onChangeProductType}
                    multiple={false}
                    isSearchable={true}
                    optionsDefault={
                      !productTypes
                        ? []
                        : productTypes.map((productType) => ({
                            ...productType,
                            label: productType?.label,
                            value: productType?.id,
                          }))
                    }
                    zIndex={1}
                    zIndexInverse={1}
                    itemKey="productType"
                    defaultValue={selectedProductType?.id}
                  />
                  <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
                  <Button.Icon
                    iconName="add"
                    small
                    style={{ alignSelf: 'flex-end' }}
                    label={'Proposer un nouveau type de produit'}
                    onPress={() => {
                      setIsAddingProductType(true)
                      resetForm()
                    }}
                  />
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                </>
                // </View>
              )}

              <Dropdown
                label={'Type de mesure'}
                onChange={onChangeMesureType}
                multiple={false}
                optionsDefault={ProductEnums.MESURE_TYPES.map((mesureType) => ({
                  ...mesureType,
                }))}
                zIndex={999}
                zIndexInverse={4001}
                itemKey="mesureType"
                hidden={!selectedProductType}
                defaultValue={selectedMesureType.value}
              />
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <TextInput
                value={updatedProduct.label}
                label="Nom du produit"
                field="text"
                onChangeText={(text) => setValue(text, 'label')}
                hidden={!selectedMesureType}
              />
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <TextInput
                value={updatedProduct.description}
                label="Description"
                field="text"
                onChangeText={(text) => setValue(text, 'description')}
                hidden={!selectedMesureType}
                multiline
              />
              {updatedProduct.description &&
              updatedProduct.description.length > MAX_DESCRIPTION_LENGTH ? (
                <Typography.BodySmall colorName="color-danger">
                  Votre description est trop longue ({updatedProduct.description?.length}/
                  {MAX_DESCRIPTION_LENGTH})
                </Typography.BodySmall>
              ) : (
                <Typography.BodySmall colorName="text-dark-3">
                  Taille de la description ({updatedProduct.description?.length}/
                  {MAX_DESCRIPTION_LENGTH})
                </Typography.BodySmall>
              )}
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <Dropdown
                label={'Type de Colis'}
                onChange={onChangeContainer}
                multiple={false}
                defaultValue={selectedContainer.id}
                optionsDefault={(containers || []).map((container) => ({
                  ...container,
                  value: container.id,
                }))}
                zIndex={2}
                zIndexInverse={2}
                isSearchable={true}
                itemKey="container"
                hidden={!updatedProduct.label}
              />

              <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
              <Button.Icon
                iconName="add"
                small
                style={{ alignSelf: 'flex-end' }}
                label={'Proposer un nouveau type de colis'}
                onPress={() => {
                  setIsAddingContainerType(true)
                }}
              />
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

              <Row>
                <Col xs={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={updatedProduct.packing}
                    label={`Conditionnement (${
                      selectedMesureType?.value === ProductEnums.MESURE_TYPE.KILO ? 'Kg' : 'Pièces'
                    } par colis)`}
                    placeholder="6"
                    field="text"
                    onChangeText={(text) => setValue(text, 'packing')}
                    hidden={!selectedContainer}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                  <TextInput
                    value={updatedProduct.minUnitPrice}
                    label={`Prix minimum HT`}
                    placeholder="6"
                    field="text"
                    onChangeText={(text) => setValue(text, 'minUnitPrice')}
                    hidden={!updatedProduct.packing}
                    suffix={`€/${FormatUtils.formatUnity(selectedMesureType?.value)}`}
                  />
                </Col>
                {selectedMesureType?.value === ProductEnums.MESURE_TYPE.PIECE && (
                  <Col xs={12} md={6}>
                    <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                    <TextInput
                      value={updatedProduct.unitWeight}
                      label={`Poids d'une pièce (en kg)`}
                      field="text"
                      onChangeText={(text) => setValue(text, 'unitWeight')}
                      hidden={!updatedProduct.packing}
                    />
                  </Col>
                )}
              </Row>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <Dropdown
                label={'Taux de TVA'}
                onChange={onChangeVat}
                multiple={false}
                optionsDefault={ProductEnums.VATS.map((vat) => ({
                  ...vat,
                }))}
                zIndex={497}
                itemKey="vatRate"
                hidden={
                  !updatedProduct.packing ||
                  (!updatedProduct.unitWeight &&
                    selectedMesureType?.value === ProductEnums.MESURE_TYPE.PIECE)
                }
                defaultValue={selectedVat?.value}
              />
              <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />

              <Dropdown
                label={'Votre produit est-il labélisé (BIO, ...) ?'}
                onChange={onChangeProductLabels}
                multiple={true}
                defaultValue={selectedLabels}
                optionsDefault={
                  productLabels?.map((productLabel) => ({
                    label: productLabel.label,
                    value: productLabel.id,
                  })) ?? []
                }
                zIndex={3}
                zIndexInverse={3}
                dropDownDirection="TOP"
              />
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <InternalReferenceInput
                value={updatedProduct.internalReference}
                onChange={(value) => setValue(value, 'internalReference')}
              />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              {isLoadingImage ? (
                <>
                  <Typography.Body colorName="text-dark-3" align="center">
                    Chargement de l'image en cours...
                  </Typography.Body>
                  <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
                </>
              ) : null}
              <StyledButtonWrapper>
                {errorMessage ? (
                  <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
                ) : null}
                <Button
                  disabled={
                    isDisabled ||
                    (updatedProduct?.description || '').length > MAX_DESCRIPTION_LENGTH
                  }
                  label={'Sauvegarder'}
                  onPress={() => onUpdateProduct()}
                  loading={isLoading || isLoadingImage}
                  hasDoubleValidation
                  confirmationLabel="Êtes-vous sûr de vouloir modifier ce produit ?"
                />
              </StyledButtonWrapper>
              <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
            </ScrollableFormWrapper>
          </UpdateProductContentWrapper>
        )}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerUpdatePoductScreen
