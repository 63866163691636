import { useState } from 'react'
import { View } from 'react-native'
import { Typography, Card, Button, Row, Col, Spacer, SectionTitle } from '../../../../../components'
import FormatUtils from '../../../../../utilities/utils/format'
import { ClientCartSummaryProps } from './ClientCartSummary.model'
import {
  BoxWrapper,
  StyledCardWrapperTour,
  StyledCardWrapperCart,
  StyledButtonWrapper,
  CartLine,
  CartLineLabel,
  CartLineValue,
} from './ClientCartSummary.styles'
import TourUtil from '../../../../../utilities/utils/tour'
import { TourEnums } from '../../../../../../enums'

const ClientCartSummary = ({ cart, onValidateOrder, loading }: ClientCartSummaryProps) => {
  const [seeMore, setSeeMore] = useState<boolean | null>(false)

  const tourIsPending = () => {
    if (!cart || !cart.tour) return true
    return TourUtil.getTourStatus(cart.tour) === TourEnums.STATUS.PENDING
  }

  const orderHasMinimum = () => {
    const minOrderValueHT = cart.tour?.minOrderValueHT || 0

    if (!cart.totalHTBatchs || cart.totalHTBatchs < 0) {
      return false
    }

    if (
      cart.totalHTBatchs &&
      cart.totalHTBatchs > 0 &&
      minOrderValueHT > 0 &&
      minOrderValueHT > cart.totalHTBatchs
    ) {
      return false
    }

    return true
  }

  const oderValueToFreeDelivery = () => {
    const freeDeliveryOrderValueHT = cart.tour?.freeDeliveryOrderValueHT || 0

    if (
      cart.totalHTBatchs &&
      cart.totalHTBatchs > 0 &&
      freeDeliveryOrderValueHT > 0 &&
      freeDeliveryOrderValueHT > cart.totalHTBatchs
    ) {
      return FormatUtils.formatPrice(freeDeliveryOrderValueHT - cart.totalHTBatchs)
    }

    return undefined
  }

  return (
    <BoxWrapper>
      <StyledCardWrapperTour onPress={() => null}>
        <Card>
          <Typography.Body colorName="color-primary" bold>
            Livraison
          </Typography.Body>
          <Typography.Body>
            {FormatUtils.formatDate(cart.tour?.start, 'FullDate+StartTime+EndTime', cart.tour?.end)}
          </Typography.Body>
          <Typography.BodySmall colorName="text-dark-3">
            Fin des commandes {FormatUtils.formatDate(cart.tour?.close, 'FullDate+StartTime')}
          </Typography.BodySmall>
        </Card>
      </StyledCardWrapperTour>

      <StyledCardWrapperCart onPress={() => null}>
        <Card>
          <Typography.Body colorName="color-primary" bold>
            Totaux
          </Typography.Body>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

          <CartLine>
            <CartLineLabel>
              <Typography.Body>Total HT</Typography.Body>
            </CartLineLabel>
            <CartLineValue>
              <Typography.Body colorName="color-primary" bold>
                {FormatUtils.formatPrice(cart.totalHTBatchs)}
              </Typography.Body>
            </CartLineValue>
          </CartLine>

          {seeMore ? (
            <>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              {cart.totalVAT55 && cart.totalVAT55 > 0 ? (
                <>
                  <CartLine>
                    <CartLineLabel>
                      <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
                      <Typography.Body>TVA 5,5%</Typography.Body>
                    </CartLineLabel>
                    <CartLineValue>
                      <Typography.Body colorName="color-primary" bold>
                        {FormatUtils.formatPrice(cart.totalVAT55)}
                      </Typography.Body>
                    </CartLineValue>
                  </CartLine>
                  <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                </>
              ) : null}
              {cart.totalVAT10 && cart.totalVAT10 > 0 ? (
                <>
                  <CartLine>
                    <CartLineLabel>
                      <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
                      <Typography.Body>TVA 10%</Typography.Body>
                    </CartLineLabel>
                    <CartLineValue>
                      <Typography.Body colorName="color-primary" bold>
                        {FormatUtils.formatPrice(cart.totalVAT10)}
                      </Typography.Body>
                    </CartLineValue>
                  </CartLine>
                  <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                </>
              ) : null}
              {cart.totalVAT20 && cart.totalVAT20 > 0 ? (
                <>
                  <CartLine>
                    <CartLineLabel>
                      <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
                      <Typography.Body>TVA 20%</Typography.Body>
                    </CartLineLabel>
                    <CartLineValue>
                      <Typography.Body colorName="color-primary" bold>
                        {FormatUtils.formatPrice(cart.totalVAT20)}
                      </Typography.Body>
                    </CartLineValue>
                  </CartLine>
                  <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
                </>
              ) : null}

              <CartLine>
                <CartLineLabel>
                  <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
                  <Typography.Body>Total TVA</Typography.Body>
                </CartLineLabel>
                <CartLineValue>
                  <Typography.Body colorName="color-primary" bold>
                    {FormatUtils.formatPrice(
                      (cart.totalVAT20 || 0) + (cart.totalVAT10 || 0) + (cart.totalVAT55 || 0),
                    )}
                  </Typography.Body>
                </CartLineValue>
              </CartLine>
              <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
              <CartLine>
                <CartLineLabel>
                  <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
                  <Typography.Body>Total TTC</Typography.Body>
                </CartLineLabel>
                <CartLineValue>
                  <Typography.Body colorName="color-primary" bold>
                    {FormatUtils.formatPrice(cart.totalTTC)}
                  </Typography.Body>
                </CartLineValue>
              </CartLine>
              <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
              <Button.Text
                colorName="text-dark-3"
                label={'Cacher le détail'}
                onPress={() => setSeeMore(false)}
              />
            </>
          ) : (
            <>
              <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
              <Button.Text
                colorName="text-dark-3"
                label={'Voir le détail'}
                onPress={() => setSeeMore(true)}
              />
            </>
          )}
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
          {cart?.tour?.deliveryFeesHT && cart?.tour?.deliveryFeesHT > 0 ? (
            <>
              <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
              <CartLine>
                <CartLineLabel>
                  <View>
                    <Typography.Body>Frais de livraison</Typography.Body>
                  </View>
                </CartLineLabel>
                <CartLineValue>
                  <Typography.Body colorName="text-dark-3">
                    {cart.deliveryFeesHT && cart.deliveryFeesHT > 0
                      ? `+${FormatUtils.formatPrice(cart.deliveryFeesHT)}`
                      : 'Offerts'}
                  </Typography.Body>
                </CartLineValue>
              </CartLine>
              <Spacer size={0.6} axis={Spacer.AxisEnum.VERTICAL} />
              {oderValueToFreeDelivery() !== undefined ? (
                <Typography.Body colorName="text-dark-1" bold>
                  Encore {oderValueToFreeDelivery()} pour bénéficier d'une livraison gratuite.
                </Typography.Body>
              ) : null}
            </>
          ) : undefined}
        </Card>
      </StyledCardWrapperCart>
      {tourIsPending() && (
        <>
          <Typography.BodySmall colorName="color-danger">
            Les commandes ne sont pas encore ouvertes
          </Typography.BodySmall>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        </>
      )}
      {!orderHasMinimum() && (
        <>
          <Typography.BodySmall colorName="color-danger">
            Vous n'avez pas atteint le minimum de commande de{' '}
            {FormatUtils.formatPrice(cart?.tour?.minOrderValueHT || 0)}
          </Typography.BodySmall>
          <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        </>
      )}
      <StyledButtonWrapper>
        <Button
          small
          label={cart.targetCart ? 'Valider la modification' : 'Valider la commande'}
          onPress={() => onValidateOrder()}
          loading={loading}
          disabled={tourIsPending() || !orderHasMinimum()}
        />
      </StyledButtonWrapper>
      <Spacer size={3} axis={Spacer.AxisEnum.VERTICAL} />
      {cart?.tour?.paymentConditions && (
        <StyledCardWrapperCart onPress={() => null}>
          <Card>
            <Typography.Body colorName="text-dark-1" bold>
              Conditions de paiement
            </Typography.Body>
            <Spacer size={0.5} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.BodySmall colorName="text-dark-3">
              {cart.tour.paymentConditions}
            </Typography.BodySmall>
          </Card>
        </StyledCardWrapperCart>
      )}
    </BoxWrapper>
  )
}

export default ClientCartSummary
