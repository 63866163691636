import styled, { css } from 'styled-components/native'
import { ButtonProps } from './Button.model'

const StyledTouchableOpacity = styled.TouchableOpacity<ButtonProps>`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacingUnit * 1}px ${({ theme }) => theme.spacingUnit * 3}px;
  background-color: ${({ theme }) => theme.colors['color-secondary']};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  width: fit-content;
  height: fit-content;

  ${({ colorName }) =>
    colorName &&
    css`
      background-color: ${({ theme }) => theme.colors[colorName]};
    `}
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      max-width: 290px;
    `}

  ${({ small }) =>
    small &&
    css`
      padding: ${({ theme }) => theme.spacingUnit * 0.8}px;
    `}

    ${({ lowPadding }) =>
    lowPadding &&
    css`
      padding-top: ${({ theme }) => theme.spacingUnit * 0.3}px;
      padding-bottom: ${({ theme }) => theme.spacingUnit * 0.3}px;
    `}
    

  ${({ outlined }) =>
    outlined &&
    css`
      border: 2px solid ${({ theme }) => theme.colors['color-border']};
      background-color: white;
    `}

    ${({ danger }) =>
    danger &&
    css`
      background-color: ${({ theme }) => theme.colors['color-danger']};
    `}

    ${({ danger, outlined }) =>
    danger &&
    outlined &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors['color-danger']};
    `}

    ${({ rounded }) =>
    rounded &&
    css`
      border-radius: ${({ theme }) => theme.borderRadius * 1.5}px;
    `}

    ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}
`

const DoubleValidationWrapper = styled.TouchableOpacity``

const ButtonsValidationWrapper = styled.TouchableOpacity`
  gap: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export { StyledTouchableOpacity, DoubleValidationWrapper, ButtonsValidationWrapper }
