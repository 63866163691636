import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import { Button, Card, Icon, Icons, Spacer, TouchableCard, Typography } from '../../../components'
import i18n from '../../../i18n'
import FormatUtils from '../../../utilities/utils/format'
import { OrderBatchProps } from './OrderBatch.model'
import {
  LeftBox,
  ProductNameBox,
  RightBox,
  StyledCardContent,
  StyledCardWrapper,
  StyledImage,
  StyledValueBox,
  StyledValuesWrapper,
} from './OrderBatch.styles'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import { View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import DeviceUtil from '../../../utilities/utils/device'
import DirectusUtil from '../../../utilities/utils/directus'
import { Product } from '../../../domain'

const OrderBatch = ({ cartBatch }: OrderBatchProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()

  const OnClickBatch = () => {
    // onClick(batch)
  }

  const isMobileScreen = DeviceUtil.isMobileApp()

  const batch = cartBatch.batch

  if (!cartBatch || !batch) return null

  return (
    <StyledCardWrapper onPress={OnClickBatch} activeOpacity={0.8}>
      <StyledCardContent>
        <LeftBox>
          <StyledImage
            source={DirectusUtil.getProductImageUrlFromId(batch.product as any as Product)}
            resizeMode="cover"
          />
          <Spacer size={1.7} axis={Spacer.AxisEnum.HORIZONTAL} />
          <ProductNameBox>
            <Typography.Body bold ellipsis>
              {batch.product?.label}
            </Typography.Body>
            <Typography.Body colorName={'text-dark-1'}>
              par {FormatUtils.formatQuantity(cartBatch.packing, batch.product?.mesureType)}
            </Typography.Body>
          </ProductNameBox>
        </LeftBox>
        <RightBox>
          {!isMobileScreen && (
            <>
              <Typography.Body colorName={'text-dark-1'}>
                {FormatUtils.formatPrice(cartBatch.unitPrice)}/
                {FormatUtils.formatUnity(batch.product?.mesureType)}
              </Typography.Body>
              <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
            </>
          )}

          <StyledValuesWrapper>
            <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
            <StyledValueBox>
              <Typography.Body semiBold colorName={'color-primary'}>
                {`${FormatUtils.formatQuantity(
                  cartBatch?.totalQuantity,
                  batch.product?.mesureType,
                )}`}
              </Typography.Body>

              <Typography.Body semiBold colorName={'color-primary'}>
                ({`${cartBatch.packingQuantity} colis`})
              </Typography.Body>
            </StyledValueBox>
            <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
          </StyledValuesWrapper>

          <Spacer size={1} axis={Spacer.AxisEnum.HORIZONTAL} />
          <Typography.Body colorName={'text-dark-1'}>
            {FormatUtils.formatPrice(
              (cartBatch.unitPrice || 0) * (cartBatch.totalQuantity || 0),
              'ht',
            )}
          </Typography.Body>
        </RightBox>
      </StyledCardContent>
    </StyledCardWrapper>
  )
}

export default OrderBatch
