import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import Typography from '../Typography'
import { PageTitleProps } from './PageTitle.model'
import { StyledLeftWrapper, StyledTitleWrapper } from './PageTitle.styles'

function PageTitle({ title, subtitle, buttonRight, noMarginLeft }: PageTitleProps) {
  const theme = useTheme()

  return (
    <StyledTitleWrapper noMarginLeft={noMarginLeft}>
      <StyledLeftWrapper>
        <Typography.PageTitle>{title}</Typography.PageTitle>
        {subtitle && (
          <Typography.BodySmall
            colorName="text-dark-3"
            style={{ marginLeft: theme.spacingUnit * 0.5 + 'px' }}
          >
            {subtitle}
          </Typography.BodySmall>
        )}
      </StyledLeftWrapper>
      {buttonRight && buttonRight}
    </StyledTitleWrapper>
  )
}

export default PageTitle
