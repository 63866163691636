import MaskInput from 'react-native-mask-input'
import styled from 'styled-components/native'

const StyledTextInput = styled.TextInput<{
  small?: boolean
}>`
  flex: 1;
  width: 100%;
  padding: 0 ${({ theme, small }) => theme.spacingUnit * (small ? 0.5 : 2)}px;
  padding-top: ${({ theme }) => theme.spacingUnit * 0.3}px;
  padding-bottom: ${({ theme }) => theme.spacingUnit * 1}px;
  margin-top: ${({ theme, multiline, small }) =>
    multiline ? 0 : theme.spacingUnit * (small ? 0.2 : 2)}px;
  color: ${({ theme }) => theme.colors['text-dark-1']};
  font-size: ${({ theme }) => theme.fontSizes.BODY}px;
  z-index: 1;
  border: none !important;

  // outline: none !important;

  &:focus {
    // outline: none !important;
    background-color: red;
  }
  *:focus:not(.focus-visible) {
    // outline: none;
    background-color: red;
  }
  input:focus {
    // outline: none;
    background-color: red;
  }
`

const StyledMaskedInput = styled(MaskInput)<{ noLabel: boolean }>`
  flex: 1;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacingUnit * 2}px;
  margin-top: ${({ theme, noLabel }) => (noLabel ? 0 : theme.spacingUnit * 2)}px;
  color: ${({ theme }) => theme.colors['text-dark-1']};
  font-size: ${({ theme }) => theme.fontSizes.BODY}px;
  z-index: 1;
`

const StyledSecureTextEntryIconWrapper = styled.View`
  position: absolute;
  right: ${({ theme }) => theme.spacingUnit * 2}px;
  bottom: 0;
  top: 0;
  justify-content: center;
  z-index: 2;
`

const StyledInlineWrapper = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const StyledPrefixWrapper = styled.View`
  padding-left: ${({ theme }) => theme.spacingUnit * 2}px;
`

const StyledSuffixWrapper = styled.View`
  position: absolute;
  right: 0;
  bottom: ${({ theme }) => theme.spacingUnit * 2.2}px;
  padding-left: ${({ theme }) => theme.spacingUnit * 1}px;
  padding-right: ${({ theme }) => theme.spacingUnit * 1}px;
`

export {
  StyledTextInput,
  StyledMaskedInput,
  StyledSecureTextEntryIconWrapper,
  StyledInlineWrapper,
  StyledPrefixWrapper,
  StyledSuffixWrapper,
}
