import { MailsObject } from '../Client'
import { Address, ProducerGroup, User, UserProducer } from '../'
import { NotificationEnums } from '../../../enums'
import { Account } from '../Account'
import { ProducerStatusEnum, ProducerVatTypeEnum } from '../../../enums/producer'

export type ProducerNotificationSettings = {
  clientOrder?: NotificationEnums.ProducerNotificationSettingsClientOrderEnum
}

export type Producer = {
  id: string
  label: string
  owners?: string
  createdDate?: string
  updatedDate?: string
  newImageUri?: string
  newGalleryImagesUris?: string[]

  imageId?: string
  galleryImagesIds?: string[]
  description?: string
  location?: string
  user?: User
  nbOrders?: number
  nbProducts?: number
  sumCA?: number
  dateCreation?: string
  dateUpdate?: string

  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  city?: string
  phone1?: string
  phone2?: string
  mail?: string
  mails?: MailsObject
  deliveryNoteStatment1?: string
  deliveryNoteStatment2?: string
  deliveryNoteBottomStatment?: string
  billingStatement?: string
  siret?: string
  apeCode?: string
  vatNumber?: string

  userProducers?: UserProducer[]
  vatType?: ProducerVatTypeEnum
  labels?: string[]
  stripeCustomerId?: string
  platformFees?: number
  status?: ProducerStatusEnum
  notificationSettings?: ProducerNotificationSettings
  address?: Address
  account?: Account
  producerGroups?: ProducerGroup[]
}

export type AddProducer = {
  label?: string
  owners?: string
  description?: string
  location?: string

  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  city?: string
  phone1?: string
  phone2?: string
  mail?: string
  deliveryNoteStatment1?: string
  deliveryNoteStatment2?: string
  deliveryNoteBottomStatment?: string
  billingStatement?: string
  siret?: string
  apeCode?: string
  vatNumber?: string
  labels?: string[]
  address?: Address
}

export type UpdateProducer = {
  label?: string
  owners?: string
  description?: string
  location?: string

  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  city?: string
  phone1?: string
  phone2?: string
  mail?: string
  deliveryNoteStatment1?: string
  deliveryNoteStatment2?: string
  deliveryNoteBottomStatment?: string
  billingStatement?: string
  siret?: string
  apeCode?: string
  vatNumber?: string
  labels?: string[]
  notificationSettings?: ProducerNotificationSettings
  address?: Address
}

export type UpdateProducerProfile = {
  location?: string
  label?: string
  owners?: string
  description?: string
  newImageUri?: string
  newGalleryImagesUris?: string[]
  galleryImagesIds?: string[]
}

export type AddProducerRegister = {
  lastName?: string
  firstName?: string
  label?: string
  addressLine1?: string
  postalCode?: string
  city?: string
  phone1?: string
  mail?: string
  password?: string
  passwordConfirmation?: string
}

export type AddProducerTourInvite = {
  label: string
  email: string
  firstName: string
  lastName: string
}

export const ProducersFake: Producer[] = []
