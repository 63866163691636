import React, { useState } from 'react'
import { useTheme } from 'styled-components/native'
import {
  Button,
  Icon,
  Icons,
  Spacer,
  TitleWithTag,
  TouchableCard,
  Typography,
} from '../../../components'
import FormatUtils from '../../../utilities/utils/format'
import { BatchCartCardProps } from './BatchCartCardArrival.model'
import {
  CancelButtonWrapper,
  StyledCardContent,
  StyledCardWrapper,
  StyledCrateBox,
  StyledCratesBox,
  StyledLeftBox,
  StyledMiddleBox,
  StyledRightBox,
  StyledValueBox,
  StyledValuesWrapper,
} from './BatchCartCardArrival.styles'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import {
  CartBatchCarrierStatusEnum,
  CartBatchProducerStatusEnum,
} from '../../../../enums/cartBatch'
import { Batch, CartBatch } from '../../../domain'
import { UserEnums } from '../../../../enums'
import BatchCarrierService from '../../../services/carrier/batch'
import { queryClient } from '../../../utilities/queryClient'
import { View } from 'react-native'

const BatchCartCardArrival = ({
  withProducer,
  batch,
  displayClient,
  userRole,
}: BatchCartCardProps) => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [isIdentificationsExpanded, setIsIdentificationsExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  if (!batch) return null
  if (!userRole) return null

  const identifications = batch.identifications || []

  let displayedIdentifications = [...identifications]

  if (identifications.length > 8 && !isIdentificationsExpanded) {
    displayedIdentifications = displayedIdentifications.splice(0, 6)
  }

  const isCarrier = () => {
    return userRole === UserEnums.RoleEnums.CARRIER
  }

  const isStatusBatch = (
    status: CartBatchCarrierStatusEnum | CartBatchProducerStatusEnum,
    cartBatchs: CartBatch[],
  ): boolean => {
    if (isCarrier()) {
      if (
        cartBatchs.find(
          (cartBatch) => cartBatch.carrierStatus === CartBatchCarrierStatusEnum.PREPARED,
        ) !== undefined
      ) {
        return true
      }

      if (cartBatchs.find((cartBatch) => !cartBatch.carrierStatus)) {
        return false
      }

      return cartBatchs.every((cartBatch) => cartBatch.carrierStatus === status)
    }

    if (cartBatchs.find((cartBatch) => !cartBatch.producerStatus)) {
      return false
    }
    return cartBatchs.every((cartBatch) => cartBatch.producerStatus === status)
  }

  const updateCartBatchsStatus = async (
    batch: Batch,
    status: CartBatchCarrierStatusEnum | CartBatchProducerStatusEnum | null,
  ) => {
    setIsLoading(true)
    if (!batch?.cartBatch) return

    let cartBatchsToUpdateCarrier: Partial<Batch>[] = []
    let cartBatchsToUpdateProducer: Partial<Batch>[] = []
    if (isCarrier()) {
      cartBatchsToUpdateCarrier = batch.cartBatch.map((cartBatch) => {
        return { id: cartBatch.id, carrierStatus: status }
      })
    } else {
      cartBatchsToUpdateProducer = batch.cartBatch.map((cartBatch) => {
        return { id: cartBatch.id, producerStatus: status }
      })
    }

    const cartBatchsToUpdate = isCarrier() ? cartBatchsToUpdateCarrier : cartBatchsToUpdateProducer
    await BatchCarrierService.updateCartBatchs(batch.id, cartBatchsToUpdate)
      .then(() => {
        queryClient.invalidateQueries(['p_arrival_batchs'])
        queryClient.invalidateQueries(['sf_arrival_batchs'])
        queryClient.invalidateQueries(['sf_tour_batchs'])
        queryClient.invalidateQueries(['sf_tour_cart_batchs'])
      })
      .catch((error) => {
        console.error(error)
      })
    setIsLoading(false)
  }

  return (
    <StyledCardWrapper activeOpacity={0.8}>
      <TouchableCard>
        <StyledCardContent>
          <Spacer size={0.4} axis={Spacer.AxisEnum.VERTICAL} />
          {batch.cartBatch && isCarrier() && (
            <StyledLeftBox>
              <Typography.Body colorName="text-dark-1" bold>
                {batch.packingQuantity} colis
              </Typography.Body>
              {!identifications || identifications?.length < 1 ? (
                <Typography.BodySmall colorName="text-dark-3">
                  Identification à générer
                </Typography.BodySmall>
              ) : null}
              <StyledCratesBox>
                {displayedIdentifications?.map((identification) => (
                  <StyledCrateBox key={identification} activeOpacity={1}>
                    <Typography.Body colorName="text-light-1">{identification}</Typography.Body>
                  </StyledCrateBox>
                ))}
                {identifications.length > 8 && (
                  <StyledCrateBox
                    key={'more'}
                    noBackground
                    onPress={() => setIsIdentificationsExpanded(!isIdentificationsExpanded)}
                  >
                    <Typography.Body colorName="text-light-1">
                      {!isIdentificationsExpanded ? '...' : ' - '}
                    </Typography.Body>
                  </StyledCrateBox>
                )}
              </StyledCratesBox>
            </StyledLeftBox>
          )}
          <StyledMiddleBox>
            <StyledValuesWrapper>
              <TitleWithTag tag={batch.product?.internalReference}>
                <Typography.Body bold ellipsis>
                  {batch.product?.label}
                </Typography.Body>
              </TitleWithTag>

              {withProducer && (
                <Typography.BodySmall colorName="text-dark-3">
                  {batch.product?.producer?.label}
                </Typography.BodySmall>
              )}
              <StyledValueBox>
                <Typography.Body semiBold>{batch.packingQuantity} colis</Typography.Body>
              </StyledValueBox>
            </StyledValuesWrapper>

            <Spacer size={0.1} axis={Spacer.AxisEnum.VERTICAL} />

            <StyledValuesWrapper>
              <StyledValueBox>
                <Typography.BodySmall>
                  par{' '}
                  {FormatUtils.formatQuantity(batch?.product?.packing, batch.product?.mesureType)}
                </Typography.BodySmall>
              </StyledValueBox>
              <StyledValueBox>
                <Typography.BodySmall>
                  {FormatUtils.formatQuantity(batch.totalQuantity, batch.product?.mesureType)}
                </Typography.BodySmall>
              </StyledValueBox>
            </StyledValuesWrapper>

            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />

            {batch.cartBatch && isCarrier() && (
              <StyledValuesWrapper>
                <StyledValueBox>
                  <Typography.Body semiBold>
                    {FormatUtils.formatPrice(batch.unitPrice)}/
                    {FormatUtils.formatUnity(batch.product?.mesureType)}
                  </Typography.Body>
                </StyledValueBox>
                <StyledValueBox>
                  <Typography.Body semiBold>
                    {FormatUtils.formatPrice(batch.totalQuantity * batch.unitPrice)}
                  </Typography.Body>
                </StyledValueBox>
              </StyledValuesWrapper>
            )}
          </StyledMiddleBox>

          {batch.cartBatch && isCarrier() && (
            <StyledRightBox>
              <StyledValueBox>
                {isStatusBatch(CartBatchCarrierStatusEnum.RECEIVED, batch.cartBatch) ? (
                  <>
                    <Icon
                      type={Icons.Ionicons}
                      name="checkmark-circle-outline"
                      color={theme.colors['color-primary']}
                    />
                    <Typography.BodySmall colorName="text-dark-3" semiBold>
                      Reçu
                    </Typography.BodySmall>
                    <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
                    <CancelButtonWrapper onPress={() => updateCartBatchsStatus(batch, null)}>
                      <Typography.BodyExtraSmall semiBold colorName="text-light-1">
                        Annuler
                      </Typography.BodyExtraSmall>
                    </CancelButtonWrapper>
                  </>
                ) : (
                  <Button
                    small
                    lowPadding
                    label={'Reçu'}
                    onPress={() =>
                      updateCartBatchsStatus(batch, CartBatchCarrierStatusEnum.RECEIVED)
                    }
                    loading={isLoading}
                  />
                )}
              </StyledValueBox>
            </StyledRightBox>
          )}
          {batch.cartBatch && userRole === UserEnums.RoleEnums.PRODUCER && (
            <StyledRightBox>
              <StyledValueBox>
                {isStatusBatch(CartBatchProducerStatusEnum.DEPOSITED, batch.cartBatch) ? (
                  <>
                    <Icon
                      type={Icons.Ionicons}
                      name="checkmark-circle-outline"
                      color={theme.colors['color-primary']}
                    />
                    <Typography.BodySmall colorName="text-dark-3" semiBold>
                      Déposé
                    </Typography.BodySmall>
                    <Spacer size={0.2} axis={Spacer.AxisEnum.VERTICAL} />
                    <CancelButtonWrapper onPress={() => updateCartBatchsStatus(batch, null)}>
                      <Typography.BodyExtraSmall semiBold colorName="text-light-1">
                        Annuler
                      </Typography.BodyExtraSmall>
                    </CancelButtonWrapper>
                  </>
                ) : (
                  <Button
                    small
                    lowPadding
                    label={'Déposé'}
                    onPress={() =>
                      updateCartBatchsStatus(batch, CartBatchProducerStatusEnum.DEPOSITED)
                    }
                    loading={isLoading}
                  />
                )}
              </StyledValueBox>
            </StyledRightBox>
          )}
        </StyledCardContent>
      </TouchableCard>
    </StyledCardWrapper>
  )
}

export default BatchCartCardArrival
