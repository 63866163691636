import { BatchEnums } from '../../../enums'
import { InterfaceColors } from '../../utilities/styling/theme/theme'
import { CartBatch } from '../Cart'
import { Client } from '../Client'
import { Group } from '../Group'
import { Pagination } from '../Pagination'
import { PriceStatement } from '../PriceStatement'
import { Producer } from '../Producer'
import { Product, ProductsFake } from '../Product'
import { Tour, ToursFake } from '../Tour'

export type Batch = {
  id: string
  start: string
  end: string
  tour?: Tour
  tourId?: string
  product?: Product
  productId?: string
  unitPriceProducer: number
  unitPrice: number
  crossedOutPrice?: number
  priceCarrierFees?: number
  otherBatchs?: Batch[]
  totalQuantity: number
  orderedQuantity: number
  availableQuantity?: number
  cartBatch?: CartBatch[]
  isPrivate?: boolean
  carrierFees?: number
  type?: BatchEnums.TYPE
  priceReporting?: {
    minPriceProducer: number | undefined
    minPrice: number | undefined
    minPriceProducerWholsalers: number | undefined
    minPriceWholsalers: number | undefined
  }
  daysDuration?: number
  computed?: BatchComputed
  identifications?: string[]
  packingQuantity?: number

  otherProducersBatchs?: ProductBatchs[]
  createdDate?: string
  expiration?: Date
  productLabel?: string
  producerLabel?: string
}
// ============================ Start Batch Computed Params
/*
export type BatchComputed = Batch & {
  platformFeesPercentage?: number
  unitPriceParams: {
    min?: number
    max?: number
    unitPrices?: UnitPriceBatch[]
  }
  priceStatementsProducerPriceParams: {
    min?: number
    minPriceReporting?: PriceStatement
    max?: number
    maxPriceReporting?: PriceStatement
    producerPricePriceStatements?: ProducerPriceStatement[]
  }
  carriersFeesParams?: CarrierFeesBatchParams

  periodLabel?: string
  priceParams?: {
    prices: string
    pricesColor: keyof InterfaceColors
    priceStatement: string
    priceStatementColor: keyof InterfaceColors
  }
}*/

export type CarrierFeesBatch = {
  tour: Tour
  group: Group
  fees: number
}

export type CarrierFeesBatchParams = {
  min?: number
  max?: number
  carrierFees?: CarrierFeesBatch[]
}

export type PriceParam = {
  producerPrice: number
  carrierFees: number
  unitPrice: number
  tour: Tour
  priceStatement?: PriceStatement
  client?: Client
}

export type PricesStat = {
  producerPriceBellow: {
    min?: number
    max?: number
  }
  producerPrice: {
    min?: number
    max?: number
  }
  carrierFees: {
    min?: number
    max?: number
  }
  unitPriceBellow?: {
    min?: number
    max?: number
  }
  unitPrice: {
    min?: number
    max?: number
  }
}

export type BatchStatusMessage = {
  type: BatchEnums.BatchStatusMessageTypeEnum
  title: string
  details: string
}

export type BatchComputed = {
  platformFeesPercentage?: number
  pricesParamsBellowProducerPrice?: PriceParam[]
  pricesParams?: PriceParam[]
  pricesStats?: PricesStat
  carriersFeesParams?: CarrierFeesBatchParams
  periodLabel?: string
  statusMessage?: BatchStatusMessage[]
  priceParams?: {
    producerPriceWithPlatformFeesFormatted: string
    producerPricesFormatted: string
    producerPricesColor: keyof InterfaceColors
    producerPriceGainPercentage: string
    unitPricesFormatted: string
    carrierFeesFormatted: string
  }
}

// ============================ End Batch Computed Params

export type ProductBatchs = {
  product?: Product
  batchs?: Batch[]
}

export type Catalog = {
  batchs?: Batch[]
  producers?: Producer[]
  totalCapacityUsed?: number
  clientLabels?: string[]
  pagination?: Pagination
}

export type AddBatch = {
  type?: BatchEnums.TYPE
  start?: Date
  end?: Date
  tourId?: string
  productId?: string
  unitPriceProducer?: string
  unitPrice?: string
  crossedOutPrice?: string
  totalQuantity?: string
  carrierFees?: string
  expiration?: Date
}

export type UpdateBatch = {
  id?: string
  tourId?: string
  productId?: string
  unitPriceProducer?: string
  unitPrice?: string
  crossedOutPrice?: string
  totalQuantity?: string
  availableQuantity?: number
  isPrivate?: boolean
  carrierFees?: string
  orderedQuantity?: number
  start?: Date
  end?: Date
  type?: BatchEnums.TYPE
  daysDuration?: number
  computed?: BatchComputed
  expiration?: Date
}

export type UpdateBatchCarrier = {
  id?: string
  tourId?: string
  productId?: string
  unitPriceProducer?: string
  unitPrice?: string
  totalQuantity?: string
  availableQuantity?: number
  isPrivate?: boolean
  carrierFees?: string
  start?: Date
  end?: Date
  type?: BatchEnums.TYPE
  daysDuration?: number
  computed?: BatchComputed
}

export type BatchMail = {
  id: string
  label: string
  producerLabel: string
  packing: string
  unitPrice: string
}

export const BatchsFake: Batch[] = []
