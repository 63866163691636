import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Button, PageTitle, SectionTitle, Spacer } from '../../../components'
import CardList from '../../../components/CardList'
import { Batch, Pagination, Producer, ProductBatchs, Tour } from '../../../domain'
import TourCard from '../../../modules/Tour/TourCard'
import { ProducerStackParamList } from '../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import BatchProducerService from '../../../services/producer/batch'
import TourProducerService from '../../../services/producer/tour'
import useAuthenticationContext from '../../../utilities/hook/useAuthenticationContext'
import { queryClient } from '../../../utilities/queryClient'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../utilities/styling/wrappers'
import FormatUtils from '../../../utilities/utils/format'
import HelpFooter from '../../../components/HelpFooter'
import ActionCard from '../../../modules/Common/ActionCard'
import MetricsProducerService, {
  ProducerDashboardMetrics,
} from '../../../services/producer/metrics'
import ProducerProducerService from '../../../services/producer/producer'
import DashboardStats from '../../../components/DashboardStats'
import BatchsCard from '../../../modules/Batch/BatchsCard'
import PaginationBar from '../../../components/PaginationBar'
import { ArrivalSectionWrapper } from './ProducerDashboard.styles'
import TutorialStepCards from '../../../components/TutorialStepCards'
import { ProducerTutorialSteps, TutorialStepData } from '../../../../enums/tutorialStep'
import { ProducerStatusEnum } from '../../../../enums/producer'
import TourCarrierService from '../../../services/carrier/tour'

const currentMonth = new Date().getMonth()
const currentYear = new Date().getFullYear()
const defaultDateHour = new Date(currentYear, currentMonth, 1, 0, 0, 0)
const defaultDateHour2 = new Date(currentYear, currentMonth + 1, 1, 0, 0, 0)

export type DashboardPropositionsFiltersObject = {
  mode: 'DAY' | 'WEEK' | 'MONTH'
  start: Date
  end: Date
  selectedTour?: Tour
}

const ProducerDashboardScreen = () => {
  const theme = useTheme()
  const navigation = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const [metricsFilters, setMetricsFilters] = useState({
    start: new Date(defaultDateHour),
    end: new Date(defaultDateHour2),
  })
  const [pageSizeBatchs, setPageSizeBatchs] = useState(2)
  const [pageNumberBatchs, setPageNumberBatchs] = useState(1)

  const { getAccessInfos } = useAuthenticationContext()

  const [dashboardPropositionsFilters, setDashboardPropositionsFilter] = useState<
    DashboardPropositionsFiltersObject | undefined
  >(undefined)

  const {
    data: nextTours,
    refetch: refetchNextTours,
    isInitialLoading: nextToursLoading,
  } = useQuery<{ data: Tour[]; pagination: Pagination }, Error>(
    ['p_next_tours_dashboard'],
    () =>
      TourProducerService.getAllTours(
        getAccessInfos().producerId,
        new Date(),
        undefined,
        false,
        100,
      ),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: productsBatchs,
    refetch: refetchProductBatchs,
    isRefetching: productBatchsRefetching,
    isLoading: productBatchsLoading,
  } = useQuery<{ data: ProductBatchs[]; pagination: Pagination }, Error>(
    ['p_batchs_dashboard'],
    () =>
      BatchProducerService.getAllBatchsProducerComputed(
        getAccessInfos().producerId,
        dashboardPropositionsFilters?.start ? dashboardPropositionsFilters.start : undefined,
        dashboardPropositionsFilters?.end ? dashboardPropositionsFilters.end : undefined,
        undefined,
        [],
        pageSizeBatchs,
        pageNumberBatchs,
      ),
    {
      // keepPreviousData: true,
      enabled: dashboardPropositionsFilters !== undefined,
    },
  )

  const {
    data: metrics,
    refetch: refetchMetrics,
    isInitialLoading: metricsLoading,
  } = useQuery<ProducerDashboardMetrics, Error>(
    ['p_metrics_dashboard', getAccessInfos().producerId],
    () =>
      MetricsProducerService.getDashboardMetrics(
        getAccessInfos().producerId,
        metricsFilters.start,
        metricsFilters.end,
      ),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: producerData,
    refetch: refetchProducer,
    isFetching: producerLoading,
  } = useQuery<{ producer: Producer; stepsData: TutorialStepData[] }, Error>(
    ['p_producer', getAccessInfos().producerId],
    () => ProducerProducerService.getOne(getAccessInfos().producerId),
    {
      keepPreviousData: true,
      enabled: getAccessInfos().producerId !== undefined,
    },
  )

  useEffect(() => {
    if (!nextTours?.data || !nextTours.data[0]) return

    const tempStartDate = new Date(nextTours.data[0].deposit)
    const tempEndDate = new Date(nextTours.data[0].deposit)

    tempStartDate.setHours(0)
    tempStartDate.setMinutes(0)
    tempStartDate.setSeconds(0)

    tempEndDate.setHours(23)
    tempEndDate.setMinutes(59)
    tempEndDate.setSeconds(59)

    setDashboardPropositionsFilter({
      mode: 'DAY',
      start: tempStartDate,
      end: tempEndDate,
      selectedTour: nextTours.data[0],
    })
  }, [nextTours])

  useEffect(() => {
    if (dashboardPropositionsFilters && dashboardPropositionsFilters.start) {
      refetchProductBatchs()
    }
  }, [dashboardPropositionsFilters])

  const onClickTour = (tour: Tour) => {
    navigation.navigate('ProducerTours', {
      screen: 'ProducerTour',
      params: { id: tour.id },
    })
  }

  const onChangePage = async (pageNumber: number) => {
    await setPageNumberBatchs(pageNumber)
    refetchProductBatchs()
  }

  const onClickUpdateBatch = async (batch: Batch) => {
    await queryClient.setQueryData(['p_batch'], null)
    await queryClient.invalidateQueries(['p_batch'])

    navigation.navigate('PropositionProducerNavigationConfiguration', {
      screen: 'ProducerUpdateBatch',
      params: {
        batchId: batch.id,
        propositionsFilters: null,
      },
    })
  }

  const onClickActionTour = (tour: Tour) => {
    if (!tour || !tour.id) return
    navigation.navigate('PropositionProducerNavigationConfiguration', {
      screen: 'ProducerPropositions',
      params: { tourId: tour.id },
    })
  }

  const onClickNavigateToProducerPublicProfile = () => {
    if (!producerData?.producer || !producerData?.producer.id) return
    navigation.navigate('ProfileProducerNav', {
      screen: 'ProducerPublicProfile',
      params: {},
    })
  }

  useEffect(() => {
    refetchMetrics()
  }, [metricsFilters])

  const onClickMetricsChangeDate = async (metricsFilters: { start: Date; end: Date }) => {
    let currentMonthNew = metricsFilters.start.getMonth()
    let currentYear = metricsFilters.start.getFullYear()

    if (currentMonthNew < 0) {
      currentMonthNew = 11
      currentYear = currentYear - 1
    }

    if (currentMonthNew > 11) {
      currentMonthNew = 0
      currentYear = currentYear + 1
    }

    const newStartDate = new Date(currentYear, currentMonthNew, 1, 0, 0, 0)

    const newEndDate = new Date(currentYear, currentMonthNew + 1, 1, 0, 0, 0)

    const tempMetricsFilter = { start: newStartDate, end: newEndDate }

    await setMetricsFilters(tempMetricsFilter)
  }

  const onPressBillingPage = async () => {
    const billingPageURL = await ProducerProducerService.getBillingPageURL(
      getAccessInfos().producerId,
    )

    if (billingPageURL) {
      window.open(billingPageURL, '_blank')
    }
  }

  // Only selected some of the ProducerDashboardMetrics properties
  const selectedMetrics = [
    {
      label: 'Ventes NET (BL)',
      value: metrics?.totalIncome
        ? `${FormatUtils.formatPrice(metrics?.totalIncome)} (${FormatUtils.formatPrice(
            metrics?.totalHT,
          )})`
        : '-',
    },
    { label: 'Commandes', value: metrics?.nbOrders ? metrics?.nbOrders.toString() : '-' },
    { label: 'Clients', value: metrics?.nbClients ? metrics?.nbClients.toString() : '-' },
    { label: 'Colis', value: metrics?.nbCrates ? metrics?.nbCrates.toString() : '-' },
  ]

  const getUrgentActions = (): { title: string; description?: string; onClick: () => void }[] => {
    let tempActions = []

    if (
      nextTours?.data &&
      nextTours.data[0] &&
      (!productsBatchs?.data || productsBatchs?.data.length < 0)
    ) {
      tempActions.push({
        title: 'Mercuriale vide',
        description: `Aucun lot pour la tournée du ${FormatUtils.formatDate(
          nextTours.data[0].start,
          'Date',
        )}`,
        onClick: () => onClickActionTour(nextTours.data[0]),
      })
    }

    if (
      producerData?.producer &&
      (!producerData?.producer.description ||
        !producerData?.producer.imageId ||
        !producerData?.producer.location)
    ) {
      tempActions.push({
        title: `Profil publique incomplet`,
        description: 'Complétez votre profil publique (affiché à vos clients)',
        onClick: () => onClickNavigateToProducerPublicProfile(),
      })
    }

    return tempActions
  }
  const onClickReception = () => {
    const params = {
      mode: 'DAY',
      start: new Date(),
    }
    navigation.navigate('ProducerTours', {
      screen: 'ProducerReceptionPreparation',
      params: params,
    })
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Accueil" />
        <DashboardStats
          metrics={selectedMetrics}
          metricsFilters={metricsFilters}
          onChangeDates={onClickMetricsChangeDate}
          isLoading={metricsLoading}
          metricsType="MONTH"
        />

        {producerData?.producer &&
          producerData?.producer.status === ProducerStatusEnum.ONBOARDING &&
          producerData?.stepsData &&
          producerData?.stepsData.length > 0 && (
            <>
              <SectionTitle title={`Finalisez votre inscription`} />
              <Spacer axis={Spacer.AxisEnum.VERTICAL} size={2} />
              <TutorialStepCards
                tutorialStepsData={producerData?.stepsData}
                tutorialSteps={ProducerTutorialSteps}
                navigationType={'PRODUCER'}
              />
            </>
          )}

        {getUrgentActions() && getUrgentActions().length > 0 ? (
          <>
            <SectionTitle title={`Actions importantes`} />
            {getUrgentActions().map((action, index) => {
              return (
                <ActionCard
                  title={action.title}
                  description={action.description || ''}
                  onClick={action.onClick}
                  key={index}
                />
              )
            })}
          </>
        ) : null}

        <SectionTitle title={`Prochaines tournées`} />
        <CardList
          data={nextTours?.data}
          element={function (tour: any) {
            return <TourCard key={tour.id} tour={tour} onClick={onClickTour} />
          }}
          isLoading={nextToursLoading}
          emptyMessage="Aucune tournée à venir"
        />
        {nextTours?.data && nextTours?.data.length > 0 ? (
          <>
            <SectionTitle
              title={`Votre mercuriale du ${FormatUtils.capitalize(
                FormatUtils.formatDate(nextTours?.data[0].deposit, 'FullDate'),
              )}`}
            />
            {(productsBatchs?.data || []).map((productBatch) => (
              <BatchsCard
                key={productBatch.product?.id}
                productBatchs={productBatch}
                onClick={onClickUpdateBatch}
              />
            ))}
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <PaginationBar
              data={productsBatchs?.data?.map((pbs) => pbs.batchs).flat()}
              pagination={productBatchsRefetching ? undefined : productsBatchs?.pagination}
              onChangePage={onChangePage}
            />
          </>
        ) : null}
        <SectionTitle title={`Dépôt des commandes`} />
        <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
        <ArrivalSectionWrapper>
          <Button
            onPress={onClickReception}
            label="Acceder à la page de dépôt des commandes"
            colorName="color-grey"
            small
          />
        </ArrivalSectionWrapper>
        {producerData?.producer?.stripeCustomerId && (
          <>
            <SectionTitle title={`Abonnement Harvy`} />
            <Spacer axis={Spacer.AxisEnum.VERTICAL} size={1} />
            <Button
              small
              label="Gérer mon abonnement"
              onPress={() => onPressBillingPage()}
              colorName="color-grey"
              outlined
              style={{ marginLeft: '20px' }}
            />
          </>
        )}
        <Spacer axis={Spacer.AxisEnum.VERTICAL} size={5} />
        <HelpFooter />
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default ProducerDashboardScreen
