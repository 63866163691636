import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/native'
import Spacer from '../Spacer'
import Typography from '../Typography'
import { PaginationBarProps } from './PaginationBar.model'
import { Box, BoxWrapper, StyledPaginationWrapper } from './PaginationBar.styles'
import Row from '../Grid/Row'
import Col from '../Grid/Col'
import Icon, { Icons } from '../Icon'
import { TouchableOpacity } from 'react-native'
import { Pagination } from '../../domain'

function PaginationBar({ data, pagination, onChangePage, isBig }: PaginationBarProps) {
  const theme = useTheme()
  const [storedPagination, setStoredPagination] = useState<Pagination | undefined>()

  useEffect(() => {
    if (pagination) {
      setStoredPagination(pagination)
    }
  }, [pagination])

  const onChangePageClick = (pageDiff: number) => {
    if (!storedPagination?.page || !onChangePage) return
    const newPage = storedPagination?.page + pageDiff
    if (newPage < 1 || newPage > storedPagination.totalPages) return
    onChangePage(newPage)
  }

  const getCurrentInterval = () => {
    if (
      !storedPagination?.page ||
      !storedPagination?.totalPages ||
      !storedPagination?.totalCount ||
      !data
    )
      return '-'
    let firstElementRank = storedPagination?.page * data.length - data.length
    let lastElementRank = storedPagination?.page * data.length

    if (storedPagination?.page === 1) {
      firstElementRank = 1
    }

    if (storedPagination?.page === storedPagination?.totalPages) {
      firstElementRank = storedPagination?.totalCount - data.length + 1
      lastElementRank = storedPagination?.totalCount
    }

    return `${firstElementRank} - ${lastElementRank}`
  }

  if (!storedPagination || storedPagination.totalCount < 1) return null

  const displayNextPage = () => {
    if (
      !storedPagination?.page ||
      !storedPagination?.totalPages ||
      storedPagination?.page === storedPagination?.totalPages
    ) {
      return false
    }

    if (storedPagination.noFixedPages) {
      if (!data || data?.length < (storedPagination.limit || 0)) {
        return false
      }
      const elementsPassed = storedPagination?.page * (storedPagination?.limit || 1)
      if (
        elementsPassed >= storedPagination.totalCount ||
        data.length >= storedPagination.totalCount
      ) {
        return false
      }
    }

    return true
  }

  const displayPageNumber = (diff: number) => {
    const targetPage = storedPagination?.page + diff
    if (targetPage < 1 || targetPage > storedPagination.totalPages || !onChangePage) {
      return null
    }

    if (storedPagination.noFixedPages) {
      if (!data || data?.length < (storedPagination.limit || 0)) {
        return null
      }
      if (diff > 1) {
        return null
      }

      const elementsPassed = storedPagination?.page * (storedPagination?.limit || 1)
      if (
        elementsPassed >= storedPagination.totalCount ||
        data.length >= storedPagination.totalCount
      ) {
        return null
      }
    }

    return (
      <Box>
        <TouchableOpacity onPress={() => onChangePage(targetPage)}>
          <Typography.Body colorName="text-dark-3">{targetPage}</Typography.Body>
        </TouchableOpacity>
      </Box>
    )
  }

  return (
    <StyledPaginationWrapper>
      <Row>
        <Col xs={3} alignItems="flex-start">
          <Typography.BodySmall colorName="text-dark-3"></Typography.BodySmall>
        </Col>
        <Col xs={6} alignItems="center">
          <BoxWrapper>
            <Box style={{ minWidth: '30px', alignItems: 'flex-end' }}>
              {!storedPagination?.page || storedPagination?.page === 1 ? null : (
                <TouchableOpacity onPress={() => onChangePageClick(-1)}>
                  <Icon
                    type={Icons.Ionicons}
                    name="chevron-back-outline"
                    size={theme.spacingUnit * (isBig ? 3.3 : 2.5)}
                    color={theme.colors['color-primary']}
                  />
                </TouchableOpacity>
              )}
            </Box>

            <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={0.4} />

            {displayPageNumber(-2)}
            {displayPageNumber(-1)}

            <Box>
              <Typography.ButtonLabel
                colorName="color-primary"
                bold
                style={{ fontSize: theme.spacingUnit * 2 }}
              >
                {storedPagination?.page}
              </Typography.ButtonLabel>
            </Box>

            {displayPageNumber(1)}
            {displayPageNumber(2)}

            <Spacer axis={Spacer.AxisEnum.HORIZONTAL} size={0.4} />

            <Box style={{ minWidth: '30px' }}>
              {!displayNextPage() ? null : (
                <TouchableOpacity onPress={() => onChangePageClick(1)}>
                  <Icon
                    type={Icons.Ionicons}
                    name="chevron-forward-outline"
                    size={theme.spacingUnit * (isBig ? 3.3 : 2.5)}
                    color={theme.colors['color-primary']}
                  />
                </TouchableOpacity>
              )}
            </Box>
          </BoxWrapper>
        </Col>
        <Col xs={3} alignItems="flex-end">
          <Spacer axis={Spacer.AxisEnum.VERTICAL} size={0.7} />
          <Typography.BodySmall colorName="text-dark-3">
            {storedPagination.noFixedPages
              ? `${data?.length} de ${storedPagination?.totalCount} résultats`
              : `[${getCurrentInterval()}] de ${storedPagination?.totalCount}`}
          </Typography.BodySmall>
        </Col>
      </Row>
    </StyledPaginationWrapper>
  )
}

export default PaginationBar
