import * as DocumentPicker from 'expo-document-picker'
import * as ImagePicker from 'expo-image-picker'
import React, { useEffect, useMemo, useState } from 'react'
import { ActionSheetIOS } from 'react-native'
import uuid from 'react-native-uuid'
import { useTheme } from 'styled-components/native'
import GeneralUtils from '../../utilities/utils/general'
import Button from '../Button'
import Typography from '../Typography'
import { DocumentPicked } from './DocumentPicked.model'
import { DocumentPickerProps } from './DocumentPicker.model'
import { StyledButtonWrapper, StyledWrapper } from './DocumentPicker.styles'
import Spacer from '../Spacer'
import { manipulateAsync, FlipType, SaveFormat } from 'expo-image-manipulator'

function DocumentPickerInput({
  onAddFiles,
  customAcceptedFileExtensions,
  openOnRender,
  customButtonText,
  buttonOnly,
  imagesOnly,
  label = 'Charger une nouvelle photo',
}: DocumentPickerProps) {
  const theme = useTheme()
  const [errorMessage, setErrorMessage] = useState('')
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false)

  const acceptedFileExtensions = (): string[] => {
    let acceptedFileExtensionsValues: string[] = [
      'png',
      'jpg',
      'jpeg',
      'webp',
      'svg',
      'pdf',
      'xlsx',
      'xls',
      'csv',
      'gif',
    ]
    if (customAcceptedFileExtensions) {
      acceptedFileExtensionsValues = customAcceptedFileExtensions
    }
    return acceptedFileExtensionsValues
  }
  const acceptedFilesText = () => {
    const acceptedFiles = acceptedFileExtensions().join(', ').toLowerCase()
    return `Fichiers acceptés: ${acceptedFiles} (8Mo max.)`
  }

  const getFileExtension = (filename: string) => {
    return filename.slice((filename.lastIndexOf('.') - 1 || 0) + 2)
  }

  const getDocumentNameFromUri = (uri: string) => {
    return uri.slice((uri.lastIndexOf('/') - 1 || 0) + 2)
  }

  const isValidFile = ({ file }: any): boolean => {
    let fileExtension = file.name ? getFileExtension(file.name) : null

    if (!fileExtension || !acceptedFileExtensions().includes(fileExtension)) {
      setErrorMessage(`Type de fichier invalide. ${acceptedFilesText()}`)
      return false
    }

    if (!file.size || file.size >= 8000000) {
      setErrorMessage('Fichier trop volumineux')
      return false
    }

    fileExtension = fileExtension.toLowerCase()

    const validExtensions = acceptedFileExtensions().map((extension) => extension.toLowerCase())

    const isValid = fileExtension ? validExtensions.includes(fileExtension) : false

    if (validExtensions.length === 0) {
      return true
    }
    return isValid
  }

  const onDropItem = (items: DocumentPicked[]) => {
    setDisplaySuccessMessage(false)
    const itemsDroppedWithId: any[] = []

    items.map((item: any) => {
      if (isValidFile({ file: item })) {
        itemsDroppedWithId.push({ ...item, id: uuid.v4() })
      }
      return true
    })

    if (!GeneralUtils.isEmpty(itemsDroppedWithId)) {
      onAddFiles(itemsDroppedWithId as DocumentPicked[])
      setDisplaySuccessMessage(true)
    }
  }

  // This function is triggered when the "Select an image" button pressed
  /*
  const showImagePicker = async () => {
    // Ask the user for the permission to access the media library
    const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()

    if (permissionResult.granted === false) {
      alert("You've refused to allow this appp to access your photos!")
      return
    }

    const result = await ImagePicker.launchImageLibraryAsync()

    if (!result.cancelled && result.uri && result.type) {
      const name = getDocumentNameFromUri(result.uri)
      const documentReceived: DocumentPicked = {
        uri: result.uri,
        mimetype: `${result.type}/${getFileExtension(name)}`,
        name,

        height: result.height,
        width: result.width,
      }

      onDropItem([documentReceived])
    }
  }
  */

  // This function is triggered when the "Open camera" button pressed
  /*
  const openCamera = async () => {
    // Ask the user for the permission to access the camera
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync()

    if (permissionResult.granted === false) {
      alert("You've refused to allow this appp to access your camera!")
      return
    }

    const result: ImagePicker.ImagePickerResult = await ImagePicker.launchCameraAsync()

    if (!result.cancelled && result.uri && result.type) {
      const documentReceived: DocumentPicked = {
        uri: result.uri,
        mimetype: result.type,
        name: getDocumentNameFromUri(result.uri),
        height: result.height,
        width: result.width,
      }

      onDropItem([documentReceived])
    }
  }
  */

  const uploadDocument = async () => {
    setErrorMessage('')
    const document = await DocumentPicker.getDocumentAsync({
      type: '*/*',
    }).then((result: DocumentPicker.DocumentResult) => {
      if (result.type === 'success') {
        const documentReceived: DocumentPicked = {
          uri: result.uri,
          name: result.name,
          size: result.size,
          mimetype: result.mimeType,
        }

        return documentReceived
      }
    })

    if (!document) {
      return
    }

    if (!isValidFile({ file: document })) {
      return
    }

    const compressedDocument = await compressDocument(document)

    onDropItem([compressedDocument])
  }

  const compressDocument = async (documentReceived: DocumentPicked) => {
    if (documentReceived?.size && documentReceived?.size < 300000) {
      return documentReceived
    }
    let format: SaveFormat | undefined = undefined
    if (documentReceived.mimetype === 'image/png') {
      format = SaveFormat.JPEG
    } else if (
      documentReceived.mimetype === 'image/jpg' ||
      documentReceived.mimetype === 'image/jpeg'
    ) {
      format = SaveFormat.JPEG
    } else if (documentReceived.mimetype === 'image/webp') {
      format = SaveFormat.JPEG
    }

    if (!format) {
      return documentReceived
    }

    const manipResult = await manipulateAsync(
      documentReceived.uri,
      [
        /*
        {
          resize: { height: 500 },
        }*/
      ],
      { compress: 0.2, format: format, base64: false },
    )

    if (!manipResult) {
      return documentReceived
    }

    return {
      ...documentReceived,
      uri: manipResult.uri,
      // size: manipResult.height * manipResult.width * 3,
    }
    //setImage(manipResult);
  }

  /*
  const actionSheetOptions = useMemo(() => {
    const options = ['Cancel', 'Use Camera', 'Select from Photo Library']

    if (!imagesOnly) {
      options.push('Select from Files')
    }

    return options
  }, [imagesOnly])
  */

  const onUploadStart = () => uploadDocument()
  /*
    ActionSheetIOS.showActionSheetWithOptions(
      {
        options: actionSheetOptions,
        cancelButtonIndex: 0,
        tintColor: theme.colors['text-dark-1'],
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          // cancel action
        } else if (buttonIndex === 1) {
          openCamera()
        } else if (buttonIndex === 2) {
          showImagePicker()
        } else if (buttonIndex === 3) {
          uploadDocument()
        }
      },
    )
    */

  useEffect(() => {
    if (openOnRender) {
      onUploadStart()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openOnRender])

  const CustomButton = customButtonText ?? Button.Text

  return (
    <StyledWrapper>
      {errorMessage ? (
        <Typography.InputLabel colorName="color-danger">{errorMessage}</Typography.InputLabel>
      ) : null}
      {!buttonOnly ? (
        <Typography.InputLabel colorName="text-dark-3">{acceptedFilesText()}</Typography.InputLabel>
      ) : null}
      <StyledButtonWrapper>
        <CustomButton
          onPress={onUploadStart}
          label={label}
          iconName="download-outline"
          iconProps={{ color: theme.colors['color-primary'] }}
          style={{ justifyContent: 'flex-start' }}
        />
        <Spacer size={0.1} axis={Spacer.AxisEnum.VERTICAL} />
        {displaySuccessMessage && (
          <Typography.BodyExtraSmall colorName="color-primary">
            Votre fichier a bien été chargé
          </Typography.BodyExtraSmall>
        )}
      </StyledButtonWrapper>
    </StyledWrapper>
  )
}

export default DocumentPickerInput
